import { Injectable } from "@angular/core";
import {
  AppellationEstablishmentTypeAreaModel,
  AppellationModel,
  EstablishmentTypeModel,
} from "./appellation.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AppellationService {
  constructor(private http: HttpClient) {}

  public getEstablishmentTypes(): Observable<EstablishmentTypeModel[]> {
    const request = this.http
      .get<EstablishmentTypeModel[]>(
        `${environment.apiUrls[environment.name]}/establishment_types`
      )
      .pipe(map((data) => data["hydra:member"]));

    return request;
  }

  public getAppellationEstablishmentTypeAreas(
    appellationId
  ): Observable<AppellationEstablishmentTypeAreaModel[]> {
    let params = new HttpParams().set("appellation", appellationId);
    const request = this.http
      .get<AppellationEstablishmentTypeAreaModel[]>(
        `${
          environment.apiUrls[environment.name]
        }/appellation_establishment_type_areas`,
        { params: params }
      )
      .pipe(map((data) => data["hydra:member"]));

    return request;
  }

  public getAppellations(
    offset = 0,
    items_per_page = 25,
    filterValue = null
  ): Observable<AppellationModel[]> {
    offset++;
    let params = new HttpParams()
      .set("order[id]", "desc")
      .set("page", offset.toString())
      .set("items_per_page", items_per_page.toString());
    if (filterValue) {
      params = params.set("name", filterValue);
    }
    const request = this.http.get<AppellationModel[]>(
      `${environment.apiUrls[environment.name]}/appellations`,
      { params: params }
    );

    return request;
  }

  public getAppellationsList(): Observable<AppellationModel[]> {
    const params = new HttpParams().set("groups[]", "list");

    const request = this.http
      .get<AppellationModel[]>(
        `${environment.apiUrls[environment.name]}/appellations`,
        { params: params }
      )
      .pipe(map((data) => data["hydra:member"]));

    return request;
  }

  public async getAppellation(id, reload = true): Promise<AppellationModel> {
    if (localStorage["appellationElement" + id] && !reload) {
      return JSON.parse(localStorage["appellationElement" + id]);
    } else {
      return this.http
        .get<AppellationModel>(
          `${environment.apiUrls[environment.name]}/appellations/${id}`
        )
        .toPromise()
        .then((result) => {
          return result;
        });
    }
  }

  public updateAppellation(
    appellation: AppellationModel
  ): Promise<AppellationModel> {
    const params = {
      id: appellation.id,
      name: appellation.name,
      certified: appellation.certified,
      category: appellation.category.id,
      attributes: appellation.attributes,
      vatCategory: appellation.vatCategory.id,
      establishmentTypeAreas: appellation.establishmentTypeAreas,
    };
    return this.http
      .post<AppellationModel>(
        `${environment.apiUrls[environment.name]}/set_appellation`,
        params
      )
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  public replaceAppellation(
    appellationToRemove: Number,
    appellationToReplace: Number
  ): Observable<any> {
    const params = {
      appellationToRemove: appellationToRemove,
      appellationToReplace: appellationToReplace,
    };
    return this.http.post<any>(
      `${environment.apiUrls[environment.name]}/replace_appellations`,
      params
    );
  }
}
