import { ProductsFamilyModel } from '../products-families/products-family.model';
import { BrandModel } from '../brands/brand.model';
import { TaxeModel } from '../taxes/taxe.model';
import { ProviderModel } from '../providers/provider.model';
import { ProductModel } from '../products/product.model';
import { UnitModel } from '../units/unit.model';

export class ReferenceModel {
    id: number;
    brand: BrandModel;
    productsFamily: ProductsFamilyModel;
    code: string;
    libelle: string;
    provider: ProviderModel;
    product: ProductModel;
    taxes: TaxeModel[] | string[];
    packagingUnit: UnitModel;
    packagingQuantity: number;
    equivalence: string;
    prix_unitaire_ht: number;
    invoiceRatio: number;
    unit: UnitModel;
    unitValue: number;
    isUncertain: boolean;
    coopeoNote: string;
    info: string;
    appellationName: string;
    brandName: string;
    invoiceRows: any[];
    constructor() {
        this.product = new ProductModel;
        this.code = '';
    }
}

export class ResponseRefModel {
    response: string;
    reference: any;

    constructor() {
        this.reference = new ReferenceModel();
        this.response = '';
    }
}
