import { Injectable } from '@angular/core';
import { EstablishmentGroupModel } from './establishment-group.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { EstablishmentModel } from '../establishments/establishment.model';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentGroupService {

  constructor(private http: HttpClient) { }

  public getEstablishmentGroup(offset = 0, items_per_page = null, filterValue = null): Observable<EstablishmentGroupModel[]> {
    offset++;
    let params = new HttpParams().set('order[id]', 'desc').set('page', offset.toString());
    if (items_per_page) {
      params = params.set('items_per_page', items_per_page.toString());
    }
    if (filterValue) {
      params = params.set('name', filterValue);
    }
    const request = this.http.get<EstablishmentGroupModel[]>(`${environment.apiUrls[environment.name]}/establishment_groups`, { params: params });

    return request;
  }

  public getEstablishmentGroupList(value: string = null, itemPerPage: number = null): Observable<EstablishmentGroupModel[]> {
    let params = new HttpParams().set('order[id]', 'desc').append('groups[]', 'list');
    if (value) {
      params = params.append('name', value);
    }
    if (itemPerPage !== null) {
      params = params.append('items_per_page', itemPerPage.toString());
    }
    return this.http.get<EstablishmentGroupModel[]>(`${environment.apiUrls[environment.name]}/establishment_groups`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public createEstablishmentGroup(establishmentGroup: EstablishmentGroupModel): Observable<EstablishmentGroupModel> {
    return this.http.post<EstablishmentGroupModel>(`${environment.apiUrls[environment.name]}/establishment_groups`, establishmentGroup);
  }

  public updateEstablishmentGroup(establishmentGroup: EstablishmentGroupModel): Observable<EstablishmentGroupModel> {
    return this.http.put<EstablishmentGroupModel>(`${environment.apiUrls[environment.name]}/establishment_groups/${establishmentGroup.id}`, establishmentGroup);
  }

  public deleteEstablishmentGroup(establishmentGroup: EstablishmentGroupModel): Observable<EstablishmentGroupModel> {
    return this.http.delete<EstablishmentGroupModel>(`${environment.apiUrls[environment.name]}/establishment_groups/${establishmentGroup.id}`);
  }
}
