import { ProviderModel } from '../providers/provider.model';
import { TaxeModel } from '../taxes/taxe.model';

export class ReferencesTaxeModel {
    id: number;
    code: string;
    provider: ProviderModel;
    taxe: TaxeModel;

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: ReferencesTaxeModel;

    constructor() {
        this.element = new ReferencesTaxeModel();
        this.response = '';
    }
}
