import { Injectable } from '@angular/core';
import { UserModel } from './user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  public getUsers(): Observable<UserModel[]> {
    const params = new HttpParams().set('order[id]', 'desc');
    return this.http.get<UserModel[]>(`${environment.apiUrls[environment.name]}/users`, { params: params }).pipe(map(data => {
      const list = data['hydra:member'].map(user => ({
        ...user,
        createdAt: new Date(user.createdAt).toLocaleDateString('fr'),
        updatedAt: new Date(user.updatedAt).toLocaleDateString('fr'),
      }))

      return list
    }));
  }

  public createUser(user: UserModel): Observable<UserModel> {
    console.log(user)
    return this.http.post<UserModel>(`${environment.apiUrls[environment.name]}/users`, user, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  public updateUser(user: UserModel): Observable<UserModel> {
    return this.http.put<UserModel>(`${environment.apiUrls[environment.name]}/users/${user.id}`, user);
  }

  public activateUser(user: UserModel): Observable<UserModel> {
    return this.http.put<UserModel>(`${environment.apiUrls[environment.name]}/users/${user.id}`, { status: 1 });
  }

  public deleteUser(user: UserModel): Observable<UserModel> {
    return this.http.put<UserModel>(`${environment.apiUrls[environment.name]}/users/${user.id}`, { status: 0 });
  }

  public linkUserEstablishments(user: UserModel, establishments: string[], isOwner: boolean): Observable<UserModel> {
    return this.http.post<UserModel>(`${environment.apiUrls[environment.name]}/link-user-establishments`, {
      user: `/users/${user.id}`,
      establishments,
      isOwner
    })
  }

  public unlinkUserEstablishments(user: UserModel, establishment: string): Observable<UserModel> {
    return this.http.post<UserModel>(`${environment.apiUrls[environment.name]}/unlink-user-establishments`, {
      user: `/users/${user.id}`,
      establishment
    })
  }
}
