import { Injectable } from '@angular/core';
import { ProductModel } from './product.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient) { }

  public getProducts(appellationID, list = false, offset = 0, items_per_page = 10, filterValue = null): Observable<ProductModel[]> {
    offset++;
    let params = new HttpParams()
      .set('order[id]', 'desc')
      .set('appellation', appellationID);

    if (list === true) {
      params = params.append('groups[]', 'list')
    } else {
      params = params.set('page', offset.toString())
        .set('items_per_page', items_per_page.toString());
    }

    if (filterValue) {
      filterValue.forEach(element => {
        params = (element.value) ? params.append(element.type, element.value.toString()) : params;
      });
    }
    const request = this.http.get<ProductModel[]>(`${environment.apiUrls[environment.name]}/products`, { params: params });

    if (list === true) {
      return request.pipe(map(data => data['hydra:member']));
    } else {
      return request;
    }
  }

  public getProductsList(appellationID, offset = 0, items_per_page = 10, filterValue = null): Observable<ProductModel[]> {
    offset++;
    let body: any = {};
    body = {
      'currentPage': offset,
      'pageSize': items_per_page,
      'appellation': appellationID,
      ...filterValue
    };

    /* if (filterValue) {
      filterValue.forEach(element => {
        params = (element.value) ? params.append(element.type, element.value.toString()) : params;
      });
    } */
    const request = this.http.post<ProductModel[]>(`${environment.apiUrls[environment.name]}/list-products`, body);

    return request;
  }

  public getProductsName(): Observable<ProductModel[]> {
    const params = new HttpParams().set('groups[]', 'list');

    const request = this.http.get<ProductModel[]>(`${environment.apiUrls[environment.name]}/products`, { params: params }).pipe(map(data => data['hydra:member']));

    return request;
  }

  public async getProduct(id, reload = true): Promise<ProductModel> {
    if (localStorage['productElement' + id] && !reload) {
      return JSON.parse(localStorage['productElement' + id]);
    } else {
      return this.http.get<ProductModel>(`${environment.apiUrls[environment.name]}/products/${id}`)
        .toPromise()
        .then(result => {
          /* localStorage['productElement' + id] = JSON.stringify( result ); */
          return result;
        });
    }
  }

  public createProduct(product: ProductModel): Promise<ProductModel> {
    const params = {
      'appellation': '/appellations/' + product.appellation.id,
      'vat': product.vat,
      'values': product.values,
    };
    return this.http.post<ProductModel>(`${environment.apiUrls[environment.name]}/products`, params).toPromise()
      .then(res => {
        return res;
      });
  }

  public updateProduct(product: ProductModel): Promise<ProductModel> {
    const params = {
      'appellation': '/appellations/' + product.appellation.id,
      'vat': product.vat,
      'values': product.values,
    };
    return this.http.put<ProductModel>(`${environment.apiUrls[environment.name]}/products/${product.id}`, params).toPromise()
      .then(res => {
        return res;
      });
  }

  public deleteProduct(product: ProductModel): Promise<ProductModel> {
    return this.http.delete<ProductModel>(`${environment.apiUrls[environment.name]}/products/${product.id}`)
      .toPromise()
      .then(res => {
        return res;
      });
  }
}
