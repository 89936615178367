import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './_services';
import { UserModel } from './_models';
import { Router, RouterOutlet } from '@angular/router';
import { LoaderService } from './_services/loader.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public currentUser: UserModel;
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  public globaLoader: boolean;
  public globaCheck: boolean;
  public production: boolean;
  public project: String = environment.name;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private loaderService: LoaderService,
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService) {
    this.translate.setDefaultLang('fr');
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.authenticationService.currentProjectName.subscribe(name => {
      environment.name = name;
    });
    this.production = environment.production;
    this.currentUser = this.authenticationService.currentUserValue;
    this.loaderService.currentLoaderState.subscribe(state => { this.globaLoader = state; });
    this.loaderService.currentCheckState.subscribe(state => { this.globaCheck = state; });
  }

  public logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
