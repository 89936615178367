import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartConfiguration } from 'chart.js';
import * as moment from 'moment';
import { ThemeService } from 'ng2-charts';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-wrong-savings',
  templateUrl: './wrong-savings.component.html',
  styleUrls: ['./wrong-savings.component.scss']
})
export class WrongSavingsComponent implements OnInit {
// Dates filter
public dateStart = new FormControl({ value: moment().startOf('year'), disabled: true });
public dateEnd = new FormControl({ value: moment().endOf('year'), disabled: true });

public eventsSubject: Subject<any> = new Subject<any>();

constructor(private themeService: ThemeService) { }

ngOnInit() { this.setDarkTheme(); }

/**
 * Define the filters and redefine the chart
 * @param filters object of dateStart, dateEnd and list of establishments
 */
public setFilters(filters) {
  // Send the filters to all components called in the dashboard
  this.eventsSubject.next({ filters: filters });
}

/**
 * Define the dark theme
 */
public setDarkTheme() {
  const overrides: ChartConfiguration['options'] = {
    plugins: {
      datalabels: { display: false },
      title: { font: { family: 'Open Sans', style: 'normal' } },
      legend: { labels: { color: 'white', font: { family: 'Open Sans' } } },
    },
    scales: {
      x: { ticks: { color: 'white', font: { family: 'Open Sans' } }, grid: { color: 'rgba(255,255,255,0.1)' } },
      y: { ticks: { color: 'white', font: { family: 'Open Sans' } }, grid: { color: 'rgba(255,255,255,0.1)' } }
    }
  };
  this.themeService.setColorschemesOptions(overrides);
}nInit(): void {
  }

}
