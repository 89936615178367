import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators  } from '@angular/forms';
import { ProductModel, VAT_VAL } from '../../products/product.model';
import { AttributeValueModel, AttributeModel } from '@app/pages/attributes/attribute.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  public proForm: FormGroup;
  public valuesForm: FormGroup;
  public vatValues: number[] = VAT_VAL;

  constructor(
    public dialogRef: MatDialogRef<ProductDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {context: string, product: ProductModel}) {}

  ngOnInit() {
    this.proForm = new FormGroup({
      appellation: new FormControl(this.data.product.appellation),
      vat: new FormControl(this.data.product.vat),
    });
    if ( this.data.context === 'update' ) {
      this.proForm.addControl(
        'id', new FormControl(this.data.product.id)
      );
    }
    this.valuesForm = new FormGroup({});
    for (const value of this.data.product.appellation.attributesList) {
      if (this.data.context === 'update') {
        let val;
        const attribute = this.data.product.values.find((element: AttributeValueModel) => value['id'] === element.attribute['id']);
        if (!attribute) {
          val = '';
        } else if (attribute.attributeType === '0') { // TXT
          val = (attribute.customValue !== null) ? attribute.customValue : null;
        } else if (attribute.attributeType === '1') { // LIST
          val = attribute.value !== null ? attribute.value.id : null;
        } else if (attribute.attributeType === '2') { // BOOL
          val = (attribute.customValue !== null && attribute.customValue === 'true') ? true : false;
        } else {
          val = '';
        }
        this.valuesForm.addControl(
          value['id'].toString(),
          new FormControl(val)
        );
      } else {
        this.valuesForm.addControl(
          value['id'].toString(),
          new FormControl('')
        );
      }
    }
    this.proForm.addControl( 'values', this.valuesForm );
  }

  public hasError(controlName: string, errorName: string) {
    return this.proForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'productCanceled' });
  }

  public createProduct(createProFormValue: ProductModel) {
    if (this.proForm.valid) {
      this.dialogRef.close({ response: 'productCreated', product: this.sanitizeValues(createProFormValue) });
    }
  }

  public updateProduct(updateEltFormValue: ProductModel) {
    if (this.proForm.valid) {
      this.dialogRef.close({ response: 'productUpdated', product: this.sanitizeValues(updateEltFormValue) });
    }
  }

  public sanitizeValues(createProFormValue: ProductModel): ProductModel {
    const arrayValues = [];
    Object.entries(createProFormValue.values).forEach(([key, value]) => {
      const attribute = this.data.product.appellation.attributesList.find((element: AttributeModel) => +key === element.id);
      if (attribute.type === 0) { // TEXT
        arrayValues.push({
          'attribute': attribute['id'],
          'customValue': (value ? value : null)
        });
      } else if (attribute.type === 1) { // LIST
        arrayValues.push({
          'attribute': attribute['id'],
          'value': (value ? '/attribute_values/' + value : null)
        });
      } else if (attribute.type === 2) { // BOOL
        arrayValues.push({
          'attribute': attribute['id'],
          'customValue': (value ? 'true' : 'false')
        });
      }
    });
    createProFormValue.vat = (createProFormValue.vat) ? createProFormValue.vat : 0;
    createProFormValue.values = arrayValues;
    return createProFormValue;
  }

}
