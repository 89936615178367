import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ScopeModel } from '../scope.model';
import { ScopesService } from '../scopes.service';
import { ScopeDetailComponent } from '../scope-detail/scope-detail.component';
import { SlugifyPipe } from '@app/_helpers/slugify.pipe';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-scopes-list',
  templateUrl: './scopes-list.component.html',
  styleUrls: ['./scopes-list.component.scss']
})
export class ScopesListComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = ['id', 'fullScopeName', 'code', 'description', 'depending', 'actions'];
  public elementsList: ScopeModel[];
  public elementType: string;
  public elementsMatTable;
  public loading = true;
  private eventSubscription: Subscription;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private elementService: ScopesService,
    private slugifyPipe: SlugifyPipe,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.elementsMatTable = new MatTableDataSource<ScopeModel>();
    this.initMatTable();
    this.eventSubscription = this.elementService.getScopes().subscribe(
      (elements: ScopeModel[]) => {
        this.loading = false;
        this.elementsList = elements;
        this.elementsMatTable = new MatTableDataSource<ScopeModel>(this.elementsList);
        this.initMatTable();
      }
    );
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Search in table
   * @param filterValue string to research in array list
   */
  applyFilter(filterValue: string) {
    this.elementsMatTable.filter = this.slugifyPipe.transform(filterValue, true);
  }

  /**
   * Switch context between creation/edition feature
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  modalElement(context: string, element: ScopeModel, parentId: number = null): void {
    if (context === 'create') {
      this.createElement(context, parentId);
    } else if (context === 'update') {
      this.updateElement(context, element);
    }
  }

  /**
   * Display Modal for element's creation
   * @param context 'create' or 'update'
   */
  createElement(context: string, parentId: number) {
    const createApp = this.dialog.open(ScopeDetailComponent, {
      width: '600px',
      data: { element: new ScopeModel(), context: context, elementType: this.elementType, parentId: parentId, scopesList: this.elementsList },
      disableClose: true
    });

    // Observable after modal closed
    createApp.afterClosed().subscribe(result => {
      if (result.response === 'create') {
        const match = this.elementsList.find(i => this.slugifyPipe.transform(i.code, true) === this.slugifyPipe.transform(result.element.code, true));
        if (match === undefined) {
          this.elementService.createScope(result.element).subscribe(
            res => {
              this.snackBar.open('Element "' + result.element.name + '" créé.', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });
              this.elementsList.unshift(res);
              this.elementsMatTable = new MatTableDataSource<ScopeModel>(this.elementsList);
              this.initMatTable();
            }
          );
        } else {
          this.translate.get('global.actions.duplicateWith').subscribe((res: string) => {
            this.snackBar.open(res + ' "' + result.element.name + '".', '', {
              duration: 3000,
              panelClass: ['mat-bg-warn']
            });
          });
        }
      }
    });
  }

  /**
   * Display Modal for element's edition
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  updateElement(context: string, element: ScopeModel) {
    const updateElt = this.dialog.open(ScopeDetailComponent, {
      width: '600px',
      data: { element: element, context: context, scopesList: [...this.elementsList] },
      disableClose: true
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe(result => {
      if (result.response === 'update') {
        this.elementService.updateScope(result.element).subscribe(
          res => {
            const refList = [...this.elementsList];
            const index = refList.findIndex(elt => elt.id === result.element.id);
            if (index >= 0) {
              refList[index] = res;
              this.elementsMatTable = new MatTableDataSource<ScopeModel>(refList);
              this.initMatTable();
            }
            this.snackBar.open('Element "' + result.element.name + '" modifié.', '', {
              duration: 3000,
              panelClass: ['mat-bg-primary']
            });
          }
        );
      }
    });
  }

  /**
   * Delete element
   * @param element Element to remove
   */
  public deleteElement(element) {
    if (window.confirm('Confirmer la suppression de la reference "' + element.name + '" ?')) {
      this.elementService.deleteScope(element).subscribe(
        res => {
          const refList = this.elementsList;
          const index = refList.findIndex(array => array.id === element.id);
          refList.splice(index, 1);
          this.elementsMatTable = new MatTableDataSource<ScopeModel>(refList);
          this.initMatTable();
          this.snackBar.open('Element "' + element.name + '" supprimé.', '', {
            duration: 3000,
            panelClass: ['mat-bg-primary']
          });
        }
      );
    }
  }

  public checkV1(row) {
    if (row.code.indexOf('web') >= 0 || row.code.indexOf('mobile') >= 0) {
      return 'disabled';
    } else {
      return '';
    }
  }
}
