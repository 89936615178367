import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SlugifyPipe } from '@app/_helpers/slugify.pipe';
import { UserModel } from '@app/pages/users/user.model';
import { UsersService } from '@app/pages/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { UserDetailComponent } from '../user-detail/user-detail.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  public displayedColumns: string[] = ['id', 'fullName', 'email', 'createdAt', 'status', 'actions'];
  public elementsList: UserModel[];
  public elementType: string;
  public elementsMatTable;
  public loading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private elementService: UsersService,
    private slugifyPipe: SlugifyPipe,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.elementsMatTable = new MatTableDataSource<UserModel>();
    this.initMatTable();
    this.elementService.getUsers().subscribe(
      (elements: UserModel[]) => {
        this.loading = false;
        this.elementsList = elements;
        this.elementsMatTable = new MatTableDataSource<UserModel>(this.elementsList);
        this.initMatTable();
      }
    );
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Search in table
   * @param filterValue string to research in array list
   */
  applyFilter(filterValue: string) {
    this.elementsMatTable.filter = this.slugifyPipe.transform(filterValue, true);
  }

  /**
   * Switch context between creation/edition feature
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  modalElement(context: string, element: UserModel): void {
    if (context === 'create') {
      this.createElement(context);
    } else if (context === 'update') {
      this.updateElement(context, element);
    }
  }

  /**
   * Display Modal for element's creation
   * @param context 'create' or 'update'
   */
  createElement(context: string) {
    const createApp = this.dialog.open(UserDetailComponent, {
      width: '600px',
      data: { element: new UserModel(), context: context, elementType: this.elementType },
      disableClose: true
    });

    // Observable after modal closed
    createApp.afterClosed().subscribe(result => {
      if (result.user.response === 'create') {
        const user = result.user.element;
        const match = this.elementsList.find(i => this.slugifyPipe.transform(i.email, true) === this.slugifyPipe.transform(user.email, true));
        if (match === undefined) {
          this.elementService.createUser(user).subscribe(
            res => {
              const establishmentsIri = result.establishments.map(establishment => establishment['@id']);

              this.elementService.linkUserEstablishments(res, establishmentsIri, true).subscribe(res => {
                this.snackBar.open('Element "' + user.email + '" créé.', '', {
                  duration: 3000,
                  panelClass: ['mat-bg-primary']
                });
                this.elementsList.unshift(res);
                this.elementsMatTable = new MatTableDataSource<UserModel>(this.elementsList);
                this.initMatTable();
                this.snackBar.open('Element "' + user.email + '" créé.', '', {
                  duration: 3000,
                  panelClass: ['mat-bg-primary']
                });
              })
            }
          );
        } else {
          this.translate.get('global.actions.duplicateWith').subscribe((res: string) => {
            this.snackBar.open(res + ' "' + user.email + '".', '', {
              duration: 3000,
              panelClass: ['mat-bg-warn']
            });
          });
        }
      }
    });
  }

  /**
   * Display Modal for element's edition
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  updateElement(context: string, element: UserModel) {
    const updateElt = this.dialog.open(UserDetailComponent, {
      width: '600px',
      data: { element: element, context: context },
      disableClose: true
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe(result => {
      if (result.user.response === 'update') {
        const user = result.user.element;
        const establishmentsIri = result.establishments.map(establishment => establishment['@id']);

        this.elementService.updateUser(user).subscribe(
          res => {
            this.elementService.linkUserEstablishments(res, establishmentsIri, true).subscribe(res => {
              const refList = this.elementsList;
              const index = refList.findIndex(array => array.id === user.id);
              refList[index] = res;
              this.elementsMatTable = new MatTableDataSource<UserModel>(refList);
              this.initMatTable();
              this.snackBar.open('Element "' + user.email + '" modifié.', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });
            })
          }
        );
      }
    });
  }

  activateUser(element: UserModel) {
    this.elementService.activateUser(element).subscribe(
      res => {
        const refList = this.elementsList;
        const index = refList.findIndex(array => array.id === element.id);
        refList[index].status = 1;
        this.elementsMatTable = new MatTableDataSource<UserModel>(refList);
        this.initMatTable();
        this.snackBar.open('Utilisateur "' + element.email + '" activé.', '', {
          duration: 3000,
          panelClass: ['mat-bg-primary']
        });
      }
    )
  }

  /**
   * Delete element
   * @param element Element to remove
   */
  public deleteElement(element) {
    if (window.confirm('Confirmer le blocage de l\'utilisateur "' + element.email + '" ?')) {
      this.elementService.deleteUser(element).subscribe(
        res => {
          const refList = this.elementsList;
          const index = refList.findIndex(array => array.id === element.id);
          refList[index].status = 0;
          this.elementsMatTable = new MatTableDataSource<UserModel>(refList);
          this.initMatTable();
          this.snackBar.open('Utilisateur "' + element.email + '" désactivé.', '', {
            duration: 3000,
            panelClass: ['mat-bg-primary']
          });
        }
      );
    }
  }
}
