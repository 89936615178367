import { Injectable } from '@angular/core';
import { ReferencesAlcoholModel } from './references-alcohol.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferencesAlcoholsService {

  constructor(private http: HttpClient) { }

  public getReferencesAlcohols(): Observable<ReferencesAlcoholModel[]> {
    const params = new HttpParams().set('order[id]', 'desc');
    return this.http.get<ReferencesAlcoholModel[]>(`${environment.apiUrls[environment.name]}/reference_alcohols`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public createReferencesAlcohol(referencesAlcohol: ReferencesAlcoholModel): Observable<ReferencesAlcoholModel> {
    const params = {
      'code': referencesAlcohol.code,
      'degree': referencesAlcohol.degree,
      'provider': '/providers/' + referencesAlcohol.provider.id,
      'type': '/type_alcohols/' + referencesAlcohol.type.id
    };
    return this.http.post<ReferencesAlcoholModel>(`${environment.apiUrls[environment.name]}/reference_alcohols`, params);
  }

  public updateReferencesAlcohol(referencesAlcohol: ReferencesAlcoholModel): Observable<ReferencesAlcoholModel> {
    const params = {
      'code': referencesAlcohol.code,
      'degree': referencesAlcohol.degree,
      'provider': '/providers/' + referencesAlcohol.provider.id,
      'type': '/type_alcohols/' + referencesAlcohol.type.id
    };
    return this.http.put<ReferencesAlcoholModel>(`${environment.apiUrls[environment.name]}/reference_alcohols/${referencesAlcohol.id}`, params);
  }

  public deleteReferencesAlcohol(referencesAlcohol: ReferencesAlcoholModel): Observable<ReferencesAlcoholModel> {
    return this.http.delete<ReferencesAlcoholModel>(`${environment.apiUrls[environment.name]}/reference_alcohols/${referencesAlcohol.id}`);
  }
}
