import { Injectable } from '@angular/core';
import { FeeModel } from './fee.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeesService {

  constructor(private http: HttpClient) { }

  public getfees(): Observable<FeeModel[]> {
    const params = new HttpParams().set('order[id]', 'desc');
    return this.http.get<FeeModel[]>(`${environment.apiUrls[environment.name]}/fees`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public createfee(fee: FeeModel): Observable<FeeModel> {
    return this.http.post<FeeModel>(`${environment.apiUrls[environment.name]}/fees`, fee);
  }

  public updatefee(fee: FeeModel): Observable<FeeModel> {
    return this.http.put<FeeModel>(`${environment.apiUrls[environment.name]}/fees/${fee.id}`, fee);
  }

  public deletefee(fee: FeeModel): Observable<FeeModel> {
    return this.http.delete<FeeModel>(`${environment.apiUrls[environment.name]}/fees/${fee.id}`);
  }
}
