import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { UserModel } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentProjectNameSubject: BehaviorSubject<any>;
    public currentProjectName: Observable<any>;

    private currentUserSubject: BehaviorSubject<UserModel>;
    public currentUser: Observable<UserModel>;

    constructor(private http: HttpClient) {
        this.currentProjectNameSubject = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('name')));
        this.currentProjectName = this.currentProjectNameSubject.asObservable();

        this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): UserModel {
        return this.currentUserSubject.value;
    }

    setCurrentProjectName(name) {
        localStorage.setItem('name', JSON.stringify(name));
        environment.name = name;
        this.currentProjectNameSubject.next(name);
    }

    public get currentProjectNameValue(): any {
        return this.currentProjectNameSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrls[environment.name]}/login_check`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('currentUserActive', 'true');
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    loginRT(refreshToken: string) {
        const headers = new HttpHeaders().set('X-Skip-Interceptor', '');

        return this.http.post<any>(`${environment.apiUrls[environment.name]}/api/token/refresh`, { 'refresh_token': refreshToken }, { headers })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('currentUserActive', 'true');
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
