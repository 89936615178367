import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaModel } from '@app/pages/area/area.model';
import { AreaService } from '@app/pages/area/area.service';
import { TextValidator } from '@app/_validators/text.validator';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { EstablishmentTypeModel, ResponseModalModel } from '../establishment-type.model';

@Component({
  selector: 'app-establishment-type-detail',
  templateUrl: './establishment-type-detail.component.html',
  styleUrls: ['./establishment-type-detail.component.scss']
})
export class EstablishmentTypeDetailComponent implements OnInit {

  public refForm: FormGroup;
  public responseElt: ResponseModalModel =  new ResponseModalModel();
  public areasList: AreaModel[];

  constructor(
    public dialogRef: MatDialogRef<EstablishmentTypeDetailComponent>,
    public areaService: AreaService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.responseElt.response = '';
      this.responseElt.element = new EstablishmentTypeModel();
    }

  ngOnInit() {
    this.areaService.getAreaList().subscribe(
      (elements: AreaModel[]) => {
        this.areasList = elements;
      }
    );
    this.refForm = new FormGroup({
      name: new FormControl(this.data.element.name, [Validators.required, TextValidator.noWhiteSpace]),
      iosIcon: new FormControl(this.data.element.iosIcon, [TextValidator.noWhiteSpace]),
      webIcon: new FormControl(this.data.element.webIcon, [TextValidator.noWhiteSpace]),
      area: new FormControl((this.data.element.area) ? this.data.element.area.map(elt => elt.id) : [], [Validators.required])
    });
    if (this.data.context === 'update') {
      this.refForm.addControl(
        'id', new FormControl(this.data.element.id, [])
      );
    }
  }

  public getSpace(element, space: number = 0) {
    if (element.parent) {
      space++;
      return this.getSpace(element.parent, space);
    }
    return space;
  }

  public hasError(controlName: string, errorName: string) {
    return this.refForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.refForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }

}
