import { Injectable } from '@angular/core';
import { ScopeModel } from './scope.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScopesService {

  constructor(private http: HttpClient) { }

  public getScopes(): Observable<ScopeModel[]> {
    const params = new HttpParams().set('order[id]', 'desc');
    return this.http.get<ScopeModel[]>(`${environment.apiUrls[environment.name]}/scopes`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public createScope(scope: ScopeModel): Observable<ScopeModel> {
    const params = {
      'code': scope.code,
      'name': scope.name,
      'description': scope.description,
      ...(scope.parent && {'parent': scope.parent.id}),
      ...(scope.depending && {'depending': scope.depending.id})
    };
    return this.http.post<ScopeModel>(`${environment.apiUrls[environment.name]}/scopes`, params);
  }

  public updateScope(scope: ScopeModel): Observable<ScopeModel> {
    const params = {
      'code': scope.code,
      'name': scope.name,
      'description': scope.description,
      ...(scope.parent && {'parent': scope.parent.id}),
      ...(scope.depending && {'depending': scope.depending.id})
    };
    return this.http.put<ScopeModel>(`${environment.apiUrls[environment.name]}/scopes/${scope.id}`, params);
  }

  public deleteScope(scope: ScopeModel): Observable<ScopeModel> {
    return this.http.delete<ScopeModel>(`${environment.apiUrls[environment.name]}/scopes/${scope.id}`);
  }
}
