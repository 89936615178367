import { AppellationModel } from '../appellations/appellation.model';
import { AttributeValueModel } from '../attributes/attribute.model';
import { VatcategoryModel } from '../vatcategories/vatcategory.model';

export class ProductModel {
  id: number;
  refsCount: number;
  name: string;
  appellation: AppellationModel;
  values: AttributeValueModel[];
  attributeValues: any;
  vat: any;
  productName: string;
  vatCategory: VatcategoryModel;

  constructor(appellation: AppellationModel = new AppellationModel()) {
    this.appellation = appellation;
  }
}

export class ResponseModalModel {
  response: string;
  element: ProductModel;

  constructor() {
    this.element = new ProductModel();
    this.response = '';
  }
}

export const VAT_VAL = [5.5, 10, 20, 2.1, 8, 8.5, 17, 14, 3, 6, 19, 22, 19.6, 1.9, 23].sort(function(a, b){return a-b});
