export const environment = {
  name: localStorage.getItem("name")
    ? JSON.parse(localStorage.getItem("name"))
    : "chr",
  sentryEnv: "preprod",
  production: true,
  apiUrls: {
    chr: "https://api-dev-chr.coopeo.fr",
    btp: "https://api-dev-btp.coopeo.fr",
    "v2-chr": "https://api-dev.coopeo.fr/api/v2",
    "v2-btp": "https://api-dev.coopeo.fr/api-cpw/v2",
  },
  sseUrls: {
    chr: "https://api-dev-chr.coopeo.fr:1337/.well-known/mercure",
    btp: "https://api-dev-btp.coopeo.fr:1337/.well-known/mercure",
  },
  account: {
    email: "",
    password: "",
  },
  version: "2.722",
};
