import { Component, OnInit, ViewChild } from '@angular/core';
import { UnitModel } from '../unit.model';
import { UnitsService } from '../units.service';
import { UnitDetailComponent } from '../unit-detail/unit-detail.component';
import { SlugifyPipe } from '@app/_helpers/slugify.pipe';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-units-list',
  templateUrl: './units-list.component.html',
  styleUrls: ['./units-list.component.scss']
})
export class UnitsListComponent implements OnInit {
  public displayedColumns: string[] = ['id', 'fullUnitName', 'shortName', 'types', 'actions'];
  public elementsList: UnitModel[];
  public elementType: string;
  public elementsMatTable;
  public loading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private elementService: UnitsService,
    private slugifyPipe: SlugifyPipe,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.elementsMatTable = new MatTableDataSource<UnitModel>();
    this.initMatTable();
    this.elementService.getUnits().subscribe(
      (elements: UnitModel[]) => {
        this.loading = false;
        this.elementsList = elements;
        this.elementsMatTable = new MatTableDataSource<UnitModel>(this.elementsList);
        this.initMatTable();
      }
    );
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.filterPredicate = (data: UnitModel, filter) => {
      const dataStr = this.slugifyPipe.transform((data.fullUnitName !== null) ? data.fullUnitName : data.name, true);
      return dataStr.indexOf(filter) !== -1;
    };
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Search in table
   * @param filterValue string to research in array list
   */
  applyFilter(filterValue: string) {
    this.elementsMatTable.filter = this.slugifyPipe.transform(filterValue, true);
  }

  public getSpace(element, space: number = 0) {
    if(element.parent) {
      space++;
      return this.getSpace(element.parent, space);
    }
    return space;
  }

  /**
   * Switch context between creation/edition feature
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  modalElement(context: string, element: UnitModel): void {
    if (context === 'create') {
      this.createElement(context);
    } else if (context === 'update') {
      this.updateElement(context, element);
    }
  }

  /**
   * Display Modal for element's creation
   * @param context 'create' or 'update'
   */
  createElement(context: string) {
    const createApp = this.dialog.open(UnitDetailComponent, {
      width: '600px',
      data: {element: new UnitModel(), context: context, elementType: this.elementType},
      disableClose: true
    });

    // Observable after modal closed
    createApp.afterClosed().subscribe(result => {
      if ( result.response === 'create' ) {
        const match = this.elementsList.find(i => this.slugifyPipe.transform(i.name, true) === this.slugifyPipe.transform(result.element.name, true));
        if (match === undefined) {
          this.elementService.createUnit(result.element).subscribe(
            res => {
              this.snackBar.open('Element "' + result.element.name + '" créé.', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });
              this.elementsList.unshift(res);
              this.elementsMatTable = new MatTableDataSource<UnitModel>(this.elementsList);
              this.initMatTable();
            }
          );
        } else {
          this.translate.get('global.actions.duplicateWith').subscribe((res: string) => {
            this.snackBar.open(res + ' "' + result.element.name + '".', '', {
              duration: 3000,
              panelClass: ['mat-bg-warn']
            });
          });
        }
      }
    });
  }

  /**
   * Display Modal for element's edition
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  updateElement(context: string, element: UnitModel) {
    const updateElt = this.dialog.open(UnitDetailComponent, {
      width: '600px',
      data: {element: element, context: context},
      disableClose: true
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe(result => {
      if ( result.response === 'update' ) {
        this.elementService.updateUnit(result.element).subscribe(
          res => {
            const refList = this.elementsList;
            const index = refList.findIndex(array => array.id === result.element.id);
            refList[index] = res;
            this.elementsMatTable = new MatTableDataSource<UnitModel>(refList);
            this.initMatTable();
            this.snackBar.open('Element "' + result.element.name + '" modifié.', '', {
              duration: 3000,
              panelClass: ['mat-bg-primary']
            });
          }
        );
      }
    });
  }

  /**
   * Delete element
   * @param element Element to remove
   */
  public deleteElement(element) {
    if (window.confirm('Confirmer la suppression de "' + element.name + '" ?')) {
      this.elementService.deleteUnit(element).subscribe(
        res => {
          const refList = this.elementsList;
          const index = refList.findIndex(array => array.id === element.id);
          refList.splice(index, 1);
          this.elementsMatTable = new MatTableDataSource<UnitModel>(refList);
          this.initMatTable();
          this.snackBar.open('Element "' + element.name + '" supprimé.', '', {
            duration: 3000,
            panelClass: ['mat-bg-primary']
          });
        }
      );
    }
  }
}
