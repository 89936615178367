export class EstablishmentGroupModel {
    id: number;
    name: string;
    establishments: Number[];

    constructor() {
        this.name = '';
    }
}

export class ResponseModalModel {
    response: string;
    element: EstablishmentGroupModel;

    constructor() {
        this.element = new EstablishmentGroupModel();
        this.response = '';
    }
}
