import { Injectable } from '@angular/core';
import { TypeAlcoholModel } from './type-alcohol.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TypesAlcoholsService {

  constructor(private http: HttpClient) { }

  public getTypesAlcohols(): Observable<TypeAlcoholModel[]> {
    const params = new HttpParams().set('order[id]', 'desc');
    return this.http.get<TypeAlcoholModel[]>(`${environment.apiUrls[environment.name]}/type_alcohols`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public createTypeAlcohol(typeAlcohol: TypeAlcoholModel): Observable<TypeAlcoholModel> {
    return this.http.post<TypeAlcoholModel>(`${environment.apiUrls[environment.name]}/type_alcohols`, typeAlcohol);
  }

  public updateTypeAlcohol(typeAlcohol: TypeAlcoholModel): Observable<TypeAlcoholModel> {
    return this.http.put<TypeAlcoholModel>(`${environment.apiUrls[environment.name]}/type_alcohols/${typeAlcohol.id}`, typeAlcohol);
  }

  public deleteTypeAlcohol(typeAlcohol: TypeAlcoholModel): Observable<TypeAlcoholModel> {
    return this.http.delete<TypeAlcoholModel>(`${environment.apiUrls[environment.name]}/type_alcohols/${typeAlcohol.id}`);
  }
}
