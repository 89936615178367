import { Component, OnInit, ViewChild } from '@angular/core';
import { FileSystemFileEntry, FileSystemDirectoryEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ReferencesService } from '../references/references.service';
import { ReferenceModel } from '../references/reference.model';
import { ReferenceMercurialeModel, ReferenceMercurialeDetailModel } from './reference-invoice.model';
import { ProviderModel } from '../providers/provider.model';
import { ProvidersService } from '../providers/providers.service';
import { InvoiceFileModel } from '../invoices-files/invoice-file.model';
import { InvoicesFilesService } from '../invoices-files/invoices-files.service';
import { EstablishmentService } from '../establishments/establishment.service';
import { EstablishmentModel } from '../establishments/establishment.model';
import { ConfirmationDialogComponent } from '@app/_components/confirmation-dialog';
import { ImportCsvService } from './import-csv.service';
import { parse } from 'date-fns';
import { isAfter, isEqual } from 'date-fns/esm';
import { ReferencesTaxesService } from '../references-taxes/references-taxes.service';
import { ReferencesTaxeModel } from '../references-taxes/references-taxe.model';
import { ReferencesFeesService } from '../references-fees/references-fees.service';
import { ReferencesFeeModel } from '../references-fees/references-fee.model';
import { FeeModel } from '../fees/fee.model';
import { FeesService } from '../fees/fees.service';
import { ReferencesAlcoholsService } from '../references-alcohols/references-alcohols.service';
import { ReferencesAlcoholModel } from '../references-alcohols/references-alcohol.model';
import { StickersService } from '../stickers/stickers.service';
import { StickerModel } from '../stickers/sticker.model';
import { UnitModel } from '../units/unit.model';
import { forkJoin } from 'rxjs';
import { LoaderService } from '@app/_services/loader.service';
import { EstablishmentGroupService } from '../establishment-group/establishment-group.service';
import { EstablishmentGroupModel } from '../establishment-group/establishment-group.model';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-import-mercuriale',
  templateUrl: './import-mercuriale.component.html',
  styleUrls: ['./import-mercuriale.component.scss']
})
export class ImportMercurialeComponent implements OnInit {
  public csvData: ReferenceMercurialeModel[] = [];
  public files: NgxFileDropEntry[] = [];
  public allReferences: ReferenceModel[];
  public listfees: FeeModel[];
  public listStickers: StickerModel[];
  public elementsMatTable;
  public displayedColumns: string[] = ['reference'];
  public providers: ProviderModel[];
  public establishments: EstablishmentModel[];
  public establishmentsGroups: EstablishmentGroupModel[];
  public refAlcohol: ReferencesAlcoholModel[];
  public dossier: number;
  public dossierGroup: number;
  public lien_fichier: string;
  public fournisseur: number;
  public date_debut: Date;
  public date_fin: Date;
  public validForm: boolean;
  public dateNow: string;
  public dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  public loading: boolean;
  public establishmentType = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public snackBar: MatSnackBar,
    public referenceServices: ReferencesService,
    public providerService: ProvidersService,
    public referenceTaxeService: ReferencesTaxesService,
    public referencefeeService: ReferencesFeesService,
    public referenceAlcoholService: ReferencesAlcoholsService,
    public stickersService: StickersService,
    public feeService: FeesService,
    public establishmentService: EstablishmentService,
    public establishmentGroupService: EstablishmentGroupService,
    public invoiceService: InvoicesFilesService,
    public importFactureService: ImportCsvService,
    public dialog: MatDialog,
    private loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.changeLoader(true);
    forkJoin(
      this.providerService.getProviders(),
      this.establishmentService.getEstablishments(),
      this.establishmentGroupService.getEstablishmentGroup(),
      this.referenceAlcoholService.getReferencesAlcohols(),
      this.feeService.getfees(),
      this.stickersService.getStickers()
    ).subscribe(
      results => {
        this.providers = results[0];
        this.establishments = results[1];
        this.establishmentsGroups = results[2]['hydra:member'];
        this.refAlcohol = results[3];
        this.listfees = results[4];
        this.listStickers = results[5];
      },
      (err) => {
      },
      () => {
        this.loaderService.changeLoader(false);
      },
    );
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Date FNS methods
   */
  public isAfter(dateA: Date, dateB: Date) { return isAfter(dateA, dateB); }
  public isAfterNow(dateA: Date) { return isAfter(dateA, new Date()); }
  public isEqual(dateA, dateB) { return isEqual(dateA, dateB); }

  /**
   * File upload
   * @param event Event
   */
  public dropped(event: NgxFileDropEntry[]) {
    this.dossier = null;
    this.dossierGroup = null;
    this.files = event;
    if (this.files.length > 1) {
      this.snackBar.open('Merci de charger un seul document à la fois', '', { duration: 3000, panelClass: ['mat-bg-warn'] });
      return;
    }
    for (const { index, droppedFile } of this.files.map((droppedFile, index) => ({ index, droppedFile }))) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (!fileEntry.isFile || !this.isFileAllowed(file.name)) {
            this.snackBar.open('Format incorrect: le fichier doit être un .csv', '', { duration: 3000, panelClass: ['mat-bg-warn'] });
            return;
          } else {
            // Set this.csvData with the CSV lines
            this.extractAndPersistCsv(file);
            // Active loader during the initialisation of front
            this.loaderService.changeLoader(true);
          }
        });
      }
    }
  }

  public isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.csv'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  /**
   * Check the number and name of columns from the first line of CSV
   */
  public checkCSV(csvLine): boolean {
    // Convert first line of CSV in array
    csvLine = csvLine.split(';').filter(n => n.trim().replace(/(\r\n|\n|\r)/gm, ''));
    const headerLine = ['dossier', 'lien_fichier', 'fournisseur', 'mercuriale', 'reference_fournisseur', 'libelle', 'prix_unitaire_ht', 'date_debut', 'date_fin'];
    const difference = headerLine.filter(x => !csvLine.includes(x));
    return (difference.length === 0 && csvLine.length === 9);
  }

  public extractAndPersistCsv(file: File): void {
    this.csvData = [];
    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const csv = reader.result as string;
      let linesCsv = csv.split(/\r?\n?\r\n/);
      // Remove empty values
      linesCsv = linesCsv.filter(line => line.length > 1);
      try {
        // Remove first line of header
        const firstLine = linesCsv.splice(0, 1);
        // Check number and names of columns
        if (!this.checkCSV(firstLine[0])) {
          throw new Error('Colonnes non conformes, les en-têtes présentent une erreur.');
        }
        let refIndex = 0;
        let factureTmp = new ReferenceMercurialeModel;

        // Get references for the query
        const listReferences = [];
        linesCsv.forEach((element) => {
          const lineCSV: string[] = element.split(';');
          // Correct length of reference with '0'
          let referenceWithZero = lineCSV['4'];
          // Get the provider if exist in database
          if (lineCSV[2]) { this.fournisseur = +lineCSV[2]; }
          // Get the provider if exist in database
          let lineProvider;
          let matchProvider;
          if (matchProvider = this.providers.find(provider => +this.fournisseur === provider.id)) {
            lineProvider = matchProvider;
          } else {
            lineProvider = new ProviderModel();
            lineProvider.id = +this.fournisseur;
          }
          if (lineProvider && referenceWithZero.length < lineProvider.referenceLength) {
            const num0 = lineProvider.referenceLength - referenceWithZero.length;
            referenceWithZero = '0'.repeat(num0) + referenceWithZero;
          }
          listReferences.push(referenceWithZero);
        });
        this.loaderService.changeLoader(true);
        this.referenceServices.getListReferences(listReferences).subscribe(list => {
          this.loaderService.changeLoader(false);
          this.allReferences = list;
          // Csv lines processing
          linesCsv.forEach((element, index, array) => {
            const lineCSV: string[] = element.split(';');
            let lineReference: ReferenceModel, matchReference: ReferenceModel,
              lineProvider,
              matchProvider: ProviderModel;
            // If link of invoice detected on 3th column, we persist a new invoice's informations in this.csvData
            if (lineCSV[2]) {
              refIndex = 0;
              // Persist data into this.csvData on new invoice detection
              if (factureTmp.references.length > 0) {
                this.csvData.push(factureTmp);
              }
              factureTmp = new ReferenceMercurialeModel;
              // Persist establishment id
              if (lineCSV[0]) { this.dossier = +lineCSV[0]; }
              // Persist link file
              if (lineCSV[1]) { this.lien_fichier = lineCSV[1]; }
              // Get the provider if exist in database
              if (matchProvider = this.providers.find(provider => +lineCSV[2] === provider.id)) {
                lineProvider = matchProvider;
              } else {
                lineProvider = new ProviderModel();
                lineProvider.id = +lineCSV[2];
              }
              factureTmp.dossier = this.dossier;
              factureTmp.lien_fichier = this.lien_fichier;
              factureTmp.fournisseur = lineProvider;
              factureTmp.mercuriale = lineCSV[3];
            }
            // Correct length of reference with '0'
            let referenceWithZero = lineCSV['4'];
            if (factureTmp.fournisseur && lineCSV[4].length < factureTmp.fournisseur.referenceLength) {
              const num0 = factureTmp.fournisseur.referenceLength - lineCSV[4].length;
              referenceWithZero = '0'.repeat(num0) + referenceWithZero;
            }
            // Check & assign reference's object where reference && provider match with the csv information
            lineReference = new ReferenceModel();
            if (matchReference = this.allReferences.find((ref: ReferenceModel) => (referenceWithZero === ref.code && factureTmp.fournisseur.id === ref.provider.id))) {
              Object.assign(lineReference, matchReference);
            } else {
              lineReference.code = lineCSV[4];
              lineReference.libelle = lineCSV[5];
            }
            // Create & assign reference's object informations
            factureTmp.references[refIndex] = new ReferenceMercurialeDetailModel();
            factureTmp.references[refIndex].prix_unitaire_ht = (lineCSV[6]) ? parseFloat(lineCSV[6].replace(',', '.')) : 0;
            factureTmp.references[refIndex].mercurialeRatio = (lineReference.invoiceRatio) ? lineReference.invoiceRatio : 1;
            factureTmp.references[refIndex].reference = lineReference;
            if (this.checkPrice(factureTmp.references[refIndex].prix_unitaire_ht)) {
              factureTmp.references[refIndex].status = false;
            }

            // Convert date format
            if (lineCSV[7] && lineCSV[8]) {
              this.date_debut = factureTmp.references[refIndex].date_debut = parse(lineCSV[7], 'dd/MM/yyyy', new Date());
              this.date_fin = factureTmp.references[refIndex].date_fin = parse(lineCSV[8], 'dd/MM/yyyy', new Date());
            } else if (this.date_debut && this.date_fin) {
              factureTmp.references[refIndex].date_debut = this.date_debut;
              factureTmp.references[refIndex].date_fin = this.date_fin;
            } else {
              this.snackBar.open('Erreur de saisie des dates CSV', '', { duration: 3000, panelClass: ['mat-bg-warn'] });
              throw new Error('Erreur de saisie des dates CSV');
            }

            refIndex++;

            // Persist data into csvData on last reference detected
            if (index === (array.length - 1)) {
              this.csvData.push(factureTmp);
            }
          });
          this.refreshFormData();
        },
          () => this.loaderService.changeLoader(false)
        );
      } catch (errMessage) {
        console.error(errMessage);
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '600px', disableClose: false });
        this.dialogRef.componentInstance.confirmMessage = 'Erreur sur l\'import.';
        this.dialogRef.componentInstance.confirmMessageDetail = errMessage.message;
        this.dialogRef.componentInstance.styleConfirm = 'confirm';
        this.loaderService.changeLoader(false);
      }
    };
    this.loaderService.changeLoader(false);
  }

  public checkPrice(price) {
    return (isNaN(price) || price == null);
  }

  public getTypeReference(obj: ReferenceModel | ReferencesTaxeModel | ReferencesFeeModel) {
    if (obj instanceof ReferencesTaxeModel) {
      return 1;
    } else if (obj instanceof ReferencesFeeModel) {
      return 2;
    } else {
      return 0;
    }
  }

  public refreshFormData() {
    this.csvData.forEach((group: any) => {
      // Reorder by date ASC to group them in front
      group.references.sort((a, b) => isAfter(a.date_livraison, b.date_livraison) ? 1 : (isAfter(b.date_livraison, a.date_livraison) ? -1 : 0));
    });
    // Check Form
    this.checkValidForm();
  }

  public refreshAllReferenceData(invoiceData: ReferenceMercurialeModel, invoiceIndex) {
    invoiceData.references.forEach((ref: ReferenceMercurialeDetailModel, refIndex) => {
      this.refreshReferenceData(invoiceIndex, refIndex, ref.reference.code);
    });
  }

  public getReferenceBeforeRefreshReferenceData(invoiceIndex, refIndex, refValue) {
    // Correct length of reference with '0'
    let referenceWithZero = refValue;
    if (this.csvData[invoiceIndex].fournisseur && refValue.length < this.csvData[invoiceIndex].fournisseur.referenceLength) {
      const num0 = this.csvData[invoiceIndex].fournisseur.referenceLength - refValue.length;
      referenceWithZero = '0'.repeat(num0) + referenceWithZero;
    }
    if (!this.allReferences.find(ref => ((referenceWithZero === ref.code) && (this.csvData[invoiceIndex].fournisseur.id === ref.provider.id)))) {
      this.referenceServices.getReferencesByCode(referenceWithZero, this.csvData[invoiceIndex].fournisseur.id).subscribe(newReference => {
        if (newReference[0] && !this.allReferences.find(element => element.code === referenceWithZero)) {
          this.allReferences.push(newReference[0]);
        }
        this.refreshReferenceData(invoiceIndex, refIndex, referenceWithZero);
      });
    } else {
      this.refreshReferenceData(invoiceIndex, refIndex, referenceWithZero);
    }
  }

  public refreshReferenceData(invoiceIndex, refIndex, refValue) {
    // Check if reference exist in database
    let lineReference: ReferenceModel;
    let match: ReferenceModel;
    // Correct length of reference with '0'
    let referenceWithZero = refValue;
    if (this.csvData[invoiceIndex].fournisseur && refValue.length < this.csvData[invoiceIndex].fournisseur.referenceLength) {
      const num0 = this.csvData[invoiceIndex].fournisseur.referenceLength - refValue.length;
      referenceWithZero = '0'.repeat(num0) + referenceWithZero;
    }
    // Check & assign reference's object where reference && provider match with the csv information
    lineReference = new ReferenceModel();
    if (match = this.allReferences.find((ref: ReferenceModel) => (referenceWithZero === ref.code && this.csvData[invoiceIndex].fournisseur.id === ref.provider.id))) {
      Object.assign(lineReference, match);
    } else {
      lineReference.code = refValue;
    }
    // Clone reference into csvData
    this.csvData[invoiceIndex].references[refIndex].reference = lineReference;
    this.csvData[invoiceIndex].references[refIndex].mercurialeRatio = (lineReference.invoiceRatio) ? lineReference.invoiceRatio : 1;
    if (this.checkPrice(this.csvData[invoiceIndex].references[refIndex].prix_unitaire_ht) === true) {
      this.csvData[invoiceIndex].references[refIndex].status = false;
    } else {
      this.csvData[invoiceIndex].references[refIndex].status = true;
    }
    this.refreshFormData();
  }

  /**
   * Remove reference from invoice
   * @param invoiceIndex Index of invoice on the csvData
   * @param refIndex Index of reference on the csvData
   */
  public removeLine(invoiceIndex, elementIndex, force = false) {
    if (force) {
      this.csvData[invoiceIndex].references.splice(elementIndex, 1);
      if (this.csvData[invoiceIndex].references.length === 0) {
        this.deleteMercuriale(invoiceIndex, true);
      }
      return;
    }

    const txt = 'la référence';
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '600px', disableClose: false });
    this.dialogRef.componentInstance.confirmMessage = 'Supprimer ' + txt + ' ?';
    this.dialogRef.componentInstance.styleConfirm = 'delete';

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.csvData[invoiceIndex].references.splice(elementIndex, 1);
        if (this.csvData[invoiceIndex].references.length === 0) {
          this.deleteMercuriale(invoiceIndex, true);
        }
        // Reset sum HT & TTC
        this.refreshFormData();
      }
      this.dialogRef = null;
    });
  }

  /**
   * Add reference/fee
   */
  public addLine(invoiceIndex) {
    this.csvData[invoiceIndex].references.push(new ReferenceMercurialeDetailModel());
    this.refreshFormData();
  }

  /**
   * Remove reference/fee from invoice
   */
  public deleteMercuriale(invoiceIndex, force = false) {
    if (force) {
      // Remove invoice from csv data
      this.csvData.splice(invoiceIndex, 1);
      // Check valid form
      this.checkValidForm();
      return;
    }
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '600px', disableClose: false });
    this.dialogRef.componentInstance.confirmMessage = 'Supprimer la facture ?';
    this.dialogRef.componentInstance.confirmMessageDetail = 'Ainsi que toutes les rérérences associées.';
    this.dialogRef.componentInstance.styleConfirm = 'delete';

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.csvData.splice(invoiceIndex, 1);
        // Remove invoice from csv data
        this.checkValidForm();
      }
      this.dialogRef = null;
    });
  }

  /**
   * Create a tab to open invoice
   * @param fileName File name in AWS
   */
  public openMercuriale(fileName) {
    const invoice = new InvoiceFileModel();
    invoice.originalName = fileName;
    invoice.identifierFile = fileName;
    this.invoiceService.openFile(invoice);
  }

  public checkValidForm(silence = false) {
    this.validForm = true;
    this.csvData.forEach(invoice => {
      invoice.references.forEach(referenceData => {
        if (this.checkPrice(referenceData.prix_unitaire_ht)) {
          referenceData.status = false;
          this.validForm = false;
        } else {
          referenceData.status = true;
        }
      });
    });
    if (this.validForm === true && (this.dossier || this.dossierGroup) && !silence) {
      this.snackBar.open('Parfait, l\'import peut desormais être enregistré.', '', {
        duration: 3000,
        panelClass: ['mat-bg-accent']
      });
    }
  }

  public getUnitRatio(unit: UnitModel): number {
    let unitRatio = 1;
    let parentUnit = unit.parent;
    while (parentUnit) {
      unitRatio = unitRatio * 10;
      parentUnit = parentUnit.parent;
    }
    return unitRatio;
  }

  public refreshEstablishment() {
    this.csvData.forEach(mercuriale => {
      mercuriale.dossier = this.dossier;
      this.dossierGroup = null;
      mercuriale.dossierGroup = null;
    });
  }

  public refreshEstablishmentGroup() {
    this.csvData.forEach(mercuriale => {
      mercuriale.dossierGroup = this.dossierGroup;
      this.dossier = null;
      mercuriale.dossier = null;
    });
  }

  /**
   * Persist csv datas to API
   */
  public saveData() {
    this.checkValidForm(true);
    if (this.validForm) {
      this.csvData.forEach(referenceFacture => {
        referenceFacture.references = referenceFacture.references.filter(referenceFactureDetailModel => {
          return referenceFactureDetailModel.status;
        });
      });
      console.log('TCL: -> file: import-mercuriale.component.ts -> line 527 -> this.csvData', this.csvData);
      this.importFactureService.createImportMercuriale(this.csvData).subscribe((res) => {
        this.snackBar.open('Import validé', '', {
          duration: 3000,
          panelClass: ['mat-bg-primary']
        });
        this.loaderService.changeCheck(true);
        setTimeout(() => { this.loaderService.changeCheck(false); }, 3000);
        this.csvData = [];
      });
    }
  }
}
