import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { AnalyticService } from '@app/pages/dashboard/dashboard.service';

@Component({
  selector: 'app-invoices-establishments',
  templateUrl: './invoices-establishments.component.html',
  styleUrls: ['./invoices-establishments.component.scss']
})
export class InvoicesEstablishmentsComponent implements OnInit, OnDestroy {
  @Input() events: Observable<any>;

  private eventsSubscription: Subscription;

  public purchases: any = 0;
  public invoices: any = 0;

  constructor(private analyticService: AnalyticService) { }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe(res => {
      this.getStats(res.filters);
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  /**
   * Get datas from API and formatte them in the chart
   */
  public getStats(filters: any) {
    const dateStart = moment(filters.dateStart.value.toDate()).format('YYYY-MM-DD');
    const dateEnd = moment(filters.dateEnd.value.toDate()).format('YYYY-MM-DD');
    this.analyticService.getInvoicesByEstablishment(dateStart, dateEnd).subscribe((total: any) => {
      this.invoices = total;
    });
  }

}

