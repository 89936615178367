import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';

import * as moment from 'moment';
moment.locale('fr');

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntlFr } from './mat-paginator-intl-fr';
import { LayoutModule } from '@angular/cdk/layout';
import 'hammerjs';
// Divers
import { AlertComponent } from './_components';
import { JwtInterceptor, ErrorInterceptor, Utils } from './_helpers';
import { DatePipe, registerLocaleData } from '@angular/common';
// Import login features
import { LoginComponent } from './pages/login';
import { RegisterComponent } from './pages/register';
// Import project structure & pages
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AppellationsListComponent } from './pages/appellations/appellations-list/appellations-list.component';
import { AppellationDetailComponent } from './pages/appellations/appellation-detail/appellation-detail.component';
import { ProductsListComponent } from './pages/products/products-list/products-list.component';
import { ProductDetailComponent } from './pages/products/product-detail/product-detail.component';
import { ReferencesListComponent } from './pages/references/references-list/references-list.component';
import { ReferenceDetailComponent } from './pages/references/references-detail/reference-detail.component';
import { ProvidersListComponent } from './pages/providers/providers-list/providers-list.component';
import { ScopesListComponent } from './pages/scopes/scopes-list/scopes-list.component';
import { ScopeDetailComponent } from './pages/scopes/scope-detail/scope-detail.component';
import { BrandsListComponent } from './pages/brands/brands-list/brands-list.component';
import { BrandDetailComponent } from './pages/brands/brand-detail/brand-detail.component';
import { IndustrialsListComponent } from './pages/industrials/industrials-list/industrials-list.component';
import { IndustrialDetailComponent } from './pages/industrials/industrial-detail/industrial-detail.component';
import { ProviderDetailComponent } from './pages/providers/provider-detail/provider-detail.component';
import { UnitDetailComponent } from './pages/units/unit-detail/unit-detail.component';
import { UnitsListComponent } from './pages/units/units-list/units-list.component';
import { VatcategoriesListComponent } from './pages/vatcategories/vatcategories-list/vatcategories-list.component';
import { VatcategoryDetailComponent } from './pages/vatcategories/vatcategory-detail/vatcategory-detail.component';
import { ProductsFamiliesListComponent } from './pages/products-families/products-families-list/products-families-list.component';
import { ProductFamilyDetailComponent } from './pages/products-families/product-family-detail/product-family-detail.component';
import { TaxesListComponent } from './pages/taxes/taxes-list/taxes-list.component';
import { TaxeDetailComponent } from './pages/taxes/taxe-detail/taxe-detail.component';
import { FeesListComponent } from './pages/fees/fees-list/fees-list.component';
import { FeeDetailComponent } from './pages/fees/fee-detail/fee-detail.component';
import { EstablishmentsListComponent } from './pages/establishments/establishments-list/establishments-list.component';
import { EstablishmentDetailComponent } from './pages/establishments/establishment-detail/establishment-detail.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { UserDetailComponent } from './pages/users/user-detail/user-detail.component';
import { UserEstablishmentDetailComponent } from './pages/users/user-establishment-detail/user-establishment-detail.component';
import { ProductsCategoriesListComponent } from './pages/categories/categories-list/categories-list.component';
import { ProductsCategoryDetailComponent } from './pages/categories/category-detail/category-detail.component';
import { AttributesListComponent } from './pages/attributes/attributes-list/attributes-list.component';
import { AttributeDetailComponent } from './pages/attributes/attribute-detail/attribute-detail.component';
import { AttributeValuesListComponent } from './pages/attribute-values/attribute-values-list/attribute-values-list.component';
import { AttributeValueDetailComponent } from './pages/attribute-values/attribute-value-detail/attribute-value-detail.component';
import { TypesAlcoholsListComponent } from './pages/types-alcohols/types-alcohols-list/types-alcohols-list.component';
import { TypeAlcoholDetailComponent } from './pages/types-alcohols/type-alcohol-detail/type-alcohol-detail.component';
import { StickersListComponent } from './pages/stickers/stickers-list/stickers-list.component';
import { StickerDetailComponent } from './pages/stickers/sticker-detail/sticker-detail.component';
import { ReferencesAlcoholsListComponent } from './pages/references-alcohols/references-alcohols-list/references-alcohols-list.component';
import { ReferencesAlcoholDetailComponent } from './pages/references-alcohols/references-alcohol-detail/references-alcohol-detail.component';
// Import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Import Material Design
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SlugifyPipe } from './_helpers/slugify.pipe';
import { SanitizeIdPipe } from './_helpers/sanitize-id.pipe';
import { ReferencesTaxeDetailComponent } from './pages/references-taxes/references-taxe-detail/references-taxe-detail.component';
import { ReferencesTaxesListComponent } from './pages/references-taxes/references-taxes-list/references-taxes-list.component';
import { ReferencesFeeDetailComponent } from './pages/references-fees/references-fee-detail/references-fee-detail.component';
import { ReferencesFeesListComponent } from './pages/references-fees/references-fees-list/references-fees-list.component';
import { InvoicesFilesListComponent } from './pages/invoices-files/invoices-files-list/invoices-files-list.component';
import { InvoiceFileDetailComponent } from './pages/invoices-files/invoice-file-detail/invoice-file-detail.component';
import { ImportinvoiceComponent } from './pages/import-csv/import-invoice.component';
import { ImportMercurialeComponent } from './pages/import-csv/import-mercuriale.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ConfirmationDialogComponent } from './_components/confirmation-dialog';
import { MainToolbarComponent } from './structure/main-toolbar/main-toolbar.component';
import { MainMenuComponent } from './structure/main-menu/main-menu.component';
// ChartJs
import { NgChartsModule } from 'ng2-charts';
import { CheckScopeComponent } from './_guards/check-scope.component';
import { AllReferencesComponent } from './pages/all-references/all-references.component';
import { InvoicesReviewComponent } from './charts/invoices-review/invoices-review.component';
import { FiltersComponent } from './structure/filters/filters.component';
import { FiltersToolbarComponent } from './structure/filters/filters-toolbar.component';
import { InvoicesEstablishmentsComponent } from './charts/invoices-establishments/invoices-establishments.component';
import { Top10Component } from './charts/top10/top10.component';
import { InvoicesImportedComponent } from './charts/invoices-imported/invoices-imported.component';
import { InvoicesListComponent } from './pages/invoices/invoices-list/invoices-list.component';
import { InvoiceDetailComponent } from './pages/invoices/invoice-detail/invoice-detail.component';
import { MercurialesListComponent } from './pages/mercuriales/mercuriales-list/mercuriales-list.component';
import { MercurialeDetailComponent } from './pages/mercuriales/mercuriale-detail/mercuriale-detail.component';
import * as Sentry from '@sentry/browser';
import { environment } from '@environments/environment';
import { EstablishmentGroupListComponent } from './pages/establishment-group/establishment-group-list/establishment-group-list.component';
import { EstablishmentGroupDetailComponent } from './pages/establishment-group/establishment-group-detail/establishment-group-detail.component';
import { InvoicesTreatmentComponent } from './charts/invoices-treatment/invoices-treatment.component';
import { EstablishmentTypeListComponent } from './pages/establishment-type/establishment-type-list/establishment-type-list.component';
import { EstablishmentTypeDetailComponent } from './pages/establishment-type/establishment-type-detail/establishment-type-detail.component';
import { AreaListComponent } from './pages/area/area-list/area-list.component';
import { AreaDetailComponent } from './pages/area/area-detail/area-detail.component';
import { WrongSavingsListComponent } from './pages/wrong-savings/wrong-savings-list/wrong-savings-list.component';
import { WrongSavingsComponent } from './pages/wrong-savings/wrong-savings.component';
import { ImportScanComponent } from './pages/import-scan/import-scan.component';
import { SelectParserComponent } from './pages/invoices-files/select-parser/select-parser.component';

if (environment.production === true) {
  const dsn = 'https://f5cfb9a8fc2540869c97f46e48c1e3cf@o244113.ingest.sentry.io/5702904';
  Sentry.init({
    dsn: dsn,
    release: `admin-${environment.sentryEnv}-@${environment.version}`,
    environment: environment.sentryEnv
  });

  Sentry.configureScope(function (scope) {
    if (localStorage.getItem('currentUserEstablishment')) {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      scope.setUser({ 'user': currentUser.user.email });
      scope.setTags({ 'email': currentUser.user.email, 'project': environment.name });
    }
  });
}

@NgModule({
  declarations: [
    AlertComponent,
    LoginComponent,
    SelectParserComponent,
    RegisterComponent,
    AppComponent,
    DashboardComponent,
    ProductsListComponent,
    ProductDetailComponent,
    AppellationsListComponent,
    AppellationDetailComponent,
    ProvidersListComponent,
    ScopesListComponent,
    ScopeDetailComponent,
    BrandsListComponent,
    BrandDetailComponent,
    IndustrialsListComponent,
    IndustrialDetailComponent,
    ReferencesTaxeDetailComponent,
    ReferencesTaxesListComponent,
    ReferencesFeeDetailComponent,
    ReferencesFeesListComponent,
    ReferencesListComponent,
    ReferenceDetailComponent,
    ProviderDetailComponent,
    UnitDetailComponent,
    UnitsListComponent,
    VatcategoriesListComponent,
    VatcategoryDetailComponent,
    ProductsFamiliesListComponent,
    ProductFamilyDetailComponent,
    TaxesListComponent,
    TaxeDetailComponent,
    FeesListComponent,
    FeeDetailComponent,
    EstablishmentsListComponent,
    EstablishmentDetailComponent,
    UsersListComponent,
    UserDetailComponent,
    UserEstablishmentDetailComponent,
    ProductsCategoriesListComponent,
    ProductsCategoryDetailComponent,
    AttributesListComponent,
    AttributeDetailComponent,
    AttributeValueDetailComponent,
    AttributeValuesListComponent,
    TypeAlcoholDetailComponent,
    TypesAlcoholsListComponent,
    StickersListComponent,
    StickerDetailComponent,
    ReferencesAlcoholsListComponent,
    ReferencesAlcoholDetailComponent,
    SlugifyPipe,
    SanitizeIdPipe,
    InvoicesFilesListComponent,
    InvoiceFileDetailComponent,
    ImportinvoiceComponent,
    ImportScanComponent,
    ImportMercurialeComponent,
    ConfirmationDialogComponent,
    MainToolbarComponent,
    MainMenuComponent,
    AllReferencesComponent,
    InvoicesReviewComponent,
    FiltersComponent,
    FiltersToolbarComponent,
    InvoicesEstablishmentsComponent,
    Top10Component,
    InvoicesImportedComponent,
    InvoicesListComponent,
    InvoiceDetailComponent,
    MercurialesListComponent,
    MercurialeDetailComponent,
    EstablishmentGroupListComponent,
    EstablishmentGroupDetailComponent,
    InvoicesTreatmentComponent,
    EstablishmentTypeListComponent,
    EstablishmentTypeDetailComponent,
    AreaListComponent,
    AreaDetailComponent,
    WrongSavingsListComponent,
    WrongSavingsComponent,
  ],
  imports: [
    NgChartsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    // Material Design Modules
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatExpansionModule,
    MatDividerModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    MatTabsModule,
    MatMenuModule,
    NgxFileDropModule,
    // Translate
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr },
    DatePipe,
    SlugifyPipe,
    CheckScopeComponent,
    SanitizeIdPipe,
    Utils
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
