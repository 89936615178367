import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  AppellationEstablishmentTypeAreaModel,
  AppellationModel,
  EstablishmentTypeModel,
  ResponseModalModel,
} from "../appellation.model";
import { CategoriesService } from "@app/pages/categories/categories.service";
import { CategoryModel } from "@app/pages/categories/category.model";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { TextValidator } from "@app/_validators/text.validator";
import { AppellationService } from "../appellation.service";
import { VatcategoryModel } from "@app/pages/vatcategories/vatcategory.model";
import { VatcategoriesService } from "@app/pages/vatcategories/vatcategories.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AttributesService } from "@app/pages/attributes/attributes.service";
import { AttributeModel } from "@app/pages/attributes/attribute.model";

@Component({
  selector: "app-appellation-detail",
  templateUrl: "./appellation-detail.component.html",
})
export class AppellationDetailComponent implements OnInit {
  public refForm: FormGroup;
  public areasForm: FormGroup;
  public vatForm: FormGroup;
  public responseElt: ResponseModalModel = new ResponseModalModel();
  public categoriesList: CategoryModel[];
  public filteredCategoriesList: Observable<CategoryModel[]>;
  public vatCategoriesList: VatcategoryModel[];
  public attributesList: AttributeModel[];
  public filteredVatCategoriesList: Observable<VatcategoryModel[]>;
  public filteredAttributesList: Observable<AttributeModel[]>;
  public appellationName: string;
  public appellationEstablishmentTypeArea: AppellationEstablishmentTypeAreaModel[];
  public establishmentTypes: EstablishmentTypeModel[] =
    this.data.establishmentTypes;

  constructor(
    public dialogRef: MatDialogRef<AppellationDetailComponent>,
    private appellationService: AppellationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private categoriesService: CategoriesService,
    private vatCategoriesService: VatcategoriesService,
    private attributesService: AttributesService
  ) {
    this.responseElt.response = "";
    this.responseElt.element = new AppellationModel();
  }

  private _filter(value: any): CategoryModel[] {
    const filterValue = value.toLowerCase();
    return this.categoriesList.filter(
      (cat) =>
        cat.name.toLowerCase().indexOf(filterValue) === 0 ||
        cat.fullCategoryName.toLowerCase().indexOf(filterValue) === 0
    );
  }

  public displayFn(category?: CategoryModel): string | undefined {
    return category ? category.name : undefined;
  }

  ngOnInit() {
    this.refForm = new FormGroup({
      name: new FormControl(this.data.element.name, [
        Validators.required,
        Validators.maxLength(50),
        TextValidator.noWhiteSpace,
      ]),
      certified: new FormControl(this.data.element.certified),
      category: new FormControl(
        this.data.element.category ? this.data.element.category : null,
        Validators.required
      ),
      vatCategory: new FormControl(
        this.data.element.vatCategory ? this.data.element.vatCategory : null,
        Validators.required
      ),
      attributes: new FormControl(
        this.data.element.attributes
          ? this.data.element.attributes.map((res) => res.id)
          : ""
      ),
    });
    if (this.data.context === "update") {
      this.refForm.addControl("id", new FormControl(this.data.element.id, []));
    }

    // Set establishmentTypeAreas form
    this.areasForm = new FormGroup({});
    this.establishmentTypes.forEach((establishmentType) => {
      const valueType = this.data.element.establishmentTypeAreasArray
        ? this.data.element.establishmentTypeAreasArray[establishmentType.id]
        : null;
      this.areasForm.addControl(
        establishmentType.id.toString(),
        new FormControl(valueType, Validators.required)
      );
    });
    this.refForm.addControl("establishmentTypeAreas", this.areasForm);

    this.categoriesService
      .getCategories(true)
      .subscribe((categories: CategoryModel[]) => {
        this.categoriesList = categories;
        this.categoriesList.sort((a, b) =>
          a.fullCategoryName.localeCompare(b.fullCategoryName)
        );
        this.filteredCategoriesList = this.refForm
          .get("category")
          .valueChanges.pipe(
            startWith(""),
            map((value) => (typeof value === "string" ? value : value.name)),
            map((name) =>
              name ? this._filter(name) : this.categoriesList.slice()
            )
          );
      });

    this.vatCategoriesService
      .getVatcategories()
      .subscribe((vatcategories: VatcategoryModel[]) => {
        this.vatCategoriesList = vatcategories;
        this.vatCategoriesList.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredVatCategoriesList = this.refForm
          .get("vatCategory")
          .valueChanges.pipe(
            startWith(""),
            map((value) => (typeof value === "string" ? value : value.name)),
            map((name) =>
              name
                ? this._filterVatCategories(name)
                : this.vatCategoriesList.slice()
            )
          );
      });

    this.attributesService
      .getAttributes(true)
      .subscribe((elements: AttributeModel[]) => {
        this.attributesList = elements.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        this.filteredAttributesList = this.refForm
          .get("attributes")
          .valueChanges.pipe(
            startWith(""),
            map((value) => (typeof value === "string" ? value : value.name)),
            map((name) =>
              name ? this._filterAttributes(name) : this.attributesList.slice()
            )
          );
        // Remove parent's attributes from the list of choice (they are automaticly linked with the child)
        if (this.data.context === "update") {
          const attributsParent = this.data.element.attributesList.filter(
            this.comparer(this.data.element.attributes)
          );
          attributsParent.forEach((element) => {
            const index = this.attributesList.findIndex(
              (array) => array.id === element.id
            );
            this.attributesList[index].duplicate = true;
          });
        }
      });
  }

  private _filterVatCategories(value: any): VatcategoryModel[] {
    const filterValue = value.toLowerCase();
    return this.vatCategoriesList.filter(
      (cat) => cat.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filterAttributes(value: any): AttributeModel[] {
    const filterValue = value.toLowerCase();
    return this.attributesList.filter(
      (cat) =>
        cat.name.toLowerCase().indexOf(filterValue) === 0 ||
        cat.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  public comparer(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other.id === current.id;
        }).length === 0
      );
    };
  }

  public displayFnVatCategory(category?: VatcategoryModel): string | undefined {
    return category ? category.name : undefined;
  }

  public getSpace(element, space: number = 0) {
    if (element.parent) {
      space++;
      return this.getSpace(element.parent, space);
    }
    return space;
  }

  public hasError(controlName: string, errorName: string) {
    return this.refForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: "elementCanceled" });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.refForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }
}
