import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnalyticService {
    public establishment: number;

    constructor(private http: HttpClient) { }

    public getParam(dateStart, dateEnd, appellation = null, brand = null, packaging = null, attributes = null) {
        const res: any = {};
        if (dateStart) {
            res.dateStart = dateStart + ' 00:00:00';
        }
        if (dateEnd) {
            res.dateEnd = dateEnd + ' 23:59:59';
        }

        return res;
    }

    public getInvoicesReview(dateStart: String, dateEnd: String, type: any): Observable<any[]> {
        let endpoint = '/admin/invoices-review/createdAt';
        switch (type) {
            case 'reviewByDateBill':
                endpoint = '/admin/invoices-review/date';
                break;
            case 'reviewByEstablishment':
                endpoint = '/admin/invoices-review/establishment';
                break;
            default:
                break;
        }
        const body = this.getParam(dateStart, dateEnd);
        return this.http.post<any[]>(`${environment.apiUrls[environment.name]}${endpoint}`, body);
    }

    public getInvoicesTreatment(dateStart: String, dateEnd: String): Observable<any[]> {
        const body = this.getParam(dateStart, dateEnd);
        return this.http.post<any[]>(`${environment.apiUrls[environment.name]}/admin/invoices-treatment`, body);
    }

    public getInvoicesByEstablishment(dateStart: String, dateEnd: String): Observable<any[]> {
        const body = this.getParam(dateStart, dateEnd);
        return this.http.post<any[]>(`${environment.apiUrls[environment.name]}/admin/invoices-by-establishments`, body);
    }

    public getTop5(dateStart: String, dateEnd: String): Observable<any[]> {
        const body = this.getParam(dateStart, dateEnd);
        return this.http.post<any[]>(`${environment.apiUrls[environment.name]}/admin/top5`, body);
    }

    public getInvoicesImported(): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrls[environment.name]}/admin/invoices-imported`, null);
    }

    public getProvidersCount(): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrls[environment.name]}/admin/providers-count`, null);
    }

    public getWrongSavings(dateStart: String, dateEnd: String, pageIndex: number, pageSize: number): Observable<any[]> {
        const body = this.getParam(dateStart, dateEnd);
        if (pageIndex !== null) {
            pageIndex++;
            body.pageIndex = pageIndex;
        }
        if (pageSize !== null) {
            body.pageSize = pageSize;
        }
        return this.http.post<any[]>(`${environment.apiUrls[environment.name]}/admin/wrong_savings`, body);
    }
}
