import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { AuthenticationService } from '@app/_services';
import { FiltersComponent } from './filters.component';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from '@app/_helpers';


@Component({
    selector: 'app-filters-toolbar',
    templateUrl: './filters-toolbar.component.html',
    styleUrls: ['./filters-toolbar.component.scss']
})
export class FiltersToolbarComponent implements OnInit, OnDestroy {
    @Output() defineFilters = new EventEmitter<number>();

    // Dates filter
    public dateStart = new FormControl({ value: moment().subtract(12, 'months').startOf('month'), disabled: true });
    public dateEnd = new FormControl({ value: moment().endOf('month'), disabled: true });

    constructor(
        public dialog: MatDialog,
        public utils: Utils
    ) { }

    ngOnInit() {
        let filters;
        // Set dates interval
        filters = {
            dateStart: this.dateStart,
            dateEnd: this.dateEnd
        };
        // Initialization of chart begin from the child component filter (on init)
        setTimeout(() => { this.setFilters(filters); }); // TODO: Change order of observable
    }

    ngOnDestroy() { }

    /**
     * Define the filters and redefine the chart
     * @param filters object of dateStart, dateEnd and list of establishments
     */
    public setFilters(filters) {
        this.dateStart = filters.dateStart;
        this.dateEnd = filters.dateEnd;
        this.defineFilters.emit(filters);
    }

    /**
     * Return an object of current filters
     */
    public getFilters() {
        return {
            dateStart: this.dateStart,
            dateEnd: this.dateEnd
        };
    }

    /**
     * display modal for the selection of filters
     */
    public openFiltersModal() {
        const dialogRef = this.dialog.open(FiltersComponent, {
            width: '950px',
            data: {
                filters: this.getFilters()
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                this.setFilters(result.filters);
            }
        });
    }
}
