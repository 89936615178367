import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InvoicesFilesService } from '../invoices-files.service';
import { ParserModel } from './parser.model';

@Component({
  selector: 'app-select-parser',
  templateUrl: './select-parser.component.html',
})
export class SelectParserComponent implements OnInit {
  parsers: ParserModel[];

  constructor(
    private dialogRef: MatDialogRef<SelectParserComponent>,
    private invoicesFilesService: InvoicesFilesService
  ) { }

  ngOnInit(): void {
    this.invoicesFilesService.getParsers().subscribe((parsers: ParserModel[]) => {
      this.parsers = parsers.filter((parser: ParserModel) => parser.type === 'ocr');
    });
  }

  selectParser(parser: ParserModel): void {
    this.dialogRef.close(parser);
  }
}
