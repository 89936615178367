import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { AnalyticService } from '@app/pages/dashboard/dashboard.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invoices-imported',
  templateUrl: './invoices-imported.component.html',
  styleUrls: ['./invoices-imported.component.scss']
})
export class InvoicesImportedComponent implements OnInit, OnDestroy {
  @Input() events: Observable<any>;

  private eventsSubscription: Subscription;

  public purchases: any = 0;
  public totalInvoicesDay: any = 0;
  public totalInvoicesMonth: any = 0;
  public totalInvoicesAll: any = 0;
  public totalInvoicesWrongDate: any = null;
  public invoicesWrongDateIds: any = [];
  public totalInvoicesHTDifference: any = null;
  public invoicesHTDifferenceIds: any = [];
  public selectedTabIndex = 0;
  public spinner = true;

  constructor(private analyticService: AnalyticService, private router: Router) { }

  ngOnInit() {
    this.getStats();
  }

  ngOnDestroy() {}

  /**
   * Get datas from API and formatte them in the chart
   */
  public getStats() {
    this.analyticService.getInvoicesImported().subscribe((invoices: any) => {
      this.totalInvoicesDay = invoices.totalInvoicesDay.total;
      this.totalInvoicesMonth = invoices.totalInvoicesMonth.total;
      this.totalInvoicesAll = invoices.totalInvoicesAll.total;
      this.totalInvoicesWrongDate = invoices.totalInvoicesWrongDate.total;
      this.totalInvoicesHTDifference = invoices.totalInvoicesHTDifference.total;
      this.invoicesWrongDateIds = invoices.totalInvoicesWrongDate.ids;
      this.invoicesHTDifferenceIds = invoices.totalInvoicesHTDifference.ids;
      this.spinner = false;
    });
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTabIndex = tabChangeEvent.index;
  }

  public getInvoicesByContext(context: string) {
    if (context === 'dates') {
      this.router.navigate(['/invoices'], {
        state: {
          data: this.invoicesWrongDateIds ? this.invoicesWrongDateIds : '',
          context: context
        },
      });
    } else if (context === "difference") {
      this.router.navigate(['/invoices'], {
        state: {
          data: this.invoicesHTDifferenceIds ? this.invoicesHTDifferenceIds : '',
          context: context
        },
      });
    }
  }

}
