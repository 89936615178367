export class IndustrialModel {
    id: number;
    name: string;

    constructor() {
        this.name = '';
    }
}

export class ResponseModalModel {
    response: string;
    element: IndustrialModel;

    constructor() {
        this.element = new IndustrialModel();
        this.response = '';
    }
}
