export class AreaModel {
    id: number;
    name: string;

    constructor() {
        this.name = '';
    }
}

export class ResponseModalModel {
    response: string;
    element: AreaModel;

    constructor() {
        this.element = new AreaModel();
        this.response = '';
    }
}
