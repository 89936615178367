import { Component, OnInit, ViewChild } from '@angular/core';
import { FileSystemFileEntry, FileSystemDirectoryEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ReferencesService } from '../references/references.service';
import { ReferenceModel } from '../references/reference.model';
import { ReferenceInvoiceModel, ReferenceInvoiceDetailModel } from './reference-invoice.model';
import { ProviderModel } from '../providers/provider.model';
import { ProvidersService } from '../providers/providers.service';
import { InvoicesFilesService } from '../invoices-files/invoices-files.service';
import { EstablishmentService } from '../establishments/establishment.service';
import { EstablishmentModel } from '../establishments/establishment.model';
import { ConfirmationDialogComponent } from '@app/_components/confirmation-dialog';
import { ImportCsvService } from './import-csv.service';
import { parse, parseISO } from 'date-fns';
import { isAfter, isEqual } from 'date-fns/esm';
import { ReferencesTaxesService } from '../references-taxes/references-taxes.service';
import { ReferencesTaxeModel } from '../references-taxes/references-taxe.model';
import { ReferencesFeesService } from '../references-fees/references-fees.service';
import { ReferencesFeeModel } from '../references-fees/references-fee.model';
import { FeeValueModel, FeeModel } from '../fees/fee.model';
import { FeesService } from '../fees/fees.service';
import { TaxeModel } from '../taxes/taxe.model';
import { ReferencesAlcoholsService } from '../references-alcohols/references-alcohols.service';
import { ReferencesAlcoholModel } from '../references-alcohols/references-alcohol.model';
import { StickersService } from '../stickers/stickers.service';
import { StickerModel } from '../stickers/sticker.model';
import { forkJoin } from 'rxjs';
import { LoaderService } from '@app/_services/loader.service';
import { Utils } from '@app/_helpers';
import * as moment from 'moment';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-import-invoice',
  templateUrl: './import-invoice.component.html',
  styleUrls: ['./import-invoice.component.scss']
})
export class ImportinvoiceComponent implements OnInit {
  public csvData: ReferenceInvoiceModel[] = [];
  public files: NgxFileDropEntry[] = [];
  public allReferences: ReferenceModel[];
  public listfees: FeeModel[];
  public listStickers: StickerModel[];
  public elementsMatTable;
  public displayedColumns: string[] = ['reference'];
  public providers: ProviderModel[];
  public establishments: EstablishmentModel[];
  public refTaxes: ReferencesTaxeModel[];
  public reffees: ReferencesFeeModel[];
  public refAlcohol: ReferencesAlcoholModel[];
  public dossier: string;
  public lien_fichier: string;
  public fournisseur: number;
  public date_livraison: Date;
  public validForm: boolean;
  public dateNow: string;
  public dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  public loading: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public snackBar: MatSnackBar,
    public referenceServices: ReferencesService,
    public providerService: ProvidersService,
    public referenceTaxeService: ReferencesTaxesService,
    public referencefeeService: ReferencesFeesService,
    public referenceAlcoholService: ReferencesAlcoholsService,
    public stickersService: StickersService,
    public feeService: FeesService,
    public establishmentService: EstablishmentService,
    public invoiceService: InvoicesFilesService,
    public importFactureService: ImportCsvService,
    public dialog: MatDialog,
    private loaderService: LoaderService,
    private utils: Utils) { }

  ngOnInit() {
    this.loaderService.changeLoader(true);
    forkJoin(
      this.providerService.getProviders(),
      this.establishmentService.getEstablishments(),
      this.referenceTaxeService.getReferencesTaxes(),
      this.referencefeeService.getReferencesfees(),
      this.referenceAlcoholService.getReferencesAlcohols(),
      this.feeService.getfees(),
      this.stickersService.getStickers()
    ).subscribe(
      results => {
        this.providers = results[0];
        this.establishments = results[1];
        this.refTaxes = results[2]['hydra:member'];
        this.reffees = results[3]['hydra:member'];
        this.refAlcohol = results[4];
        this.listfees = results[5];
        this.listStickers = results[6];
      },
      (err) => {
      },
      () => {
        this.loaderService.changeLoader(false);
      },
    );
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Date FNS methods
   * At import, the date switch to string and display some warning on chrome
   * This case define the fields to error
   */
  public isAfter(dateA: Date, dateB: Date) {
    if (typeof dateA === 'string' && typeof dateB === 'string') {
      return isAfter(parseISO(dateA), parseISO(dateB));
    } else {
      return isAfter(dateA, dateB);
    }
  }
  public isAfterNow(dateA: Date) {
    if (typeof dateA === 'string') {
      return isAfter(parseISO(dateA), new Date());
    } else {
      return isAfter(dateA, new Date());
    }
  }
  public isAfter2018(dateA: Date) {
    if (typeof dateA === 'string') {
      return isAfter(parseISO(dateA), new Date('2018-12-31'));
    } else {
      return isAfter(dateA, new Date('2018-12-31'));
    }
  }
  public isEqual(dateA, dateB) {
    if (typeof dateA === 'string' && typeof dateB === 'string') {
      return isEqual(parseISO(dateA), parseISO(dateB));
    } else {
      return isEqual(dateA, dateB);
    }
  }

  /**
   * File upload
   * @param event Event
   */
  public dropped(event: NgxFileDropEntry[]) {
    this.files = event;
    if (this.files.length > 1) {
      this.snackBar.open('Merci de charger un seul document à la fois', '', { duration: 3000, panelClass: ['mat-bg-warn'] });
      return;
    }
    for (const { index, droppedFile } of this.files.map((droppedFile, index) => ({ index, droppedFile }))) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (!fileEntry.isFile || !this.isFileAllowed(file.name)) {
            this.snackBar.open('Format incorrect: le fichier doit être un .csv', '', { duration: 3000, panelClass: ['mat-bg-warn'] });
            return;
          } else {
            // Set this.csvData with the CSV lines
            this.extractAndPersistCsv(file);
            // Active loader during the initialisation of front
            this.loaderService.changeLoader(true);
          }
        });
      }
    }
  }

  public isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.csv'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  /**
   * Check the number and name of columns from the first line of CSV
   */
  public checkCSV(csvLine): boolean {
    // Convert first line of CSV in array
    csvLine = csvLine.split(';').filter(n => n.trim().replace(/(\r\n|\n|\r)/gm, ''));
    if (csvLine.length === 12 && csvLine[9] === 'tva') {
      csvLine.splice(9, 1);
    }

    // tslint:disable-next-line: max-line-length
    const headerLine = ['dossier', 'fournisseur', 'lien_fichier', 'facture', 'date_facture', 'date_livraison', 'reference_fournisseur', 'quantite', 'prix_unitaire_ht', 'total_facture_ht', 'total_facture_ttc'];
    const difference = headerLine.filter(x => !csvLine.includes(x));
    return (difference.length === 0 && csvLine.length === 11);
  }

  /**
   * Check the number and name of columns from the first line of CSV
   */
  public checkDuplicate(linesCsv): boolean {
    const identifiers = [];
    let providerId;
    linesCsv.forEach((element) => {
      const lineCSV: string[] = element.split(';');
      if (lineCSV[2] !== '') {
        providerId = lineCSV[2];
      }
      if (lineCSV[3] && lineCSV[4]) {
        identifiers.push(providerId + lineCSV[3] + lineCSV[4]);
      }
    });
    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
    return findDuplicates(identifiers).length > 0;
  }

  private findEstablishmentFromOldOrNewId(id: string): EstablishmentModel {
    if (!isNaN(parseFloat(id))) {
      // find establishment in establishments list from old id match
      const establishment = this.establishments.find(establishment => String(establishment.id) === id);
      if (!establishment) {
        this.snackBar.open('L\'établissement n\'existe pas', '', { duration: 3000, panelClass: ['mat-bg-warn'] });
        return;
      }

      return establishment;
    }

    // find establishment in establishments list from new base58 id match
    const establishment = this.establishments.find(establishment => establishment.shortId === id);

    if (!establishment) {
      this.snackBar.open('L\'établissement n\'existe pas', '', { duration: 3000, panelClass: ['mat-bg-warn'] });
      return;
    }

    return establishment;
  }

  public extractAndPersistCsv(file: File): void {
    this.csvData = [];
    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const csv = reader.result as string;
      let linesCsv = csv.split(/\r?\n?\r\n/);
      // Remove empty values
      linesCsv = linesCsv.filter(line => line.length > 1);
      try {
        // Remove first line of header
        const firstLine = linesCsv.splice(0, 1);
        // Check number and names of columns
        if (!this.checkCSV(firstLine[0])) {
          throw new Error('Colonnes non conformes, les en-têtes présentent une erreur.');
        }
        // Check duplicate indentifiers
        if (this.checkDuplicate(linesCsv)) {
          throw new Error('Doublon de facture detecté.');
        }
        let refIndex = 0;
        let factureTmp = new ReferenceInvoiceModel;
        // Get references for the query
        let listReferences = [];
        linesCsv.forEach((element) => {
          const lineCSV: string[] = element.split(';');
          if (lineCSV.length === 12) {
            lineCSV.splice(9, 1);
          }
          // Correct length of reference with '0'
          let referenceWithZero = lineCSV['6'];
          // Get the provider if exist in database
          if (lineCSV[2]) { this.fournisseur = +lineCSV[2]; }
          // Get the provider if exist in database
          let lineProvider;
          let matchProvider;
          if (matchProvider = this.providers.find(provider => +this.fournisseur === provider.id)) {
            lineProvider = matchProvider;
          } else {
            lineProvider = new ProviderModel();
            lineProvider.id = +this.fournisseur;
          }
          if (lineProvider && lineCSV[6].length < lineProvider.referenceLength) {
            const num0 = lineProvider.referenceLength - lineCSV[6].length;
            referenceWithZero = '0'.repeat(num0) + referenceWithZero;
          }
          listReferences.push(referenceWithZero);
        });
        this.loaderService.changeLoader(true);
        listReferences = listReferences.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        this.referenceServices.getListReferences(listReferences).subscribe(list => {
          this.loaderService.changeLoader(false);
          this.allReferences = list;
          // Csv lines processing
          linesCsv.forEach((element, index, array) => {
            const lineCSV: string[] = element.split(';');
            if (lineCSV.length === 12) {
              lineCSV.splice(9, 1);
            }
            let lineReference, matchReference: ReferenceModel,
              lineProvider,
              matchReferenceAlcohol: ReferencesAlcoholModel,
              matchProvider: ProviderModel,
              matchTaxeToEject: ReferencesTaxeModel,
              matchfeeToEject: ReferencesFeeModel;
            // If link of invoice detected on 3th column, we persist a new invoice's informations in this.csvData
            if (lineCSV[3]) {
              refIndex = 0;
              // Persist data into this.csvData on new invoice detection
              if (factureTmp.references.length > 0) {
                this.csvData.push(factureTmp);
              }
              factureTmp = new ReferenceInvoiceModel;
              // Persist establishment id
              if (lineCSV[0]) {
                const establishment = this.findEstablishmentFromOldOrNewId(lineCSV[0]);
                this.dossier = establishment.shortId;
              }
              // Persist link file
              if (lineCSV[1]) { this.lien_fichier = this.utils.removeExtension(lineCSV[1]); }
              // Persist provider
              if (lineCSV[2]) { this.fournisseur = +lineCSV[2]; }
              // Get the provider if exist in database
              if (matchProvider = this.providers.find(provider => +this.fournisseur === provider.id)) {
                lineProvider = matchProvider;
              } else {
                lineProvider = new ProviderModel();
                lineProvider.id = +this.fournisseur;
              }
              factureTmp.dossier = this.dossier;
              factureTmp.lien_fichier = this.utils.removeExtension(this.lien_fichier);
              factureTmp.fournisseur = lineProvider;
              factureTmp.facture = lineCSV[3];
              factureTmp.fees = [];
              // TODO: Refacto to set the date in UTC+0 properly
              const date = moment(lineCSV[4], 'DD/MM/YYYY');
              factureTmp.date_facture = new Date(Date.UTC(+date.format('YYYY'), +date.format('M') - 1, +date.format('D'), 0, 0, 0));
              factureTmp.total_facture_ht = Number.parseFloat(lineCSV[9].replace(',', '.'));
              factureTmp.total_facture_ttc = Number.parseFloat(lineCSV[10].replace(',', '.'));
              factureTmp.total_coopeo_ht = Number.parseFloat(lineCSV[9].replace(',', '.'));
              factureTmp.total_coopeo_ttc = Number.parseFloat(lineCSV[10].replace(',', '.'));
            }
            // Correct length of reference with '0'
            let referenceWithZero = lineCSV['6'];
            if (factureTmp.fournisseur && lineCSV[6].length < factureTmp.fournisseur.referenceLength) {
              const num0 = factureTmp.fournisseur.referenceLength - lineCSV[6].length;
              referenceWithZero = '0'.repeat(num0) + referenceWithZero;
            }
            // Check & assign reference's object where reference && provider match with the csv information
            lineReference = new ReferenceModel();
            let stickerTax = 0;
            let stickerYear;
            if (matchReference = this.allReferences.find((ref: ReferenceModel) => (referenceWithZero === ref.code && factureTmp.fournisseur.id === ref.provider.id))) {
              Object.assign(lineReference, matchReference);
              if (matchReferenceAlcohol = this.refAlcohol.find((ref: ReferencesAlcoholModel) => (referenceWithZero === ref.code && factureTmp.fournisseur.id === ref.provider.id))) {
                const year = (this.date_livraison) ? this.date_livraison.getFullYear() : parse(lineCSV[5], 'dd/MM/yyyy', new Date()).getFullYear();
                stickerYear = this.listStickers.find((sticker: StickerModel) => (sticker.year === +year && sticker.type.id === matchReferenceAlcohol.type.id));
                const unitRatio: number = matchReference.unit.parentUnitRatio;
                if (matchReferenceAlcohol.type.pure && stickerYear) {
                  stickerTax = stickerYear.amount * matchReferenceAlcohol.degree / 100 * (matchReference.unitValue / unitRatio);
                } else if (stickerYear) {
                  stickerTax = stickerYear.amount * matchReferenceAlcohol.degree * (matchReference.unitValue / unitRatio);
                }
              }
            } else {
              lineReference.code = lineCSV[6];
            }
            /**
             * Get the sums of taxes
             * Init taxeSumHT & taxeSumTTC with a potential sticker's taxe
             */
            let taxeSumHT = (stickerTax) ? stickerTax : 0;
            let taxeSumTTC = (stickerTax) ? stickerTax * (1 + lineReference.product.vat / 100) : 0;
            if (lineReference.taxes && lineReference.taxes.length > 0) {
              (lineReference.taxes as TaxeModel[]).forEach(taxe => {
                taxeSumHT += taxe.amount;
                taxeSumTTC += (taxe.withVat) ? taxe.amount * (1 + lineReference.product.vat / 100) : taxe.amount;
              });
            }
            // Create & assign reference's object informations
            factureTmp.references[refIndex] = new ReferenceInvoiceDetailModel();
            factureTmp.references[refIndex].quantite = (lineCSV[7]) ? parseFloat(lineCSV[7].replace(',', '.')) : 1;
            factureTmp.references[refIndex].prix_unitaire_ht = (lineCSV[8]) ? parseFloat(lineCSV[8].replace(',', '.')) : 0;
            factureTmp.references[refIndex].tva = (lineReference) ? lineReference.product.vat : 0;
            factureTmp.references[refIndex].reference = lineReference;
            factureTmp.references[refIndex].taxesHT = taxeSumHT;
            factureTmp.references[refIndex].taxesTTC = taxeSumTTC;
            factureTmp.references[refIndex].discount = 0;
            factureTmp.references[refIndex].status = true;
            // Credit detected
            if (factureTmp.references[refIndex].prix_unitaire_ht < 0 || factureTmp.references[refIndex].quantite < 0) {
              factureTmp.references[refIndex].credit = 1;
            }
            if (factureTmp.references[refIndex].prix_unitaire_ht < 0) {
              factureTmp.references[refIndex].quantite = -factureTmp.references[refIndex].quantite;
              factureTmp.references[refIndex].prix_unitaire_ht = Math.abs(factureTmp.references[refIndex].prix_unitaire_ht);
            }

            // Convert date format
            if (lineCSV[5]) {
              // TODO: Refacto to set the date in UTC+0 properly
              const date_livraison = moment(lineCSV[5], 'DD/MM/YYYY').toDate();
              // const date_livraison_tz = new Date(Date.UTC(+date_livraison.format('YYYY'), +date_livraison.format('M') - 1, +date_livraison.format('D'), 0, 0, 0));

              factureTmp.references[refIndex].date_livraison = /* date_livraison_tz */ date_livraison;
              this.date_livraison = /* date_livraison_tz */ date_livraison;
            } else if (this.date_livraison) {
              factureTmp.references[refIndex].date_livraison = this.date_livraison;
            } else {
              throw new Error('Erreur de saisie des dates CSV');
            }

            // Check if "reference taxe" is associated to lineReference and set status to false if it is.
            if (matchTaxeToEject = this.refTaxes.find((refTaxe: ReferencesTaxeModel) => (lineReference.code === refTaxe.code && factureTmp.fournisseur.id === refTaxe.provider.id))) {
              factureTmp.references[refIndex].status = false;
              factureTmp.references[refIndex].reference = new ReferencesTaxeModel;
              Object.assign(factureTmp.references[refIndex].reference, matchTaxeToEject);
            }
            // Check if "reference fee" is associated to lineReference and set status to false if it is.
            if (matchfeeToEject = this.reffees.find((reffee: ReferencesFeeModel) => (lineReference.code === reffee.code && factureTmp.fournisseur.id === reffee.provider.id))) {
              // Create an object FeeValueModel into ReferenceInvoiceModel, fees attribute.
              const feeValue = new FeeValueModel;
              feeValue.fee = new FeeModel();
              feeValue.fee.id = matchfeeToEject.fee.id;
              feeValue.value = factureTmp.references[refIndex].prix_unitaire_ht;
              feeValue.vat = matchfeeToEject.fee.vat;
              factureTmp.fees.push(feeValue);
              // Remove the reference if fee detected and don't increment 'refIndex' to erase completely this element and avoid an empty value in array
              factureTmp.references.splice(refIndex, 1);
            } else {
              refIndex++;
            }
            // Persist data into csvData on last reference detected
            if (index === (array.length - 1)) {
              this.csvData.push(factureTmp);
            }
          });
          this.isRightEstablishment();
          this.refreshFormData();
        },
          err => { console.log('TCL: ImportinvoiceComponent -> reader.onload -> err', err); },
          () => this.loaderService.changeLoader(false)
        );
      } catch (errMessage) {
        console.error(errMessage);
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '600px', disableClose: false });
        this.dialogRef.componentInstance.confirmMessage = 'Erreur sur l\'import.';
        this.dialogRef.componentInstance.confirmMessageDetail = errMessage.message;
        this.dialogRef.componentInstance.styleConfirm = 'confirm';
        this.loaderService.changeLoader(false);
      }
    };
    this.loaderService.changeLoader(false);
  }

  public getTypeReference(obj: ReferenceModel | ReferencesTaxeModel | ReferencesFeeModel) {
    if (obj instanceof ReferencesTaxeModel) {
      return 1;
    } else if (obj instanceof ReferencesFeeModel) {
      return 2;
    } else {
      return 0;
    }
  }

  public refreshFormData() {
    this.csvData.forEach((group: any) => {
      // Calculate total
      group.total_coopeo_ht = this.getTotalHT(group);
      group.total_coopeo_ttc = this.getTotalTTC(group);
      // Reorder by date ASC to group them in front
      group.references.sort((a, b) => isAfter(a.date_livraison, b.date_livraison) ? 1 : (isAfter(b.date_livraison, a.date_livraison) ? -1 : 0));
    });
    // Check Form
    this.checkValidForm();
  }

  public refreshAllReferenceData(invoiceData: ReferenceInvoiceModel, invoiceIndex) {
    invoiceData.references.forEach((ref: ReferenceInvoiceDetailModel, refIndex) => {
      this.refreshReferenceData(invoiceIndex, refIndex, (ref.reference as ReferenceModel).code);
    });
  }

  public getReferenceBeforeRefreshReferenceData(invoiceIndex, refIndex, refValue) {
    // Correct length of reference with '0'
    let referenceWithZero = refValue;
    if (this.csvData[invoiceIndex].fournisseur && refValue.length < this.csvData[invoiceIndex].fournisseur.referenceLength) {
      const num0 = this.csvData[invoiceIndex].fournisseur.referenceLength - refValue.length;
      referenceWithZero = '0'.repeat(num0) + referenceWithZero;
    }
    if (!this.allReferences.find(ref => ((referenceWithZero === ref.code) && (this.csvData[invoiceIndex].fournisseur.id === ref.provider.id)))) {
      this.referenceServices.getReferencesByCode(referenceWithZero, this.csvData[invoiceIndex].fournisseur.id).subscribe(newReference => {
        if (newReference[0] && !this.allReferences.find(element => (element.code === referenceWithZero && element.provider.id === this.csvData[invoiceIndex].fournisseur.id))) {
          this.allReferences.push(newReference[0]);
        }
        this.refreshReferenceData(invoiceIndex, refIndex, referenceWithZero);
      });
    } else {
      this.refreshReferenceData(invoiceIndex, refIndex, referenceWithZero);
    }
  }

  public refreshReferenceData(invoiceIndex, refIndex, refValue) {
    // Check if reference exist in database
    let lineReference: ReferencesTaxeModel | ReferencesFeeModel | ReferenceModel;
    let match: ReferenceModel;
    let matchTaxeToEject: ReferencesTaxeModel;
    let matchfeeToEject: ReferencesFeeModel;
    let matchReferenceAlcohol: ReferencesAlcoholModel;
    // Find reference from referentiel where reference && provider match with the csv
    lineReference = new ReferenceModel();
    // Correct length of reference with '0'
    let referenceWithZero = refValue;
    if (this.csvData[invoiceIndex].fournisseur && refValue.length < this.csvData[invoiceIndex].fournisseur.referenceLength) {
      const num0 = this.csvData[invoiceIndex].fournisseur.referenceLength - refValue.length;
      referenceWithZero = '0'.repeat(num0) + referenceWithZero;
    }
    // Check if already exist
    if (match = this.allReferences.find(ref => ((referenceWithZero === ref.code) && (this.csvData[invoiceIndex].fournisseur.id === ref.provider.id)))) {
      Object.assign(lineReference, match);
    } else {
      lineReference.code = refValue;
    }
    // Check if it's a reference alcohol
    let stickerTax = 0;
    let stickerYear;
    if (matchReferenceAlcohol = this.refAlcohol.find((ref: ReferencesAlcoholModel) => (refValue === ref.code && this.csvData[invoiceIndex].fournisseur.id === ref.provider.id))) {
      const year = (this.date_livraison) ? this.date_livraison.getFullYear() : (new Date()).getFullYear();
      stickerYear = this.listStickers.find((sticker: StickerModel) => (sticker.year === +year && sticker.type.id === matchReferenceAlcohol.type.id));
      const unitRatio: number = match.unit.parentUnitRatio;
      if (matchReferenceAlcohol.type.pure && stickerYear) {
        stickerTax = stickerYear.amount * matchReferenceAlcohol.degree / 100 * (match.unitValue / unitRatio);
      } else if (stickerYear) {
        stickerTax = stickerYear.amount * matchReferenceAlcohol.degree * (match.unitValue / unitRatio);
      }
    }
    // Get the sum of taxes
    let taxeSumHT = (stickerTax) ? stickerTax : 0;
    let taxeSumTTC = (stickerTax) ? stickerTax * (1 + lineReference.product.vat / 100) : 0;
    if ((lineReference as ReferenceModel).taxes && (lineReference as ReferenceModel).taxes.length > 0) {
      ((lineReference as ReferenceModel).taxes as TaxeModel[]).forEach((taxe: TaxeModel) => {
        taxeSumHT += taxe.amount;
        taxeSumTTC += (taxe.withVat) ? taxe.amount * (1 + (lineReference as ReferenceModel).product.vat / 100) : taxe.amount;
      });
    }
    // Check if "reference taxe" is associated to lineReference and set status to false if it is
    if (matchTaxeToEject = this.refTaxes.find(refTaxe => ((lineReference as ReferenceModel).code === refTaxe.code && this.csvData[invoiceIndex].fournisseur.id === refTaxe.provider.id))) {
      this.csvData[invoiceIndex].references[refIndex].status = false;
      this.csvData[invoiceIndex].references[refIndex].reference = matchTaxeToEject;
      lineReference = new ReferencesTaxeModel;
      lineReference = Object.assign(lineReference, matchTaxeToEject);
    }
    // Clone reference into csvData
    this.csvData[invoiceIndex].references[refIndex].reference = lineReference;
    if (this.getTypeReference(lineReference) === 0) {
      this.csvData[invoiceIndex].references[refIndex].tva = (lineReference as ReferenceModel).product.vat;
    }
    this.csvData[invoiceIndex].references[refIndex].taxesHT = taxeSumHT;
    this.csvData[invoiceIndex].references[refIndex].taxesTTC = taxeSumTTC;
    // Check if "reference fee" is associated to lineReference and set status to false if it is
    if (matchfeeToEject = this.reffees.find(reffee => ((lineReference as ReferenceModel).code === reffee.code && this.csvData[invoiceIndex].fournisseur.id === reffee.provider.id))) {
      const feeValue = new FeeValueModel;
      feeValue.fee = new FeeModel();
      feeValue.fee.id = matchfeeToEject.fee.id;
      feeValue.value = this.csvData[invoiceIndex].references[refIndex].prix_unitaire_ht;
      feeValue.vat = matchfeeToEject.fee.vat;
      this.csvData[invoiceIndex].fees.push(feeValue);
      this.removeLine(invoiceIndex, refIndex, 'reference', true); // Transfer from reference's section to fee's section
    }
    this.refreshFormData();
  }

  public getTotalHTReference(referenceData: ReferenceInvoiceDetailModel) {
    const typeRef = this.getTypeReference(referenceData.reference);
    let taxeSum, totalHT, returnVal = 0;

    switch (typeRef) {
      case 0:
        taxeSum = (referenceData.taxesHT) ? referenceData.taxesHT : 0;
        totalHT = referenceData.quantite * referenceData.prix_unitaire_ht * (1 - referenceData.discount / 100) + referenceData.quantite * taxeSum;
        returnVal = totalHT.toFixed(2);
        break;
      case 1:
        returnVal = 0;
        break;
      case 2:
        returnVal = referenceData.prix_unitaire_ht;
        break;
      default:
        break;
    }
    return returnVal;
  }

  public getTotalHT(group: any): void {
    const valeurInitiale = 0;
    let feeHT = 0;

    group.fees.forEach((feeValue: FeeValueModel) => {
      feeHT += feeValue.value;
    });

    const totalRefs = group.references.reduce(function (sum, current: ReferenceInvoiceDetailModel) {
      if (current.status) {
        return sum + current.prix_unitaire_ht * (1 - current.discount / 100) * current.quantite + current.taxesHT * current.quantite;
      } else {
        return sum;
      }
    }, valeurInitiale);

    return totalRefs + feeHT;
  }

  public getTotalTTC(group: any): void {
    const valeurInitiale = 0;
    let feeTTC = 0;

    group.fees.forEach((feeValue: FeeValueModel) => {
      feeTTC += (feeValue.vat > 0) ? feeValue.value * (1 + feeValue.vat / 100) : feeValue.value;
    });

    const totalRefs = group.references.reduce(function (sum: any, current: ReferenceInvoiceDetailModel) {
      const tva = (current.tva) ? current.tva : 0;
      if (current.status) {
        return sum + current.prix_unitaire_ht * (1 - current.discount / 100) * current.quantite * (1 + tva / 100) + current.taxesTTC * current.quantite;
      } else {
        return sum;
      }
    }, valeurInitiale);

    return totalRefs + feeTTC;
  }

  /**
   * Remove reference from invoice
   * @param invoiceIndex Index of invoice on the csvData
   * @param refIndex Index of reference on the csvData
   */
  public removeLine(invoiceIndex, elementIndex, type = 'reference', force = false) {
    if (force) {
      switch (type) {
        case 'reference':
          this.csvData[invoiceIndex].references.splice(elementIndex, 1);
          if (this.csvData[invoiceIndex].references.length === 0) { this.deleteinvoice(invoiceIndex, true); }
          break;
        case 'fee':
          this.csvData[invoiceIndex].fees.splice(elementIndex, 1);
          break;
      }
      return;
    }

    const txt = (type === 'reference') ? 'la référence' : 'le frais';
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '600px', disableClose: false });
    this.dialogRef.componentInstance.confirmMessage = 'Supprimer ' + txt + ' ?';
    this.dialogRef.componentInstance.styleConfirm = 'delete';

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (type) {
          case 'reference':
            this.csvData[invoiceIndex].references.splice(elementIndex, 1);
            if (this.csvData[invoiceIndex].references.length === 0) {
              this.deleteinvoice(invoiceIndex, true);
            }
            break;
          case 'fee':
            this.csvData[invoiceIndex].fees.splice(elementIndex, 1);
            break;
          default:
            break;
        }
        // Reset sum HT & TTC
        this.refreshFormData();
      }
      this.dialogRef = null;
    });
  }

  /**
   * Add reference/fee
   */
  public addLine(invoiceIndex, type = 'reference') {
    if (type === 'reference') {
      this.csvData[invoiceIndex].references.push(new ReferenceInvoiceDetailModel());
    } else if (type === 'fee') {
      this.csvData[invoiceIndex].fees.push(new FeeValueModel());
    }
    this.refreshFormData();
  }

  /**
   * Remove reference/fee from invoice
   */
  public deleteinvoice(invoiceIndex, force = false) {
    if (force) {
      // Remove invoice from csv data
      this.csvData.splice(invoiceIndex, 1);
      // Check valid form
      this.checkValidForm();
      return;
    }
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '600px', disableClose: false });
    this.dialogRef.componentInstance.confirmMessage = 'Supprimer la facture ?';
    this.dialogRef.componentInstance.confirmMessageDetail = 'Ainsi que toutes les rérérences associées.';
    this.dialogRef.componentInstance.styleConfirm = 'delete';

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.csvData.splice(invoiceIndex, 1);
        // Remove invoice from csv data
        this.checkValidForm();
      }
      this.dialogRef = null;
    });
  }

  /**
   * Create a tab to open invoice
   * @param fileName File name in AWS
   */
  public async openFile(fileName) {
    const invoice = await this.invoiceService.findInvoiceByIdentifier(fileName);

    this.invoiceService.openFile(invoice);
  }

  public checkValidForm(silence = false) {
    this.validForm = true;
    this.csvData.forEach(invoice => {
      if (!invoice.isRightEstablishment) {
        this.validForm = false;
      }
      invoice.references.forEach(reference => {
        if (!reference.reference.id && reference.status) {
          this.validForm = false;
        }
      });
    });
    if (this.validForm === true && this.dossier && !silence) {
      this.snackBar.open('Parfait, l\'import peut desormais être enregistré.', '', {
        duration: 3000,
        panelClass: ['mat-bg-accent']
      });
    }
  }

  public checkError(invoiceData) {
    if (invoiceData.total_coopeo_ht.toFixed(2) != invoiceData.total_facture_ht.toFixed(2)) {
      return true;
    } else if (invoiceData.total_coopeo_ttc.toFixed(2) != invoiceData.total_facture_ttc.toFixed(2)) {
      return true;
    } else if (invoiceData.isRightEstablishment === false) {
      return true;
    }
    return false;
  }

  public checkinvoiceSum(invoiceData, type) {
    let diff = 0;
    if (type === 'HT') {
      diff = invoiceData.total_facture_ht - invoiceData.total_coopeo_ht;
    } else if (type === 'TTC') {
      diff = invoiceData.total_facture_ttc - invoiceData.total_coopeo_ttc;
    }
    let txt;
    diff = parseFloat(diff.toFixed(2));
    if (diff > 0) {
      txt = 'Différence ' + type + ' : +' + diff + '€ ';
    } else if (diff < 0) {
      txt = 'Différence ' + type + ' : ' + diff + '€ ';
    } else {
      txt = 'Aucune différence ' + type;
    }
    return '\n' + txt;
  }

  public checkIsRightEstablishment(isRightEstablishment) {
    let txt = '';
    if (isRightEstablishment === false) {
      txt = 'Anomalie N° fichier';
      return '\n' + txt;
    } else {
      return txt;
    }
  }

  public applyDiscount(invoiceIndex) {
    this.csvData[invoiceIndex].references.forEach(reference => {
      reference.discount = (this.getTypeReference(reference.reference) === 0) ? this.csvData[invoiceIndex].discount : 0;
    });
    this.refreshFormData();
  }

  public setCreditType(invoiceIndex, refIndex, type = 0) {
    const quantity = this.csvData[invoiceIndex].references[refIndex].quantite;
    const price = this.csvData[invoiceIndex].references[refIndex].prix_unitaire_ht;
    const taxesHT = this.csvData[invoiceIndex].references[refIndex].taxesHT;
    const taxesTTC = this.csvData[invoiceIndex].references[refIndex].taxesTTC;
    switch (type) {
      case 0: // Remove credit
        this.csvData[invoiceIndex].references[refIndex].credit = type;
        this.csvData[invoiceIndex].references[refIndex].quantite = (quantity < 0) ? -quantity : quantity;
        this.csvData[invoiceIndex].references[refIndex].prix_unitaire_ht = (price < 0) ? -price : price;
        this.csvData[invoiceIndex].references[refIndex].taxesHT = (taxesHT < 0) ? Math.abs(taxesHT) : taxesHT;
        this.csvData[invoiceIndex].references[refIndex].taxesTTC = (taxesTTC < 0) ? Math.abs(taxesTTC) : taxesTTC;
        break;
      case 1: // Set credit quantity
        this.csvData[invoiceIndex].references[refIndex].credit = type;
        this.csvData[invoiceIndex].references[refIndex].quantite = (quantity < 0) ? quantity : -quantity;
        this.csvData[invoiceIndex].references[refIndex].prix_unitaire_ht = (price < 0) ? Math.abs(price) : price;
        this.csvData[invoiceIndex].references[refIndex].taxesHT = (taxesHT < 0) ? Math.abs(taxesHT) : taxesHT;
        this.csvData[invoiceIndex].references[refIndex].taxesTTC = (taxesTTC < 0) ? Math.abs(taxesTTC) : taxesTTC;
        break;
      case 2: // Set credit price
        this.csvData[invoiceIndex].references[refIndex].credit = type;
        this.csvData[invoiceIndex].references[refIndex].quantite = (quantity < 0) ? Math.abs(quantity) : quantity;
        this.csvData[invoiceIndex].references[refIndex].prix_unitaire_ht = (price < 0) ? price : -price;
        this.csvData[invoiceIndex].references[refIndex].taxesHT = (taxesHT < 0) ? taxesHT : -taxesHT;
        this.csvData[invoiceIndex].references[refIndex].taxesTTC = (taxesTTC < 0) ? taxesTTC : -taxesTTC;
        break;
      default:
        break;
    }
    this.refreshFormData();
  }

  public invoiceToCredit(invoiceIndex, type) {
    this.csvData[invoiceIndex].references.forEach((ref, refIndex) => {
      this.setCreditType(invoiceIndex, refIndex, type);
    });
  }

  public refreshEstablishment() {
    this.csvData.forEach(invoice => {
      invoice.dossier = this.dossier;
    });
    this.isRightEstablishment();
  }

  public isRightEstablishment() {
    this.csvData.forEach(invoice => {
      const fileId = invoice.lien_fichier.split('_');
      const expectedEstablishment = this.findEstablishmentFromOldOrNewId(fileId[0]);

      invoice.isRightEstablishment = String(this.dossier) === expectedEstablishment.shortId;
    });
  }

  /**
   * Persist csv datas to API
   */
  public saveData() {
    this.checkValidForm(true);
    if (this.validForm) {
      // TODO: The speard operator can clone only first level of the array, find a solution to clone all of the data
      let csvDateClone: ReferenceInvoiceModel[] = this.csvData.slice();
      csvDateClone.forEach(referenceFacture => {
        referenceFacture.date_facture = moment(referenceFacture.date_facture).format('YYYY-MM-DD');
        referenceFacture.references.forEach(element => {
          element.date_livraison = moment(element.date_livraison).format('YYYY-MM-DD');
        });

        referenceFacture.references = referenceFacture.references.filter(referenceFactureDetailModel => {
          return referenceFactureDetailModel.status;
        });
      });
      this.importFactureService.createImportinvoice(csvDateClone).subscribe((res) => {
        this.snackBar.open('Import validé', '', {
          duration: 3000,
          panelClass: ['mat-bg-primary']
        });
        this.loaderService.changeCheck(true);
        setTimeout(() => { this.loaderService.changeCheck(false); }, 3000);
        this.csvData = [];
        csvDateClone = [];
      });
    }
  }
}
