import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstablishmentTypeModel } from './establishment-type.model';

@Injectable({
    providedIn: 'root'
})
export class EstablishmentTypeService {
    constructor(private http: HttpClient) { }

    public getEstablismentTypes(): Observable<EstablishmentTypeModel[]> {
        const params = new HttpParams().set('order[id]', 'asc');
        return this.http.get<EstablishmentTypeModel[]>(`${environment.apiUrls[environment.name]}/establishment_types`, {
            params: params
        }).pipe(map(data => data['hydra:member']));
    }

    public createEstablishmentType(establishmentType: EstablishmentTypeModel): Observable<EstablishmentTypeModel> {
        return this.http.post<EstablishmentTypeModel>(`${environment.apiUrls[environment.name]}/set_establishment_type`, establishmentType);
    }

    public updateEstablishmentType(establishmentType: EstablishmentTypeModel): Observable<EstablishmentTypeModel> {
        return this.http.post<EstablishmentTypeModel>(`${environment.apiUrls[environment.name]}/set_establishment_type`, establishmentType);
    }

    public deleteEstablishmentType(establishmentType: EstablishmentTypeModel): Observable<EstablishmentTypeModel> {
        return this.http.delete<EstablishmentTypeModel>(`${environment.apiUrls[environment.name]}/establishment_types/${establishmentType.id}`);
    }
}