export class UserModel {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    phone: string;
    createdAt: string;
    updatedAt: string;
    status: number;
    establishments: [{
        establishment: string;
        isOwner: boolean;
    }];

    constructor() { }
}


export class ResponseModalModel {
    response: string;
    element: UserModel;

    constructor() {
        this.element = new UserModel();
        this.response = '';
    }
}
