import { Component, OnInit, ViewChild } from '@angular/core';
import { FeeModel } from '../fee.model';
import { FeesService } from '../fees.service';
import { FeeDetailComponent } from '../fee-detail/fee-detail.component';
import { SlugifyPipe } from '@app/_helpers/slugify.pipe';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-fees-list',
  templateUrl: './fees-list.component.html',
  styleUrls: ['./fees-list.component.scss']
})
export class FeesListComponent implements OnInit {
  public displayedColumns: string[] = ['id', 'name', 'vat', 'isNegotiable', 'actions'];
  public elementsList: FeeModel[];
  public elementType: string;
  public elementsMatTable;
  public loading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private elementService: FeesService,
    private slugifyPipe: SlugifyPipe,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.elementsMatTable = new MatTableDataSource<FeeModel>();
    this.initMatTable();
    this.elementService.getfees().subscribe(
      (elements: FeeModel[]) => {
        this.loading = false;
        this.elementsList = elements;
        this.elementsMatTable = new MatTableDataSource<FeeModel>(this.elementsList);
        this.initMatTable();
      }
    );
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Search in table
   * @param filterValue string to research in array list
   */
  applyFilter(filterValue: string) {
    this.elementsMatTable.filter = this.slugifyPipe.transform(filterValue, true);
  }

  /**
   * Switch context between creation/edition feature
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  modalElement(context: string, element: FeeModel): void {
    if (context === 'create') {
      this.createElement(context);
    } else if (context === 'update') {
      this.updateElement(context, element);
    }
  }

  /**
   * Display Modal for element's creation
   * @param context 'create' or 'update'
   */
  createElement(context: string) {
    const createApp = this.dialog.open(FeeDetailComponent, {
      width: '600px',
      data: {element: new FeeModel(), context: context, elementType: this.elementType},
      disableClose: true
    });

    // Observable after modal closed
    createApp.afterClosed().subscribe(result => {
      if ( result.response === 'create' ) {
        const match = this.elementsList.find(i => this.slugifyPipe.transform(i.name, true) === this.slugifyPipe.transform(result.element.name, true));
        if (match === undefined) {
          this.elementService.createfee(result.element).subscribe(
            res => {
              this.snackBar.open('Element "' + result.element.name + '" créé.', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });
              this.elementsList.unshift(res);
              this.elementsMatTable = new MatTableDataSource<FeeModel>(this.elementsList);
              this.initMatTable();
            }
          );
        } else {
          this.translate.get('global.actions.duplicateWith').subscribe((res: string) => {
            this.snackBar.open(res + ' "' + result.element.name + '".', '', {
              duration: 3000,
              panelClass: ['mat-bg-warn']
            });
          });
        }
      }
    });
  }

  /**
   * Display Modal for element's edition
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  updateElement(context: string, element: FeeModel) {
    const updateElt = this.dialog.open(FeeDetailComponent, {
      width: '600px',
      data: {element: element, context: context},
      disableClose: true
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe(result => {
      if ( result.response === 'update' ) {
        this.elementService.updatefee(result.element).subscribe(
          res => {
            const refList = this.elementsList;
            const index = refList.findIndex(array => array.id === result.element.id);
            refList[index] = res;
            this.elementsMatTable = new MatTableDataSource<FeeModel>(refList);
            this.initMatTable();
            this.snackBar.open('Element "' + result.element.name + '" modifié.', '', {
              duration: 3000,
              panelClass: ['mat-bg-primary']
            });
          }
        );
      }
    });
  }

  /**
   * Delete element
   * @param element Element to remove
   */
  public deleteElement(element) {
    if (window.confirm('Confirmer la suppression de la reference "' + element.name + '" ?')) {
      this.elementService.deletefee(element).subscribe(
        res => {
          const refList = this.elementsList;
          const index = refList.findIndex(array => array.id === element.id);
          refList.splice(index, 1);
          this.elementsMatTable = new MatTableDataSource<FeeModel>(refList);
          this.initMatTable();
          this.snackBar.open('Element "' + element.name + '" supprimé.', '', {
            duration: 3000,
            panelClass: ['mat-bg-primary']
          });
        }
      );
    }
  }
}
