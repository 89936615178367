import { Injectable } from '@angular/core';
import { ReferencesTaxeModel } from './references-taxe.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferencesTaxesService {

  constructor(private http: HttpClient) { }

  public getReferencesTaxes(offset = 0, items_per_page = null, filterValue = null): Observable<ReferencesTaxeModel[]> {
    offset++;
    let params = new HttpParams().set('order[id]', 'desc').set('page', offset.toString());
    if (items_per_page) {
      params = params.set('items_per_page', items_per_page.toString());
    }
    params = params.append('status[]', '1').append('status[]', '2').append('status[]', '3');
    if (filterValue) {
        filterValue.forEach(element => {
            params = (element.value) ? params.append(element.type, element.value.toString()) : params;
        });
    }
    return this.http.get<ReferencesTaxeModel[]>(`${environment.apiUrls[environment.name]}/reference_taxes`, { params: params });
  }

  public createReferencesTaxe(referencesTaxe: ReferencesTaxeModel): Observable<ReferencesTaxeModel> {
    const params = {
      'code': referencesTaxe.code,
      'provider': '/providers/' + referencesTaxe.provider.id,
      'taxe': '/taxes/' + referencesTaxe.taxe.id
    };
    return this.http.post<ReferencesTaxeModel>(`${environment.apiUrls[environment.name]}/reference_taxes`, params);
  }

  public updateReferencesTaxe(referencesTaxe: ReferencesTaxeModel): Observable<ReferencesTaxeModel> {
    const params = {
      'code': referencesTaxe.code,
      'provider': '/providers/' + referencesTaxe.provider.id,
      'taxe': '/taxes/' + referencesTaxe.taxe.id
    };
    return this.http.put<ReferencesTaxeModel>(`${environment.apiUrls[environment.name]}/reference_taxes/${referencesTaxe.id}`, params);
  }

  public deleteReferencesTaxe(referencesTaxe: ReferencesTaxeModel): Observable<ReferencesTaxeModel> {
    return this.http.delete<ReferencesTaxeModel>(`${environment.apiUrls[environment.name]}/reference_taxes/${referencesTaxe.id}`);
  }
}
