export class ProductsFamilyModel {
    id: number;
    name: string;
    color: string;
    shortName: string;

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: ProductsFamilyModel;

    constructor() {
        this.element = new ProductsFamilyModel();
        this.response = '';
    }
}
