import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { InvoiceFileModel, INVOICES_STATE_VISIBLE } from '../invoice-file.model';
import { InvoicesFilesService } from '../invoices-files.service';
import { SelectionModel } from '@angular/cdk/collections';
import { InvoiceFileDetailComponent } from '../invoice-file-detail/invoice-file-detail.component';
import { ConfirmationDialogComponent } from '@app/_components/confirmation-dialog';
import { merge, Observable, fromEvent, of } from 'rxjs';
import { startWith, switchMap, map, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LoaderService } from '@app/_services/loader.service';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { EstablishmentState } from '@app/pages/establishments/establishment.model';
import { SelectParserComponent } from '../select-parser/select-parser.component';
import { ParserModel } from '../select-parser/parser.model';

@Component({
  selector: 'app-invoices-files-list',
  templateUrl: './invoices-files-list.component.html',
  styleUrls: ['./invoices-files-list.component.scss']
})
export class InvoicesFilesListComponent implements OnInit {

  public displayedColumns: string[] = ['select', 'originalName', 'identifierFile', 'nameEstablishment', 'providersName', 'handledFixesFees', 'state', 'numberOfInvoices', 'isHighPriority', 'createdAt', 'isExtActive', 'isCoopeoActive', 'isOcrActive', 'stateEstablishment', 'isNative', 'actions'];
  public elementType: string;
  public elementsMatTable = new MatTableDataSource<InvoiceFileModel>();
  public selection = new SelectionModel<InvoiceFileModel>(true, []);
  public dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  public resultsLength;
  public pageEvent: PageEvent;
  public reload = new EventEmitter();
  public state: any = INVOICES_STATE_VISIBLE;
  public establishmentState: EstablishmentState | null = null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  nameSearchInput = new FormControl('');
  identifierSearchInput = new FormControl('');
  establishmentSearchInput = new FormControl('');
  providerSearchInput = new FormControl('');
  createdAtSearchInput = new FormControl('');
  stateSearchInput = new FormControl('');
  establishmentStateSearchInput = new FormControl('');
  extSearchInput = new FormControl('');
  intSearchInput = new FormControl('');
  ocrSearchInput = new FormControl('');
  prioritySearchInput = new FormControl('');
  nativeSearchInput = new FormControl('');
  previousSelected: any;
  limitDays0 = 5;
  limitDays1 = 10;
  limitDays2 = 15;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private elementService: InvoicesFilesService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.getInvoicesList();

    this.nameSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.identifierSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.establishmentSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.providerSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.createdAtSearchInput.valueChanges.subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.stateSearchInput.valueChanges.subscribe(() => { this.redefineStates(); });
    this.establishmentStateSearchInput.valueChanges.subscribe(() => {
      this.establishmentState = this.establishmentStateSearchInput.value;
      this.reload.emit();
    });
    this.extSearchInput.valueChanges.subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.intSearchInput.valueChanges.subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.ocrSearchInput.valueChanges.subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.prioritySearchInput.valueChanges.subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.nativeSearchInput.valueChanges.subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
  }

  redefineStates() {
    switch (this.stateSearchInput.value) {
      case '0':
        this.state = [0, 1, 3];
        break;
      case '1':
        this.state = [0, 1, 2, 3];
        break;
      case '2':
        this.state = [0];
        break;
      case '3':
        this.state = [1];
        break;
      case '4':
        this.state = [3];
        break;
      case '5':
        this.state = [2];
        break;
    }
    this.reload.emit();
  }

  getInvoicesList() {
    merge(this.paginator.page, this.reload)
      .pipe(
        startWith(() => { }),
        switchMap(() => {
          this.selection.clear();
          const filterValue = [
            { 'type': 'originalName', 'value': this.nameSearchInput.value },
            { 'type': 'identifierFile', 'value': this.identifierSearchInput.value },
            { 'type': 'establishment.name', 'value': this.establishmentSearchInput.value },
            { 'type': 'providersName', 'value': this.providerSearchInput.value },
            { 'type': 'isExtActive', 'value': this.extSearchInput.value },
            { 'type': 'isCoopeoActive', 'value': this.intSearchInput.value },
            { 'type': 'isOcrActive', 'value': this.ocrSearchInput.value },
            { 'type': 'establishment.isHighPriority', 'value': this.prioritySearchInput.value },
            { 'type': 'isNative', 'value': this.nativeSearchInput.value },
          ];
          if (+this.createdAtSearchInput.value === 1) {
            const limitEnd = moment.utc().subtract(this.limitDays0, 'days').format('YYYY-MM-DD HH:mm:ss');
            filterValue.push({ 'type': 'createdAt[strictly_after]', 'value': limitEnd });
          }
          if (+this.createdAtSearchInput.value === 2) {
            const limitStart = moment.utc().subtract(this.limitDays0, 'days').format('YYYY-MM-DD HH:mm:ss');
            const limitEnd = moment.utc().subtract(this.limitDays1, 'days').format('YYYY-MM-DD HH:mm:ss');
            filterValue.push({ 'type': 'createdAt[strictly_before]', 'value': limitStart });
            filterValue.push({ 'type': 'createdAt[strictly_after]', 'value': limitEnd });
          }
          if (+this.createdAtSearchInput.value === 3) {
            const limitStart = moment.utc().subtract(this.limitDays1, 'days').format('YYYY-MM-DD HH:mm:ss');
            const limitEnd = moment.utc().subtract(this.limitDays2, 'days').format('YYYY-MM-DD HH:mm:ss');
            filterValue.push({ 'type': 'createdAt[strictly_before]', 'value': limitStart });
            filterValue.push({ 'type': 'createdAt[strictly_after]', 'value': limitEnd });
          }
          if (+this.createdAtSearchInput.value === 4) {
            const limitStart = moment.utc().subtract(this.limitDays2, 'days').format('YYYY-MM-DD HH:mm:ss');
            filterValue.push({ 'type': 'createdAt[strictly_before]', 'value': limitStart });
          }

          this.loaderService.changeLoader(true);
          return this.elementService.getInvoices(this.paginator.pageIndex, this.paginator.pageSize, filterValue, this.state, this.establishmentState);
        }),
        map(data => {
          this.loaderService.changeLoader(false);
          this.resultsLength = data['hydra:totalItems'];

          return data;
        }),
        catchError(() => {
          this.loaderService.changeLoader(false);
          return new Observable();
        })
      ).subscribe(data => {
        this.loaderService.changeLoader(false);
        this.elementsMatTable.data = data['hydra:member'];
      }
      );
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Call to the service and open file in new tab
   * @param invoice invoice consultation
   */
  public openFile(invoice) {
    this.elementService.openFile(invoice);
  }

  public batchDelete() {
    if (this.selection.selected.length > 0) {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '600px', disableClose: false });
      this.dialogRef.componentInstance.confirmMessage = 'Supprimer le fichier ?';
      this.dialogRef.componentInstance.confirmMessageDetail = `Confirmer la suppression des ${this.selection.selected.length} fichiers.`;
      this.dialogRef.componentInstance.styleConfirm = 'delete';

      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {

          this.elementService.batchDelete(this.selection.selected).subscribe(
            res => {
              this.snackBar.open('Fichiers supprimés', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });

              const refList = this.elementsMatTable.data;

              this.selection.selected.forEach(invoice => {
                const index = refList.findIndex(row => row.id === invoice.id);
                refList.splice(index, 1);
              });

              // Remove from MatTable
              this.elementsMatTable = new MatTableDataSource<InvoiceFileModel>(refList);
              this.selection.clear();
              this.getInvoicesList();
              this.initMatTable();
            }
          )
        }
      })
    } else {
      this.snackBar.open('Aucun fichier selectionné', '', {
        duration: 3000,
        panelClass: ['mat-bg-warn']
      });
    }
  }
  /**
   * Delete element
   * @param element Element to remove
   */
  public deleteInvoice(element: InvoiceFileModel, withFile = true) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '600px', disableClose: false });
    this.dialogRef.componentInstance.confirmMessage = 'Supprimer le fichier ?';
    this.dialogRef.componentInstance.confirmMessageDetail = 'Confirmer la suppression du fichier.';
    this.dialogRef.componentInstance.styleConfirm = 'delete';

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.elementService.deleteInvoice(element).subscribe(
          () => {
            // Remove from MatTable
            const refList = this.elementsMatTable.data;
            const index = refList.findIndex(array => array.id === element.id);
            refList.splice(index, 1);
            this.elementsMatTable = new MatTableDataSource<InvoiceFileModel>(refList);
            this.initMatTable();
            // Snackbar for user
            this.snackBar.open('Element "' + element.originalName + '" supprimé.', '', {
              duration: 3000,
              panelClass: ['mat-bg-primary']
            });
          }
        );
      }
      this.dialogRef = null;
    });
  }

  /**
   * Open modal to edit invoice
   * @param invoice invoice edition
   */
  public editInvoice(invoice) {
    const updateElt = this.dialog.open(InvoiceFileDetailComponent, {
      width: '600px',
      data: { element: invoice },
      disableClose: true
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe(result => {
      if (result.response === 'update') {
        this.elementService.updateinvoice(result.element)
          .then(
            res => {
              const index = this.elementsMatTable.data.findIndex(array => array.id === result.element.id);
              this.elementsMatTable.data[index] = res;
              this.elementsMatTable = new MatTableDataSource<InvoiceFileModel>(this.elementsMatTable.data);
              this.snackBar.open('Le fichier "' + res.originalName + '" a été modifié', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });
            }
          );
      }
    });
  }

  public analyzeInvoice(invoice) {
    if (invoice.hasBeenAnalyzed) {
      return;
    }
    this.elementService.analyzeInvoice(invoice)
      .subscribe({
        next: res => {
          this.snackBar.open('Le fichier est en cours d\'analyse...', '', {
            duration: 3000,
            panelClass: ['mat-bg-primary']
          });
          this.elementService.flagAnalyzed(invoice).subscribe(res => {
            const refList = this.elementsMatTable.data;
            const index = refList.findIndex(array => array.id === invoice.id);
            refList[index].hasBeenAnalyzed = true
            this.elementsMatTable = new MatTableDataSource<InvoiceFileModel>(refList);
            this.initMatTable();
          })
        },
        error: err => {
          this.snackBar.open('Erreur lors de l\'analyse : ' + err.error['hydra:description'], '', {
            duration: 5000,
            panelClass: ['mat-bg-warn']
          })
        }
      });
  }

  /**
   * Call to the service and download file
   * @param invoice invoice to download
   */
  public async downloadInvoice(invoice) {
    await this.elementService.downloadInvoices([invoice]);
    if (invoice.state === 0) {
      invoice.state = 1;
      this.elementService.updateinvoice(invoice);
    }
  }

  public async downloadInvoices() {
    if (this.selection.selected.length > 0) {
      await this.elementService.downloadInvoices(this.selection.selected);
      this.selection.selected.forEach(invoice => {
        if (invoice.state === 0) {
          invoice.state = 1;
          this.elementService.updateinvoice(invoice);
        }
      });
    } else {
      this.snackBar.open('Aucun fichier selectionné', '', {
        duration: 3000,
        panelClass: ['mat-bg-warn']
      });
    }
  }

  public changeState(state: string): void {
    if (this.selection.selected.length > 0) {
      this.selection.selected.forEach(invoice => {
        invoice.state = +state;
        this.elementService.updateinvoice(invoice);
      });
      this.snackBar.open('Statut(s) modifié(s)', '', {
        duration: 3000,
        panelClass: ['mat-bg-primary']
      });
    } else {
      this.snackBar.open('Aucun fichier selectionné', '', {
        duration: 3000,
        panelClass: ['mat-bg-warn']
      });
    }
  }

  public sendToExternal() {
    if (this.selection.selected.length > 0) {
      if (this.selection.selected.some(invoice => invoice.state !== 0)) {
        this.snackBar.open('Certains fichiers ne sont plus en attente de traitement', '', {
          duration: 3000,
          panelClass: ['mat-bg-warn']
        });
        return;
      }

      this.elementService.sendInvoicesToExternal(this.selection.selected).subscribe(
        res => {
          this.snackBar.open('Fichiers envoyés', '', {
            duration: 3000,
            panelClass: ['mat-bg-primary']
          });

          this.selection.selected.forEach(invoice => {
            invoice.isExtActive = true;
            invoice.state = 1;
            this.elementService.updateinvoice(invoice);
          });

          this.selection.clear();
        }
      )
    } else {
      this.snackBar.open('Aucun fichier selectionné', '', {
        duration: 3000,
        panelClass: ['mat-bg-warn']
      });
    }
  }

  public sendToOcr() {
    if (this.selection.selected.length > 0) {
      if (this.selection.selected.some(invoice => invoice.state !== 0)) {
        this.snackBar.open('Certains fichiers ne sont plus en attente de traitement', '', {
          duration: 3000,
          panelClass: ['mat-bg-warn']
        });
        return;
      }

      // open a modal to select a parser
      const dialogRef = this.dialog.open(SelectParserComponent, {
        width: '600px',
        data: this.selection.selected
      });

      // perform action after selecting the parser
      dialogRef.afterClosed().subscribe((result: ParserModel|null) => {
        if (result) {
          this.elementService.sendInvoicesToParser(this.selection.selected, result).subscribe(
            res => {
              this.snackBar.open('Fichiers envoyés', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });

              this.selection.selected.forEach(invoice => {
                invoice.isOcrActive = true;
                invoice.state = 1;
                this.elementService.updateinvoice(invoice);
              });

              this.selection.clear();
            }
          )
        }
      });
    } else {
      this.snackBar.open('Aucun fichier selectionné', '', {
        duration: 3000,
        panelClass: ['mat-bg-warn']
      });
    }
  }

  public changeTreatment(state: string) {
    if (this.selection.selected.length > 0) {
      this.selection.selected.forEach(invoice => {
        invoice.isExtActive = (state === 'ext') ? true : invoice.isExtActive;
        invoice.isCoopeoActive = (state === 'int') ? true : invoice.isCoopeoActive;
        invoice.isOcrActive = (state === 'ocr') ? true : invoice.isOcrActive;
        this.elementService.updateinvoice(invoice);
      });
      this.snackBar.open('Traitement ' + state + ' modifiée(s)', '', {
        duration: 3000,
        panelClass: ['mat-bg-primary']
      });
    } else {
      this.snackBar.open('Aucun fichier selectionné', '', {
        duration: 3000,
        panelClass: ['mat-bg-warn']
      });
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.elementsMatTable.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.elementsMatTable.data.forEach((row: InvoiceFileModel) => this.selection.select(row));
  }

  public toggleSelected(obj, event, alldata) {
    if (event.shiftKey) {
      obj.isSelected = !obj.isSelected;

      let index = alldata - 1;
      let exit = false;
      while (exit === false) {
        if (this.selection.isSelected(this.elementsMatTable.data[index])) {
          exit = true;
        } else {
          this.selection.select(this.elementsMatTable.data[index]);
          index--;
        }
      }
    }
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: InvoiceFileModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  public updateCoopeoTreatment(element: InvoiceFileModel) {
    this.elementService.updateinvoice(element)
      .then(
        () => {
          this.snackBar.open('Traitement coopeo mis à jour', '', {
            duration: 1500,
            panelClass: ['mat-bg-primary']
          });
        }
      );
  }

  public defineLimitDate(date) {
    const diffDates = moment.utc().diff(moment.utc(date).format('YYYY-MM-DD HH:mm:ss'));
    const limit0 = moment.utc().diff(moment.utc().subtract(this.limitDays0, 'days').format('YYYY-MM-DD HH:mm:ss'));
    const limit1 = moment.utc().diff(moment.utc().subtract(this.limitDays1, 'days').format('YYYY-MM-DD HH:mm:ss'));
    const limit2 = moment.utc().diff(moment.utc().subtract(this.limitDays2, 'days').format('YYYY-MM-DD HH:mm:ss'));

    if (diffDates <= limit0) {
      return 'bg-success ';
    } else if (diffDates > limit0 && diffDates < limit1) {
      return 'bg-warning ';
    } else if (diffDates > limit1 && diffDates < limit2) {
      return 'fifteen-days ';
    } else {
      return 'bg-danger ';
    }
  }
}
