import { Injectable } from '@angular/core';
import { ProviderModel } from './provider.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  constructor(private http: HttpClient) { }

  public getProviders(): Observable<ProviderModel[]> {
    const params = new HttpParams().set('order[id]', 'desc');
    return this.http.get<ProviderModel[]>(`${environment.apiUrls[environment.name]}/providers`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public getProvidersList(value: string = null, itemPerPage: number = null): Observable<ProviderModel[]> {
    let params = new HttpParams().set('order[id]', 'desc').append('groups[]', 'list');
    if (value) {
      params = params.append('name', value);
    }
    if (itemPerPage !== null) {
      params = params.append('items_per_page', itemPerPage.toString());
    }
    return this.http.get<ProviderModel[]>(`${environment.apiUrls[environment.name]}/providers`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public createProvider(provider: ProviderModel): Observable<ProviderModel> {
    return this.http.post<ProviderModel>(`${environment.apiUrls[environment.name]}/providers`, provider);
  }

  public updateProvider(provider: ProviderModel): Observable<ProviderModel> {
    return this.http.put<ProviderModel>(`${environment.apiUrls[environment.name]}/providers/${provider.id}`, provider);
  }

  public deleteProvider(provider: ProviderModel): Observable<ProviderModel> {
    return this.http.delete<ProviderModel>(`${environment.apiUrls[environment.name]}/providers/${provider.id}`);
  }
}
