import { Component, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import { UserEstablishmentModel } from '@app/pages/establishments/establishment.model';
import { LoaderService } from '@app/_services/loader.service';
import { MercurialeModel } from '../mercuriale.model';
import { MercurialesService } from '../mercuriales.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, startWith, switchMap, map, catchError } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '@app/_components/confirmation-dialog';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-mercuriales-list',
  templateUrl: './mercuriales-list.component.html',
  styleUrls: ['./mercuriales-list.component.scss']
})
export class MercurialesListComponent implements OnInit {
  @Input() events: Observable<any>;

  public mercuriales: any;
  public currentUserEstablishment: UserEstablishmentModel;
  public dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  public reload = new EventEmitter();

  public displayedColumns: string[] = ['identifierFile', 'identifier', 'establishment', 'provider', 'createdAt', 'mercurialeRows', 'actions'];
  public elementsList: MercurialeModel[];
  public elementType: string;
  public elementsMatTable = new MatTableDataSource<MercurialeModel>();
  public loading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  providerSearchInput = new FormControl('');
  establishmentSearchInput = new FormControl('');
  identifierSearchInput = new FormControl('');
  identifierFileSearchInput = new FormControl('');
  resultsLength = Number;

  public eventsSubject: Subject<any> = new Subject<any>();

  constructor(
    private elementService: MercurialesService,
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loaderService.changeLoader(true);
    this.getStats();

    this.identifierSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.identifierFileSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.providerSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.establishmentSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
  }

  /**
   * Get datas from API and formatte them in the chart
   */
  public getStats() {
    merge(this.paginator.page, this.reload)
      .pipe(
        startWith(() => { }),
        switchMap(() => {
          const filterValue = [
            { 'type': 'establishmentProvider.provider.name', 'value': this.providerSearchInput.value },
            { 'type': 'establishmentProvider.establishment.name', 'value': this.establishmentSearchInput.value },
            { 'type': 'identifier', 'value': this.identifierSearchInput.value },
            { 'type': 'identifierFile', 'value': this.identifierFileSearchInput.value }
          ];
          this.loaderService.changeLoader(true);
          return this.elementService.getMercuriales(this.paginator.pageIndex, this.paginator.pageSize, filterValue);
        }),
        map(data => {
          this.loaderService.changeLoader(false);
          this.resultsLength = data['hydra:totalItems'];

          return data;
        }),
        catchError(() => {
          this.loaderService.changeLoader(false);
          return new Observable();
        })
      ).subscribe(data => {
        this.loaderService.changeLoader(false);
        this.elementsMatTable.data = data['hydra:member'];
      }
      );
  }

  /**
   * Define the filters and redefine the chart
   * @param filters object of dateStart, dateEnd and list of establishments
   */
  public setFilters(filters) {
    // Send the filters to all components called in the dashboard
    this.eventsSubject.next({ filters: filters });
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Search in table
   * @param filterValue string to research in array list
   */
  applyFilter(filterValue: string) {
    this.elementsMatTable.filter = filterValue.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Call to the service and open file in new tab
   * @param file file consultation
   */
  public openFile(file) {
    // this.elementService.openFile(file);
  }

  public delete(mercuriale: MercurialeModel) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '600px', disableClose: false });
    this.dialogRef.componentInstance.confirmMessage = 'Supprimer la mercuriale ?';
    this.dialogRef.componentInstance.confirmMessageDetail = 'Confirmer la suppression de la mercuriale.';
    this.dialogRef.componentInstance.styleConfirm = 'delete';

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.elementService.deleteMercuriale(mercuriale).subscribe(
          () => {
            // Remove from MatTable
            const index = this.elementsMatTable.data.findIndex(array => array.id === mercuriale.id);
            this.elementsMatTable.data.splice(index, 1);
            this.elementsMatTable = new MatTableDataSource<MercurialeModel>(this.elementsMatTable.data);
            // Snackbar for user
            this.snackBar.open('Mercuriale supprimé', '', {
              duration: 3000,
              panelClass: ['mat-bg-warn']
            });
          }
        );
      }
      this.dialogRef = null;
    });
  }

}
