import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ResponseRefModel, ReferenceModel } from '../../references/reference.model';
import { BrandModel } from '@app/pages/brands/brand.model';
import { ProviderModel } from '@app/pages/providers/provider.model';
import { Observable } from 'rxjs';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { merge } from 'rxjs';
import { AppellationModel } from '@app/pages/appellations/appellation.model';
import { ProductModel } from '@app/pages/products/product.model';
import { TextValidator } from '@app/_validators/text.validator';
import { BrandsService } from '@app/pages/brands/brands.service';
import { ProvidersService } from '@app/pages/providers/providers.service';
import { ProductService } from '@app/pages/products/product.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndustrialModel } from '@app/pages/industrials/industrial.model';
import { IndustrialsService } from '@app/pages/industrials/industrials.service';

@Component({
  selector: 'app-reference-detail',
  templateUrl: './reference-detail.component.html'
})
export class ReferenceDetailComponent implements OnInit {
  public refForm: FormGroup;
  public responseRef: ResponseRefModel = new ResponseRefModel();
  public filteredBrandsList: BrandModel[] | void;
  public filteredIndustrialsList: IndustrialModel[] | void;
  public filteredProvidersList: ProviderModel[] | void;
  public productsList: ProductModel[] = [];
  public currentReference: any;
  public brandSearchInput = new FormControl('');
  public industrialSearchInput = new FormControl('');
  public providerSearchInput = new FormControl('');
  public reloadProviders = new EventEmitter();
  public reloadBrands = new EventEmitter();
  public reloadIndustrials = new EventEmitter();
  public appellationName: string;
  public productName: string;

  constructor(
    public dialogRef: MatDialogRef<ReferenceDetailComponent>,
    private providersService: ProvidersService,
    private productService: ProductService,
    private brandsService: BrandsService,
    private industrialsService: IndustrialsService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.currentReference = this.data.reference;
    this.refForm = new FormGroup({
      code: new FormControl(this.currentReference.code, [Validators.required, TextValidator.noWhiteSpace]),
      brand: new FormControl((this.currentReference.brand) ? this.currentReference.brand : null),
      industrial: new FormControl((this.currentReference.industrial) ? this.currentReference.industrial : null),
      productsFamily: new FormControl((this.currentReference.productsFamily) ? this.currentReference.productsFamily.id : '', [Validators.required]),
      provider: new FormControl(
        (this.currentReference.provider) ? { value: this.currentReference.provider, disabled: this.currentReference.invoices.length > 0 } : null,
        (this.currentReference.provider) ? [Validators.required] : [Validators.required, TextValidator.noWhiteSpace]
      ),
      taxes: new FormControl((this.currentReference.taxes) ? this.currentReference.taxes.map(res => res.id) : ''),
      packagingUnit: new FormControl((this.currentReference.packagingUnit) ? this.currentReference.packagingUnit.id : ''),
      packagingQuantity: new FormControl(this.currentReference.packagingQuantity),
      equivalence: new FormControl((this.currentReference.equivalence) ? this.currentReference.equivalence.id : ''),
      invoiceRatio: new FormControl(this.currentReference.invoiceRatio, [Validators.required, Validators.min(0.00001)]),
      unit: new FormControl((this.currentReference.unit) ? this.currentReference.unit.id : '', [Validators.required]),
      unitValue: new FormControl(this.currentReference.unitValue, (this.currentReference.unitValue) ? [Validators.required] : [Validators.required, TextValidator.noWhiteSpace]),
      isUncertain: new FormControl((this.currentReference.isUncertain) ? this.currentReference.isUncertain : false),
      coopeoNote: new FormControl(this.currentReference.coopeoNote),
      info: new FormControl(this.currentReference.info),
      appellation: new FormControl(),
      product: new FormControl(this.data.product),
    });
    if (this.data.context === 'update') {
      this.refForm.addControl('id', new FormControl(this.currentReference.id));
    }

    this.checkProductId();

    this.reloadProvidersList();
    this.reloadBrandsList();
    this.reloadIndustrialsList();
    this.refForm.get('brand').valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(() => { this.reloadBrands.emit(); });
    this.refForm.get('industrial').valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(() => { this.reloadIndustrials.emit(); });
    this.refForm.get('provider').valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(() => { this.reloadProviders.emit(); });
    this.refForm.get('product').valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.checkProductId(); });
  }

  private reloadProvidersList() {
    merge(this.reloadProviders)
      .pipe(
        startWith(() => { }),
        switchMap(() => {
          return this.providersService.getProvidersList(this.refForm.get('provider').value, (this.refForm.get('provider').value) ? 50 : 0);
        }),
        map((data: ProviderModel[]) => {
          return data;
        }),
        catchError(() => {
          return new Observable();
        })
      ).subscribe((data: ProviderModel[]) => {
        this.filteredProvidersList = data;
      }
      );
  }

  private reloadBrandsList() {
    merge(this.reloadBrands)
      .pipe(
        startWith(() => { }),
        switchMap(() => {
          return this.brandsService.getBrandsList(this.refForm.get('brand').value, (this.refForm.get('brand').value) ? 50 : 0);
        }),
        map(data => {
          return data;
        }),
        catchError(() => {
          return new Observable();
        })
      ).subscribe((data: BrandModel[]) => {
        this.filteredBrandsList = data;
      }
      );
  }

  private reloadIndustrialsList() {
    merge(this.reloadIndustrials)
      .pipe(
        startWith(() => { }),
        switchMap(() => {
          return this.industrialsService.getIndustrialsList(this.refForm.get('industrial').value, (this.refForm.get('industrial').value) ? 50 : 0);
        }),
        map(data => {
          return data;
        }),
        catchError(() => {
          return new Observable();
        })
      ).subscribe((data: IndustrialModel[]) => {
        this.filteredIndustrialsList = data;
      }
      );
  }

  private _filterElement(value: any, list: ProviderModel[] | BrandModel[] | AppellationModel[] | IndustrialModel[]): any[] {
    const filterValue = value.toLowerCase();
    return list.filter(cat => cat.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public displayFn(element?: ProviderModel | BrandModel | AppellationModel | IndustrialModel): string | undefined {
    return element ? element.name : undefined;
  }

  public hasError(controlName: string, errorName: string) {
    return this.refForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'referenceCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseRef.response = this.data.context;
      this.responseRef.reference = this.sanitizeValues(this.refForm.value);
      this.dialogRef.close(this.responseRef);
    }
  }

  public sanitizeValues(reference: any): ReferenceModel {
    reference.brand = (reference.brand) ? '/brands/' + reference.brand.id : null;
    reference.industrial = (reference.industrial) ? '/industrials/' + reference.industrial.id : null;
    reference.product = '/products/' + (reference.product) ? reference.product : this.data.product;
    reference.productsFamily = '/product_families/' + reference.productsFamily;
    reference.vatCategory = (reference.vatCategory) ? '/vat_categories/' + reference.vatCategory : null;
    reference.provider = '/providers/' + ((reference.hasOwnProperty('provider')) ? reference.provider.id : this.currentReference.provider.id);
    reference.packagingUnit = (reference.packagingUnit) ? '/units/' + reference.packagingUnit : null;
    reference.packagingQuantity = +reference.packagingQuantity;
    reference.unit = (reference.unit) ? '/units/' + reference.unit : null;
    reference.unitValue = Math.abs(reference.unitValue);
    reference.equivalence = (reference.equivalence) ? '/units/' + reference.equivalence : null;
    reference.invoiceRatio = Math.abs(reference.invoiceRatio);
    reference.taxes = (reference.taxes) ? reference.taxes.map(x => '/taxes/' + x) : [];
    return reference;
  }

  public checkProductId() {
    const productId = this.refForm.get('product').value;
    this.productService.getProduct(productId).then((res: ProductModel) => {
      this.productName = res.productName;
      this.appellationName = res.appellation.name;
    });
  }
}
