import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EstablishmentModel, UserEstablishmentModel, UserEstablishmentResponseModalModel } from '@app/pages/establishments/establishment.model';

@Component({
    selector: 'app-user-establishment-detail',
    templateUrl: './user-establishment-detail.component.html'
})
export class UserEstablishmentDetailComponent implements OnInit {
    public refForm: FormGroup;
    public responseElt: UserEstablishmentResponseModalModel = new UserEstablishmentResponseModalModel();

    @ViewChild('establishmentInput') establishmentInput: ElementRef<HTMLInputElement>;

    constructor(
        public dialogRef: MatDialogRef<UserEstablishmentDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.responseElt.response = {
            action: '',
            establishment: new EstablishmentModel()
        };
        this.responseElt.element = new UserEstablishmentModel();
    }

    ngOnInit() {
        this.refForm = new FormGroup({
            isOwner: new FormControl(this.data.element.isOwner, []),
        });
        if (this.data.context.action === 'update') {
            this.refForm.addControl(
                'id', new FormControl(this.data.element.id, [])
            );
        }
    }

    public hasError(controlName: string, errorName: string) {
        return this.refForm.controls[controlName].hasError(errorName);
    }

    public cancelModal() {
        this.dialogRef.close({ response: 'elementCanceled' });
    }

    public saveForm() {
        if (this.refForm.valid) {
            const userEstablishment: UserEstablishmentModel = {
                ...this.refForm.value,
                establishment: this.data.context.establishment
            };

            this.responseElt.response = this.data.context;
            this.responseElt.element = userEstablishment;

            this.dialogRef.close(this.responseElt);
        }
    }
}
