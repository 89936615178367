import { Injectable } from '@angular/core';
import { UnitModel } from './unit.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  constructor(private http: HttpClient) { }

  public getUnits(types: string = ''): Observable<UnitModel[]> {
    let params = new HttpParams();
    if (types !== '') {
      params = params.set('order[id]', 'desc').set('types', types);
    } else {
      params = params.set('order[id]', 'desc');
    }
    return this.http.get<UnitModel[]>(`${environment.apiUrls[environment.name]}/units`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public createUnit(unit: UnitModel): Observable<UnitModel> {
    const params: any = {...unit};
    params.parent = (unit.parent) ? '/units/' + unit.parent.id : null;
    return this.http.post<UnitModel>(`${environment.apiUrls[environment.name]}/units`, params);
  }

  public updateUnit(unit: UnitModel): Observable<UnitModel> {
    const params: any = {...unit};
    params.parent = (unit.parent) ? '/units/' + unit.parent.id : null;
    return this.http.put<UnitModel>(`${environment.apiUrls[environment.name]}/units/${unit.id}`, params);
  }

  public deleteUnit(unit: UnitModel): Observable<UnitModel> {
    return this.http.delete<UnitModel>(`${environment.apiUrls[environment.name]}/units/${unit.id}`);
  }
}
