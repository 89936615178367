import { Injectable } from '@angular/core';
import { ReferenceModel } from './reference.model';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { CustomEncoder } from '@app/_helpers';

@Injectable({
  providedIn: 'root'
})
export class ReferencesService {

  constructor(private http: HttpClient) { }

  public getReferencesByProductId(productID: number): Observable<ReferenceModel[]> {
    const params = new HttpParams().set('order[id]', 'desc').set('product', productID.toString());
    return this.http.get<ReferenceModel[]>(`${environment.apiUrls[environment.name]}/references`, {
      params: params
    }).pipe(map(data => data['hydra:member']));
  }

  public getReferencesByCode(code: string, providerId: number, groups: boolean = null): Observable<ReferenceModel[]> {
    let params = new HttpParams({encoder: new CustomEncoder()}).set('order[id]', 'desc').set('code', code).set('provider', providerId.toString()).set('items_per_page', '1');
    if (groups) {
      params = params.append('groups[]', 'invoiceDetail');
    }
    return this.http.get<ReferenceModel[]>(`${environment.apiUrls[environment.name]}/references`, {
      params: params
    }).pipe(map(data => data['hydra:member']));
  }

  public getAllReferences(offset = 0, items_per_page = 10, filterValue = null): Observable<ReferenceModel[]> {
    offset++;
    let params = new HttpParams({encoder: new CustomEncoder()}).set('order[product.appellation.name]', 'asc').set('page', offset.toString()).set('items_per_page', items_per_page.toString());
    if (filterValue) {
      filterValue.forEach(element => {
        params = (element.value) ? params.append(element.type, element.value.toString()) : params;
      });
    }
    return this.http.get<ReferenceModel[]>(`${environment.apiUrls[environment.name]}/references`, { params: params });
  }

  public getListReferences(references: any[]): Observable<any[]> {
    const params = new HttpParams({encoder: new CustomEncoder()}).append('references', JSON.stringify(references));
    return this.http.get<any[]>(`${environment.apiUrls[environment.name]}/list-references`, { params : params });
  }


  public getReference(id): Observable<ReferenceModel> {
    return this.http.get<ReferenceModel>(`${environment.apiUrls[environment.name]}/references/${id}`);
  }

  public createReference(reference: any): Observable<ReferenceModel> {
    return this.http.post<ReferenceModel>(`${environment.apiUrls[environment.name]}/references`, reference);
  }

  public updateReference(reference: any): Observable<ReferenceModel> {
    return this.http.put<ReferenceModel>(`${environment.apiUrls[environment.name]}/references/${reference.id}`, reference);
  }

  public deleteReference(reference: ReferenceModel): Observable<ReferenceModel> {
    return this.http.delete<ReferenceModel>(`${environment.apiUrls[environment.name]}/references/${reference.id}`);
  }
}
