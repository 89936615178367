import { Component, ElementRef, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EstablishmentTypeModel } from '@app/pages/establishment-type/establishment-type.model';
import { EstablishmentTypeService } from '@app/pages/establishment-type/establishment-type.service';
import { SlugifyPipe } from '@app/_helpers/slugify.pipe';
import { LoaderService } from '@app/_services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { EstablishmentDetailComponent } from '../establishment-detail/establishment-detail.component';
import { EstablishmentModel } from '../establishment.model';
import { EstablishmentService } from '../establishment.service';

@Component({
  selector: 'app-establishments-list',
  templateUrl: './establishments-list.component.html',
  styleUrls: ['./establishments-list.component.scss']
})
export class EstablishmentsListComponent implements OnInit {

  displayedColumns: string[] = [
    'id', 'name', 'type', 'address', 'last_file', 'avg_invoices', 'avg_treatment', 'groups', 'state', 'flatRate', 'handledFixesFees', 'isHighPriority', 'comment', 'actions'
  ];
  public establishmentsType: string;
  public establishmentsMatTable = new MatTableDataSource<EstablishmentModel>();
  public pageEvent: PageEvent;
  public loading = true;
  public reload = new EventEmitter();
  public establishmentsTypesList: EstablishmentTypeModel[];
  public commentCoopeo: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('comment', { static: true }) comment: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private loaderService: LoaderService,
    private establishmentService: EstablishmentService,
    private slugifyPipe: SlugifyPipe,
    private translate: TranslateService,
    private establishmentTypeService: EstablishmentTypeService,
  ) { }

  ngOnInit() {
    this.getEstablishmentsList();
    this.establishmentTypeService.getEstablismentTypes().subscribe((data: EstablishmentTypeModel[]) => {
      this.establishmentsTypesList = data;
    });

  }

  getEstablishmentsList() {
    this.loaderService.changeLoader(true);
    this.establishmentService.getEstablishments().subscribe((data: EstablishmentModel[]) => {
      this.establishmentsMatTable = new MatTableDataSource<EstablishmentModel>(data);
      this.initMatTable();
    },
      (error) => { },
      () => {
        this.loaderService.changeLoader(false);
        this.loading = false;
      });
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.establishmentsMatTable.paginator = this.paginator;
    this.establishmentsMatTable.sort = this.sort;
    this.establishmentsMatTable.filterPredicate = (data: any, filter) => {
      let dataStr = '';
      dataStr += data.groups.reduce((accumulator, current) => accumulator + this.slugifyPipe.transform((current !== null) ? current : '', true), '');
      dataStr += this.slugifyPipe.transform(data.name, true);
      dataStr += this.slugifyPipe.transform(data.city, true);
      return dataStr.indexOf(filter) !== -1;
    };
  }

  /**
   * Search in table
   * @param filterValue string to research in array list
   */
  applyFilter(filterValue: string) {
    this.establishmentsMatTable.filter = this.slugifyPipe.transform(filterValue, true);
  }

  /**
 * Switch context between creation/edition feature
 * @param context 'create' or 'update'
 * @param element element obj to edit
 */
  modalElement(context: string, element: EstablishmentModel): void {
    if (context === 'create') {
      this.createEstablishment(context);
    } else if (context === 'update') {
      this.updateEstablishment(context, element);
    }
  }

  /**
   * Display Modal for element's creation
   * @param context 'create' or 'update'
   */
  createEstablishment(context: string) {
    const createApp = this.dialog.open(EstablishmentDetailComponent, {
      width: '600px',
      data: { element: new EstablishmentModel(), context: context, establishmentsTypesList: this.establishmentsTypesList },
      disableClose: true
    });

    // Observable after modal closed
    createApp.afterClosed().subscribe(result => {
      if (result.response === 'create') {
        this.establishmentService.createEstablishment(result.element).subscribe(
          res => {
            this.snackBar.open('Element "' + result.element.name + '" créé.', '', {
              duration: 3000,
              panelClass: ['mat-bg-primary']
            });
            this.establishmentsMatTable.data.unshift(res);
            this.establishmentsMatTable = new MatTableDataSource<EstablishmentModel>(this.establishmentsMatTable.data);
            this.initMatTable();
          }
        );
      }
    });
  }

  /**
   * Display Modal for element's edition
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  updateEstablishment(context: string, element: EstablishmentModel) {
    const updateElt = this.dialog.open(EstablishmentDetailComponent, {
      width: '600px',
      data: { element: element, context: context, establishmentsTypesList: this.establishmentsTypesList },
      disableClose: true
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe(result => {
      if (result.response === 'update') {
        this.establishmentService.updateEstablishment(result.element).subscribe(
          () => {
            const index = this.establishmentsMatTable.data.findIndex(array => array.id === result.element.id);
            this.establishmentsMatTable.data[index].address = result.element.address;
            this.establishmentsMatTable.data[index].city = result.element.city;
            this.establishmentsMatTable.data[index].name = result.element.name;
            this.establishmentsMatTable.data[index].isHighPriority = result.element.isHighPriority;
            const index2 = this.establishmentsTypesList.findIndex(elt => elt.id === result.element.type);
            this.establishmentsMatTable.data[index].type_id = this.establishmentsTypesList[index2].id;
            this.establishmentsMatTable.data[index].type_name = this.establishmentsTypesList[index2].name;
            this.establishmentsMatTable.data[index].type_web_icon = this.establishmentsTypesList[index2].webIcon;
            this.establishmentsMatTable = new MatTableDataSource<EstablishmentModel>(this.establishmentsMatTable.data);
            this.initMatTable();
            this.snackBar.open('Établissement "' + result.element.name + '" modifié.', '', {
              duration: 3000,
              panelClass: ['mat-bg-primary']
            });
          },
          (error) => { },
          () => {
            this.getEstablishmentsList();
          }
        );
      }
    });
  }

  public modalComment(commentCoopeo) {
    this.commentCoopeo = commentCoopeo;
    this.dialog.open(this.comment);
  }

  public closeComment(commentCoopeo) {
    this.dialog.closeAll();
  }

}
