import { Component, OnInit } from '@angular/core';
import { UserModel } from '@app/_models';
import { AuthenticationService } from '@app/_services';
import { UserEstablishmentModel } from '@app/pages/establishments/establishment.model';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { format } from 'date-fns';
import { LoaderService } from '@app/_services/loader.service';

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent implements OnInit {
  public currentUser: UserModel;
  public currentUserEstablishment: UserEstablishmentModel;
  public establishmentsList: UserEstablishmentModel[];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    // User states
    this.authenticationService.currentUser.subscribe(state => {
      this.currentUser = state;
    });
  }

  public logout() {
    this.authenticationService.logout();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  formatDate(date) {
    return format(new Date(date), 'dd/MM/yyyy HH:mm');
  }
}
