import { Injectable } from '@angular/core';
import { ProductsFamilyModel } from './products-family.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductsFamiliesService {

  constructor(private http: HttpClient) { }

  public getProductsFamilies(): Observable<ProductsFamilyModel[]> {
    const params = new HttpParams().set('order[id]', 'desc');
    return this.http.get<ProductsFamilyModel[]>(`${environment.apiUrls[environment.name]}/product_families`, {
      params: params
    }).pipe(map(data => data['hydra:member']));
  }

  public createProductsFamily(productsFamily: ProductsFamilyModel): Observable<ProductsFamilyModel> {
    return this.http.post<ProductsFamilyModel>(`${environment.apiUrls[environment.name]}/product_families`, productsFamily);
  }

  public updateProductsFamily(productsFamily: ProductsFamilyModel): Observable<ProductsFamilyModel> {
    return this.http.put<ProductsFamilyModel>(`${environment.apiUrls[environment.name]}/product_families/${productsFamily.id}`, productsFamily);
  }

  public deleteProductsFamily(productsFamily: ProductsFamilyModel): Observable<ProductsFamilyModel> {
    return this.http.delete<ProductsFamilyModel>(`${environment.apiUrls[environment.name]}/product_families/${productsFamily.id}`);
  }
}
