import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ReferencesTaxeModel } from '../references-taxe.model';
import { ReferencesTaxesService } from '../references-taxes.service';
import { ReferencesTaxeDetailComponent } from '../references-taxe-detail/references-taxe-detail.component';
import { SlugifyPipe } from '@app/_helpers/slugify.pipe';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { LoaderService } from '@app/_services/loader.service';
import { merge, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-references-taxes-list',
  templateUrl: './references-taxes-list.component.html',
  styleUrls: ['./references-taxes-list.component.scss']
})
export class ReferencesTaxesListComponent implements OnInit {
  public reload = new EventEmitter();
  public displayedColumns: string[] = ['id', 'code', 'provider', 'taxe', 'actions'];
  public elementsList: ReferencesTaxeModel[];
  public elementType: string;
  public elementsMatTable = new MatTableDataSource<ReferencesTaxeModel>();
  public loading = true;
  resultsLength = Number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  codeSearchInput = new FormControl('');
  providerSearchInput = new FormControl('');
  taxeSearchInput = new FormControl('');

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private elementService: ReferencesTaxesService,
    private slugifyPipe: SlugifyPipe,
    private loaderService: LoaderService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.loaderService.changeLoader(true);
    this.getStats();

    this.codeSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.providerSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.taxeSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
  }

  /**
   * Get datas from API and formatte them in the chart
   */
  public getStats() {
    merge(this.paginator.page, this.reload)
      .pipe(
        startWith(() => { }),
        switchMap(() => {
          const filterValue = [
            { 'type': 'code', 'value': this.codeSearchInput.value },
            { 'type': 'provider.name', 'value': this.providerSearchInput.value },
            { 'type': 'taxe.name', 'value': this.taxeSearchInput.value }
          ];
          this.loaderService.changeLoader(true);
          return this.elementService.getReferencesTaxes(this.paginator.pageIndex, this.paginator.pageSize, filterValue);
        }),
        map(data => {
          this.loaderService.changeLoader(false);
          this.loading = false;
          this.resultsLength = data['hydra:totalItems'];

          return data;
        }),
        catchError(() => {
          this.loaderService.changeLoader(false);
          this.loading = false;
          return new Observable();
        })
      ).subscribe(data => {
        this.loaderService.changeLoader(false);
        this.loading = false;
        this.elementsMatTable = new MatTableDataSource<ReferencesTaxeModel>(data['hydra:member']);
      }
      );
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Search in table
   * @param filterValue string to research in array list
   */
  applyFilter(filterValue: string) {
    this.elementsMatTable.filter = this.slugifyPipe.transform(filterValue, true);
  }

  /**
   * Switch context between creation/edition feature
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  modalElement(context: string, element: ReferencesTaxeModel): void {
    if (context === 'create') {
      this.createElement(context);
    } else if (context === 'update') {
      this.updateElement(context, element);
    }
  }

  /**
   * Display Modal for element's creation
   * @param context 'create' or 'update'
   */
  createElement(context: string) {
    const createApp = this.dialog.open(ReferencesTaxeDetailComponent, {
      width: '600px',
      data: { element: new ReferencesTaxeModel(), context: context, elementType: this.elementType },
      disableClose: true
    });

    // Observable after modal closed
    createApp.afterClosed().subscribe(result => {
      if (result.response === 'create') {
        const match = this.elementsMatTable.data.find(i => i.code === result.element.code && i.provider.id === result.element.provider.id && i.taxe.id === result.element.taxe.id);
        if (match === undefined) {
          this.elementService.createReferencesTaxe(result.element).subscribe(
            res => {
              this.snackBar.open('Element "' + result.element.code + '" créé.', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });
              this.elementsMatTable.data.unshift(res);
              this.elementsMatTable = new MatTableDataSource<ReferencesTaxeModel>(this.elementsMatTable.data);
              this.initMatTable();
            }
          );
        } else {
          this.translate.get('global.actions.duplicateWith').subscribe((res: string) => {
            this.snackBar.open(res + ' "' + result.element.code + '".', '', {
              duration: 3000,
              panelClass: ['mat-bg-warn']
            });
          });
        }
      }
    });
  }

  /**
   * Display Modal for element's edition
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  updateElement(context: string, element: ReferencesTaxeModel) {
    const updateElt = this.dialog.open(ReferencesTaxeDetailComponent, {
      width: '600px',
      data: { element: element, context: context },
      disableClose: true
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe(result => {
      if (result.response === 'update') {
        this.elementService.updateReferencesTaxe(result.element).subscribe(
          res => {
            const refList = this.elementsMatTable.data;
            const index = refList.findIndex(array => array.id === result.element.id);
            refList[index] = res;
            this.elementsMatTable = new MatTableDataSource<ReferencesTaxeModel>(refList);
            this.initMatTable();
            this.snackBar.open('Element "' + result.element.code + '" modifié.', '', {
              duration: 3000,
              panelClass: ['mat-bg-primary']
            });
          }
        );
      }
    });
  }

  /**
   * Delete element
   * @param element Element to remove
   */
  public deleteElement(element) {
    if (window.confirm('Confirmer la suppression de la reference "' + element.reference + '" ?')) {
      this.elementService.deleteReferencesTaxe(element).subscribe(
        res => {
          const refList = this.elementsMatTable.data;
          const index = refList.findIndex(array => array.id === element.id);
          refList.splice(index, 1);
          this.elementsMatTable = new MatTableDataSource<ReferencesTaxeModel>(refList);
          this.initMatTable();
          this.snackBar.open('Element "' + element.reference + '" supprimé.', '', {
            duration: 3000,
            panelClass: ['mat-bg-primary']
          });
        }
      );
    }
  }
}
