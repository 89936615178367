import { Component, OnInit } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ConfirmationDialogComponent } from '@app/_components/confirmation-dialog';
import { LoaderService } from '@app/_services/loader.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImportScanService } from './import-scan.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-import-scan',
  templateUrl: './import-scan.component.html',
  styleUrls: ['./import-scan.component.scss']
})
export class ImportScanComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  public dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    public snackBar: MatSnackBar,
    public ImportScanService: ImportScanService,
    public dialog: MatDialog,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.loaderService.changeLoader(false);
  }


  /**
   * File upload
   * @param event Event
   */
  public dropped(event: NgxFileDropEntry[]) {
    this.files = event;
    if (this.files.length > 1) {
      this.snackBar.open('Merci de charger un seul document à la fois', '', { duration: 3000, panelClass: ['mat-bg-warn'] });
      return;
    }
    for (const { index, droppedFile } of this.files.map((droppedFile, index) => ({ index, droppedFile }))) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (!fileEntry.isFile) {
            this.snackBar.open('Format incorrect: le fichier doit être un .jpeg, .png ou pdf', '', { duration: 3000, panelClass: ['mat-bg-warn'] });
            return;
          } else {
            // Active loader during the initialisation of front
            this.loaderService.changeLoader(true);
            this.ImportScanService.analyze(file).subscribe((res) => {
              this.loaderService.changeLoader(false);

              this.snackBar.open('Import validé', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });
            }, (err: HttpErrorResponse) => {
              this.loaderService.changeLoader(false);

              this.snackBar.open('L\'import a échoué', '', {
                duration: 3000,
                panelClass: ['mat-bg-warn']
              });
            })
          }
        });
      }
    }
  }
}
