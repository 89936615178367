export class AttributeModel {
    id: number;
    name: string;
    type: number;
    duplicate: boolean;

    constructor() {}
}

export class AttributeValueModel {
    id: number;
    attribute: string;
    attributeName: string;
    attributeType: string;
    customValue: string;
    value: {
        id: number;
        name: string;
        attribute: string;
        attributeType: string;
        customValue: string;
        textValue: string;
        unit: string;
        value: string;
    };

    constructor() {}
}


export class ResponseModalModel {
    response: string;
    element: AttributeModel;

    constructor() {
        this.element = new AttributeModel();
        this.response = '';
    }
}

export const TYPES: any[] = [
    { id: 0, label: 'TYPE_TEXT'},
    { id: 1, label: 'TYPE_LIST'},
    { id: 2, label: 'TYPE_TOGGLE'},
];
