export class ScopeModel {
    id: number;
    code: string;
    name: string;
    description: string;
    parent: ScopeModel;
    parentId: number;
    depending: ScopeModel;
    dependingId: number;
    fullScopeName: string;

    constructor() {
        this.name = '';
    }
}

export class ResponseModalModel {
    response: string;
    element: ScopeModel;

    constructor() {
        this.element = new ScopeModel();
        this.response = '';
    }
}
