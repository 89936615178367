import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReferenceDetailComponent } from '../references-detail/reference-detail.component';
import { ReferenceModel } from '../reference.model';
import { ReferencesService } from '../references.service';
import { AppellationModel } from '../../appellations/appellation.model';
import { AppellationService } from '../../appellations/appellation.service';
import { ProductModel } from '@app/pages/products/product.model';
import { ProductService } from '@app/pages/products/product.service';
import { SlugifyPipe } from '@app/_helpers/slugify.pipe';
import { TranslateService } from '@ngx-translate/core';

import { BrandModel } from '@app/pages/brands/brand.model';
import { ProductsFamiliesService } from '@app/pages/products-families/products-families.service';
import { ProductsFamilyModel } from '@app/pages/products-families/products-family.model';
import { ProviderModel } from '@app/pages/providers/provider.model';
import { UnitModel } from '@app/pages/units/unit.model';
import { UnitsService } from '@app/pages/units/units.service';
import { TaxeModel } from '@app/pages/taxes/taxe.model';
import { TaxesService } from '@app/pages/taxes/taxes.service';
import { forkJoin, Observable, pipe } from 'rxjs';
import { LoaderService } from '@app/_services/loader.service';
import { SanitizeIdPipe } from '@app/_helpers/sanitize-id.pipe';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-references-list',
  templateUrl: './references-list.component.html',
  styleUrls: ['./references-list.component.scss']
})
export class ReferencesListComponent implements OnInit {
  displayedColumns: string[] = [
    'code',
    'productsFamily',
    'brand',
    'provider',
    'unit',
    'equivalence',
    'invoiceRatio',
    'packaging',
    'isUncertain',
    'actions'
  ];
  parentAppellationID: number;
  parentAppellation: AppellationModel = new AppellationModel;
  parentProductID: number;
  parentProduct: ProductModel = new ProductModel;
  referencesList: ReferenceModel[];
  referencesMatTable;
  public loading = true;

  public brandsList: BrandModel[];
  public filteredBrandsList: Observable<BrandModel[]>;
  public taxesList: TaxeModel[];
  public productsFamiliesList: ProductsFamilyModel[];
  public providersList: ProviderModel[];
  public filteredProvidersList: Observable<ProviderModel[]>;
  public unitsListConditionnement: UnitModel[];
  public unitsListColisage: UnitModel[];
  public unitsListEquivalence: UnitModel[];
  public appellationsList: AppellationModel[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private appellationsService: AppellationService,
    private productsService: ProductService,
    private referencesService: ReferencesService,
    private slugifyPipe: SlugifyPipe,
    private translate: TranslateService,

    private taxesService: TaxesService,
    private productsFamiliesService: ProductsFamiliesService,
    private unitsService: UnitsService,
    private loaderService: LoaderService,
    private sanitizeIdPipe: SanitizeIdPipe
  ) {
    this.parentAppellationID = +this.route.snapshot.paramMap.get('appellation-id');
    this.parentProductID = +this.route.snapshot.paramMap.get('product-id');
  }

  ngOnInit() {
    this.referencesMatTable = new MatTableDataSource<ReferenceModel>();
    this.initMatTable();

    this.appellationsService.getAppellation(this.parentAppellationID).then(res => { this.parentAppellation = res; });
    this.productsService.getProduct(this.parentProductID).then(res => { this.parentProduct = res; });

    this.loaderService.changeLoader(true);
    forkJoin(
      this.taxesService.getTaxes(),
      this.productsFamiliesService.getProductsFamilies(),
      this.unitsService.getUnits(),
      this.referencesService.getReferencesByProductId(this.parentProductID)
    ).subscribe(
      results => {
        this.taxesList = results[0];
        this.productsFamiliesList = results[1];
        this.unitsListConditionnement = results[2].filter(unit => (unit.types) ? unit.types.includes(0) : false);
        this.unitsListEquivalence = results[2].filter(unit => (unit.types) ? unit.types.includes(1) : false);
        this.unitsListColisage = results[2].filter(unit => (unit.types) ? unit.types.includes(2) : false);
        this.referencesList = results[3];
      },
      (err) => { },
      () => {
        this.loading = false;
        this.referencesMatTable = new MatTableDataSource<ReferenceModel>(this.referencesList);
        this.initMatTable();
        this.loaderService.changeLoader(false);
      },
    );
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.referencesMatTable.filterPredicate = (data: ReferenceModel, filter) => {
      let dataStr = ((data.code !== null) ? data.code : '').trim().toLowerCase();
      dataStr += data.id;
      dataStr += ((data.provider !== null) ? data.provider.name : '').trim().toLowerCase();
      dataStr += ((data.brand !== null) ? data.brand.name : '').trim().toLowerCase();
      dataStr += ((data.packagingUnit !== null) ? data.packagingUnit.name : '').trim().toLowerCase();
      dataStr += ((data.productsFamily !== null) ? data.productsFamily.name : '').trim().toLowerCase();
      dataStr += ((data.unit !== null) ? data.unit.name : '').trim().toLowerCase();
      dataStr.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      return dataStr.indexOf(filter) !== -1;
    };
    this.referencesMatTable.paginator = this.paginator;
    this.referencesMatTable.sort = this.sort;
  }

  /**
   * Search in table
   * @param filterValue string to research in array list
   */
  applyFilter(filterValue: string) {
    this.referencesMatTable.filter = this.slugifyPipe.transform(filterValue, true);
  }

  /**
   * Switch context between creation/edition feature
   * @param context 'create' or 'update'
   * @param product product obj to edit
   */
  modalReference(context: string, reference: ReferenceModel): void {
    if (context === 'create') {
      this.createReference(context);
    } else if (context === 'update') {
      this.updateReference(context, reference);
    }
  }

  /**
   * Display Modal for reference's creation
   * @param context 'create' or 'update'
   */
  createReference(context: string) {
    const createApp = this.dialog.open(ReferenceDetailComponent, {
      width: '1000px',
      data: {
        reference: new ReferenceModel(),
        context: context,
        product: this.parentProductID,
        taxesList: this.taxesList.sort((a, b) => a.name.localeCompare(b.name)),
        productsFamiliesList: this.productsFamiliesList.sort((a, b) => a.name.localeCompare(b.name)),
        unitsListConditionnement: this.unitsListConditionnement.sort((a, b) => a.name.localeCompare(b.name)),
        unitsListColisage: this.unitsListColisage.sort((a, b) => a.name.localeCompare(b.name)),
        unitsListEquivalence: this.unitsListEquivalence.sort((a, b) => a.name.localeCompare(b.name))
      },
      disableClose: true
    });

    // Observable after modal closed
    createApp.afterClosed().subscribe(result => {
      if (result.response === 'create') {
        const match = this.referencesList.find(i => (i.code === result.reference.code && i.provider.id === this.sanitizeIdPipe.transform(result.reference.provider)));
        if (match === undefined) {
          this.referencesService.createReference(result.reference).subscribe(
            res => {
              this.referencesList.unshift(res);
              this.referencesMatTable = new MatTableDataSource<ReferenceModel>(this.referencesList);
              this.initMatTable();
              this.snackBar.open('Référence fournisseur "' + res.code + '" créé.', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });
            }
          );
        } else {
          this.translate.get('global.actions.duplicateWith').subscribe((res: string) => {
            this.snackBar.open(res + ' "' + result.reference.code + '".', '', {
              duration: 3000,
              panelClass: ['mat-bg-warn']
            });
          });
        }
      }
    });
  }

  /**
   * Display Modal for reference's edition
   * @param context 'create' or 'update'
   * @param reference reference obj to edit
   */
  updateReference(context: string, reference: ReferenceModel) {
    const updateElt = this.dialog.open(ReferenceDetailComponent, {
      width: '1000px',
      data: {
        reference: reference,
        context: context,
        product: this.parentProductID,
        taxesList: this.taxesList.sort((a, b) => a.name.localeCompare(b.name)),
        productsFamiliesList: this.productsFamiliesList.sort((a, b) => a.name.localeCompare(b.name)),
        unitsListConditionnement: this.unitsListConditionnement.sort((a, b) => a.name.localeCompare(b.name)),
        unitsListColisage: this.unitsListColisage.sort((a, b) => a.name.localeCompare(b.name)),
        unitsListEquivalence: this.unitsListEquivalence.sort((a, b) => a.name.localeCompare(b.name))
      },
      disableClose: true
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe(result => {
      if (result.response === 'update') {
        this.referencesService.updateReference(result.reference).subscribe(
          res => {
            const index = this.referencesList.findIndex(array => array.id === res.id);
            this.referencesList[index] = res;
            this.referencesMatTable = new MatTableDataSource<ReferenceModel>(this.referencesList);
            this.initMatTable();
            this.snackBar.open('Référence "' + res.code + '" modifié.', '', {
              duration: 3000,
              panelClass: ['mat-bg-primary']
            });
          }
        );
      }
    });
  }

  /**
   * Delete reference
   * @param reference Reference to remove
   */
  public deleteReference(reference: ReferenceModel) {
    if (window.confirm('Confirmer la suppression de la référence "' + this.parentAppellation.name + '" ?')) {
      this.referencesService.deleteReference(reference).subscribe(
        (res) => {
          const appList = this.referencesList;
          const index = appList.findIndex(array => array.id === reference.id);
          appList.splice(index, 1);
          this.referencesMatTable = new MatTableDataSource<ReferenceModel>(appList);
          this.initMatTable();
          this.snackBar.open('Référence "' + reference.code + '" supprimé.', '', {
            duration: 3000,
            panelClass: ['mat-bg-primary']
          });
        }
      );
    }
  }
}
