import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { AnalyticService } from '@app/pages/dashboard/dashboard.service';
import { Utils } from '@app/_helpers';

@Component({
  selector: 'app-top10',
  templateUrl: './top10.component.html',
  styleUrls: ['./top10.component.scss']
})
export class Top10Component implements OnInit, OnDestroy {
  @Input() events: Observable<any>;

  private eventsSubscription: Subscription;

  public top5: any = { 'list': {} };

  constructor(private analyticService: AnalyticService, public utils: Utils) { }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe(res => {
      this.getStats(res.filters);
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  /**
   * Get datas from API and formatte them in the chart
   */
  public getStats(filters: any) {
    const dateStart = moment(filters.dateStart.value.toDate()).format('YYYY-MM-DD');
    const dateEnd = moment(filters.dateEnd.value.toDate()).format('YYYY-MM-DD');
    this.analyticService.getTop5(dateStart, dateEnd).subscribe(top5 => this.top5 = top5);
  }

}
