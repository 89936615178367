import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private sourceLoaderState = new BehaviorSubject(false);
  private sourceCheckState = new BehaviorSubject(false);
  currentLoaderState = this.sourceLoaderState.asObservable();
  currentCheckState = this.sourceCheckState.asObservable();

  constructor() { }

  changeLoader(state: boolean) {
    this.sourceLoaderState.next(state);
  }
  changeCheck(state: boolean) {
    this.sourceCheckState.next(state);
  }
}
