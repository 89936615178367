export class EstablishmentTypeModel {
    id: number;
    name: string;
    area: any[];
    webIcon: string;

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: EstablishmentTypeModel;

    constructor() {
        this.element = new EstablishmentTypeModel();
        this.response = '';
    }
}
