import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { EstablishmentModel } from './establishment.model';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentService {

  constructor(private http: HttpClient) { }

  public getEstablishments(offset = 0, items_per_page = 50, filterValue = null): Observable<EstablishmentModel[]> {
    offset++;
    let params = new HttpParams();
    params = params.set('order[id]', 'desc').set('items_per_page', items_per_page.toString());
    if (filterValue) {
      params = params.set('name', filterValue);
    }

    return this.http.get<EstablishmentModel[]>(`${environment.apiUrls[environment.name]}/admin/establishments`, { params: params });
  }

  public getEstablishmentsList(list = false, itemPerPage: number = null): Observable<EstablishmentModel[]> {
    let params = new HttpParams();
    params = params.set('order[id]', 'desc');
    if (list) {
      params = params.append('groups[]', 'list');
    }
    if (itemPerPage !== null) {
      params = params.append('items_per_page', itemPerPage.toString());
    }

    return this.http.get<EstablishmentModel[]>(`${environment.apiUrls[environment.name]}/establishments`, { params: params }).pipe(map(data => data['hydra:member']));
  }

  public createEstablishment(establishment: EstablishmentModel): Observable<EstablishmentModel> {
    return this.http.post<EstablishmentModel>(`${environment.apiUrls[environment.name]}/establishments`, establishment);
  }

  public updateEstablishment(establishment: EstablishmentModel): Observable<EstablishmentModel> {
    return this.http.put<EstablishmentModel>(`${environment.apiUrls[environment.name]}/establishments/${establishment.id}`, establishment);
  }

  public deleteEstablishment(establishment: EstablishmentModel): Observable<EstablishmentModel> {
    return this.http.delete<EstablishmentModel>(`${environment.apiUrls[environment.name]}/establishments/${establishment.id}`);
  }

}
