import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoicesService } from '../invoices.service';
import { InvoiceModel, InvoiceRowModel } from '../invoice.model';
import { ProvidersService } from '@app/pages/providers/providers.service';
import { EstablishmentService } from '@app/pages/establishments/establishment.service';
import { EstablishmentModel } from '@app/pages/establishments/establishment.model';
import { ProviderModel } from '@app/pages/providers/provider.model';
import { ReferencesFeeModel } from '@app/pages/references-fees/references-fee.model';
import { ReferencesTaxeModel } from '@app/pages/references-taxes/references-taxe.model';
import { ReferenceModel } from '@app/pages/references/reference.model';
import { ReferencesService } from '@app/pages/references/references.service';
import { isEqual, parseISO } from 'date-fns';
import { FeesService } from '@app/pages/fees/fees.service';
import { FeeModel, FeeValueModel } from '@app/pages/fees/fee.model';
import * as moment from 'moment'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {
  public establishments: EstablishmentModel[] = [];
  public providers: ProviderModel[] = [];
  public invoiceId: Number;
  public globalDiscount: number;
  public invoiceDatas: InvoiceModel = null;
  public listfees: FeeModel[];

  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoicesService,
    private router: Router,
    private establishmentService: EstablishmentService,
    private providerService: ProvidersService,
    public feeService: FeesService,
    private referenceServices: ReferencesService,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.establishmentService.getEstablishments().subscribe(establishments => {
      this.establishments = establishments;
    })
    this.providerService.getProviders().subscribe(providers => {
      this.providers = providers;
    })
    this.feeService.getfees().subscribe(fees => {
      this.listfees = fees;
    })
    this.invoiceId = +this.route.snapshot.paramMap.get('invoice-id');
    this.invoiceService.getInvoice(this.invoiceId).subscribe(invoice => {
      this.invoiceDatas = invoice;
      this.invoiceDatas.invoiceRows.forEach((invoiceRow: InvoiceRowModel) => {
        invoiceRow.discount = 0;
      })
    })
  }

  public isEqual(dateA, dateB) { return isEqual(parseISO(dateA), parseISO(dateB)); }

  public getTotalHTReference(invoiceRow: InvoiceRowModel) {
    let totalHT, returnVal = 0;

    totalHT = invoiceRow.quantity * (invoiceRow.preTaxPrice + invoiceRow.preTaxTaxes) * (1 - (invoiceRow.discount / 100));
    returnVal = Math.round(totalHT * 100) / 100;
    return returnVal;
  }

  public applyDiscount() {
    this.invoiceDatas.invoiceRows.forEach((invoiceRow, index) => {
      this.invoiceDatas.invoiceRows[index].discount = (this.getTypeReference(invoiceRow.reference) === 0) ? this.globalDiscount : 0;
    });
    this.refreshTotalInvoice();
  }

  public refreshTotalInvoice() {
    let totalHT = 0;
    let totalTTC = 0;
    this.invoiceDatas.invoiceRows.forEach((invoiceRow: InvoiceRowModel) => {
      totalHT += invoiceRow.quantity * (invoiceRow.preTaxPrice + invoiceRow.preTaxTaxes) * (1 - (invoiceRow.discount / 100));
      totalTTC += invoiceRow.quantity * (invoiceRow.preTaxPrice + invoiceRow.preTaxTaxes) * (1 + invoiceRow.vat / 100) * (1 - (invoiceRow.discount / 100));
    });
    this.invoiceDatas.feesValues.forEach((feeValue: FeeValueModel) => {
      totalHT += feeValue.value;
      totalTTC += feeValue.value * (1 + feeValue.vat / 100);
    });
    this.invoiceDatas.preTaxCoopeo = totalHT;
    this.invoiceDatas.vatIncludedCoopeo = totalTTC;
  }

  public getTypeReference(obj: ReferenceModel | ReferencesTaxeModel | ReferencesFeeModel) {
    if (obj instanceof ReferencesTaxeModel) {
      return 1;
    } else if (obj instanceof ReferencesFeeModel) {
      return 2;
    } else {
      return 0;
    }
  }

  /**
   * Remove reference from invoice
   * @param refIndex Index of reference on the csvData
   */
  public removeLine(elementIndex) {
    this.invoiceDatas.invoiceRows.splice(elementIndex, 1);
  }

  public getReference(invoiceRow: InvoiceRowModel) {
    this.referenceServices.getReferencesByCode(invoiceRow.reference.code, this.invoiceDatas.providerId, true).subscribe(newReference => {
      if (newReference.length > 0 && invoiceRow.reference.code === newReference[0].code) {
        invoiceRow.reference = newReference[0];
        invoiceRow.status = true;
      } else {
        invoiceRow.reference.appellationName = null;
        invoiceRow.reference.brandName = null;
        invoiceRow.status = false;
      }
    });
  }

  public validForm() {
    let response = true;
    this.invoiceDatas.invoiceRows.forEach(invoiceRow => {
      if (invoiceRow.status === false) {
        response = false;
      }
    });
    return response;
  }

  public persist() {
    this.invoiceDatas.date = moment(this.invoiceDatas.date).format("YYYY-MM-DD");
    this.invoiceDatas.invoiceRows.forEach(element => {
      element.dateDelivery = moment(element.dateDelivery).format("YYYY-MM-DD");
    });
    this.invoiceService.updateInvoice(this.invoiceDatas).subscribe(res => {
      if (res) {
        this.router.navigate(['/invoices']);
      }
    });
  }

  /**
   * Call to the service and open file in new tab
   * @param file file consultation
   */
  public openFile(file) {
    this.invoiceService.openFile(file);
  }

}
