import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EstablishmentService } from '@app/pages/establishments/establishment.service';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, startWith } from 'rxjs';
import { InvoiceFileModel, ResponseModalModel } from '../invoice-file.model';

@Component({
  selector: 'app-invoice-file-detail',
  templateUrl: './invoice-file-detail.component.html'
})
export class InvoiceFileDetailComponent implements OnInit {
  public invoiceForm: FormGroup;
  public responseElt: ResponseModalModel = new ResponseModalModel();
  public establishments: any[] = [];
  public filteredEstablishments: Observable<string[]>;

  constructor(
    public dialogRef: MatDialogRef<InvoiceFileDetailComponent>,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private establishmentService: EstablishmentService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.responseElt.response = '';
    this.responseElt.element = new InvoiceFileModel();
  }

  ngOnInit() {
    this.invoiceForm = new FormGroup({
      id: new FormControl(this.data.element.id, [Validators.required]),
      numberOfInvoices: new FormControl(this.data.element.numberOfInvoices, [Validators.required]),
      coopeoNoteType: new FormControl(this.data.element.coopeoNoteType, []),
      coopeoNote: new FormControl(this.data.element.coopeoNote, []),
      state: new FormControl(this.data.element.state, [Validators.required]),
      establishment: new FormControl(this.data.element.establishment.name, [Validators.required]),
    });
    this.invoiceForm.get('state').valueChanges.subscribe(res => {
      if (res === 3) {
        this.invoiceForm.controls['coopeoNoteType'].setValidators([Validators.required]);
        this.invoiceForm.controls['coopeoNote'].setValidators([Validators.required]);
      } else {
        this.invoiceForm.controls['coopeoNoteType'].clearValidators();
        this.invoiceForm.controls['coopeoNote'].clearValidators();
      }
    });
    this.invoiceForm.get('coopeoNoteType').valueChanges.subscribe(val => {
      this.translate.get('invoices-files.comment-type-detail.thanks').subscribe((thanks: string) => {
        this.translate.get('invoices-files.comment-type-detail.' + val).subscribe((res: string) => {
          this.invoiceForm.get('coopeoNote').setValue(res + thanks);
        });
      });
    });
    this.establishmentService.getEstablishments().subscribe(establishments => {
      this.establishments = establishments;
    },
      (error => { }),
      () => {
        this.filteredEstablishments = this.invoiceForm.get('establishment').valueChanges.pipe(
          startWith(this.invoiceForm.get('establishment').value),
          map(value => { return this._filter(value || ''); }
          ));
      })
  }

  private _filter(value: any): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      return this.establishments.filter(establishment => establishment.name.toLowerCase().includes(filterValue));
    }
    if (typeof value === 'number') {
      return this.establishments.filter(establishment => establishment.id === value);
    }

    return this.establishments;
  }

  public displayFn(establishment?): string | undefined {
    return establishment ? establishment : undefined;
  }

  public hasError(controlName: string, errorName: string) {
    return this.invoiceForm.controls[controlName].hasError(errorName);
  }

  public isFileInError() {
    return this.invoiceForm.get('state').value === 3;
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.invoiceForm.valid) {
      const chosenEstablishmentId = this.establishments.find(establishment => establishment.name.toLowerCase() === this.invoiceForm.get('establishment').value.toLowerCase()).id;
      this.invoiceForm.get('establishment').setValue(chosenEstablishmentId);
      this.responseElt.element = this.invoiceForm.value;
      this.responseElt.response = 'update';
      this.dialogRef.close(this.responseElt);
    }
  }

}
