import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ReferenceDetailComponent } from '@app/pages/references/references-detail/reference-detail.component';
import { ReferenceModel } from '@app/pages/references/reference.model';
import { ReferencesService } from '@app/pages/references/references.service';
import { CategoriesService } from '@app/pages/categories/categories.service';
import { CategoryModel } from '@app/pages/categories/category.model';

import { BrandModel } from '@app/pages/brands/brand.model';
import { ProductsFamiliesService } from '@app/pages/products-families/products-families.service';
import { ProductsFamilyModel } from '@app/pages/products-families/products-family.model';
import { ProviderModel } from '@app/pages/providers/provider.model';
import { UnitModel } from '@app/pages/units/unit.model';
import { UnitsService } from '@app/pages/units/units.service';
import { TaxeModel } from '@app/pages/taxes/taxe.model';
import { TaxesService } from '@app/pages/taxes/taxes.service';
import { forkJoin, Observable, merge } from 'rxjs';
import { LoaderService } from '@app/_services/loader.service';
import { startWith, switchMap, map, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { AppellationService } from '../appellations/appellation.service';
import { AppellationModel } from '../appellations/appellation.model';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-all-references',
  templateUrl: './all-references.component.html',
  styleUrls: ['./all-references.component.scss']
})
export class AllReferencesComponent implements OnInit {
  displayedColumns: string[] = [
    'designation',
    'code',
    'provider',
    'brand',
    'productsFamily',
    'unit',
    'equivalence',
    'colisage',
    'invoiceRatio',
    'isUncertain',
    'actions'
  ];
  referencesList: ReferenceModel[];
  categoriesList: CategoryModel[];
  referencesMatTable;
  public resultsLength;
  public loading = true;
  public reload = new EventEmitter();

  public brandsList: BrandModel[];
  public filteredBrandsList: Observable<BrandModel[]>;
  public taxesList: TaxeModel[];
  public productsFamiliesList: ProductsFamilyModel[];
  public providersList: ProviderModel[];
  public filteredProvidersList: Observable<ProviderModel[]>;
  public unitsListConditionnement: UnitModel[];
  public unitsListColisage: UnitModel[];
  public unitsListEquivalence: UnitModel[];

  public designationSearchInput = new FormControl('');
  public codeSearchInput = new FormControl('');
  public productsFamilySearchInput = new FormControl('');
  public brandSearchInput = new FormControl('');
  public providerSearchInput = new FormControl('');

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private referencesService: ReferencesService,
    private categoriesService: CategoriesService,
    private taxesService: TaxesService,
    private productsFamiliesService: ProductsFamiliesService,
    private unitsService: UnitsService,
    private loaderService: LoaderService,
    private router: Router
  ) { }

  ngOnInit() {
    this.referencesMatTable = new MatTableDataSource<ReferenceModel>();
    this.initMatTable();

    this.designationSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.codeSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.productsFamilySearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.brandSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.providerSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });

    this.loaderService.changeLoader(true);

    // Get references
    this.getReferencesList();

    // Get datas connected to references
    forkJoin(
      this.taxesService.getTaxes(),
      this.productsFamiliesService.getProductsFamilies(),
      this.unitsService.getUnits(),
      this.categoriesService.getCategories(true),
    ).subscribe(
      results => {
        this.taxesList = results[0];
        this.productsFamiliesList = results[1];
        this.unitsListConditionnement = results[2].filter(unit => (unit.types) ? unit.types.includes(0) : false);
        this.unitsListEquivalence = results[2].filter(unit => (unit.types) ? unit.types.includes(1) : false);
        this.unitsListColisage = results[2].filter(unit => (unit.types) ? unit.types.includes(2) : false);
        this.categoriesList = results[3];
      },
      () => {
      },
      () => {
        this.loading = false;
        this.loaderService.changeLoader(false);
      },
    );
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.referencesMatTable.paginator = this.paginator;
    this.referencesMatTable.sort = this.sort;
  }

  /**
   * Search in table
   * @param filterValue string to research in array list
   */
  applyFilter(filterValue: string) {
    this.getReferencesList(filterValue);
  }

  getReferencesList(filterValue = null) {
    merge(this.reload, this.paginator.page)
      .pipe(
        startWith(() => { }),
        switchMap(() => {
          this.loaderService.changeLoader(true);
          filterValue = [
            { 'type': 'product.appellation.name', 'value': this.designationSearchInput.value },
            { 'type': 'code', 'value': this.codeSearchInput.value },
            { 'type': 'productsFamily.name', 'value': this.productsFamilySearchInput.value },
            { 'type': 'brand.name', 'value': this.brandSearchInput.value },
            { 'type': 'provider.name', 'value': this.providerSearchInput.value }
          ];
          return this.referencesService.getAllReferences(this.paginator.pageIndex, this.paginator.pageSize, filterValue);
        }),
        map(data => {
          this.loaderService.changeLoader(false);
          this.loading = false;
          this.resultsLength = data['hydra:totalItems'];

          return data;
        }),
        catchError(() => {
          this.loaderService.changeLoader(false);
          this.loading = false;
          return new Observable();
        })
      ).subscribe(data => {
        this.loaderService.changeLoader(false);
        this.loading = false;
        this.referencesList = data['hydra:member'];

        this.referencesMatTable = new MatTableDataSource<ReferenceModel>(this.referencesList);
      }
      );
  }

  /**
   * Switch context between creation/edition feature
   * @param context 'create' or 'update'
   * @param product product obj to edit
   */
  modalReference(context: string, reference: ReferenceModel): void {
    if (context === 'update') {
      this.updateReference(context, reference);
    }
  }

  /**
   * Display Modal for reference's edition
   * @param context 'create' or 'update'
   * @param reference reference obj to edit
   */
  updateReference(context: string, reference: ReferenceModel) {
    const updateElt = this.dialog.open(ReferenceDetailComponent, {
      width: '1000px',
      data: {
        reference: reference,
        context: context,
        product: reference.product.id,
        taxesList: this.taxesList.sort((a, b) => a.name.localeCompare(b.name)),
        productsFamiliesList: this.productsFamiliesList.sort((a, b) => a.name.localeCompare(b.name)),
        unitsListConditionnement: this.unitsListConditionnement.sort((a, b) => a.name.localeCompare(b.name)),
        unitsListColisage: this.unitsListColisage.sort((a, b) => a.name.localeCompare(b.name)),
        unitsListEquivalence: this.unitsListEquivalence.sort((a, b) => a.name.localeCompare(b.name))
      },
      disableClose: true
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe(result => {
      if (result.response === 'update') {
        this.referencesService.updateReference(result.reference).subscribe(
          res => {
            const index = this.referencesList.findIndex(array => array.id === res.id);
            this.referencesList[index] = res;
            this.referencesMatTable = new MatTableDataSource<ReferenceModel>(this.referencesList);
            this.initMatTable();
            this.snackBar.open('Référence "' + res.code + '" modifié.', '', {
              duration: 3000,
              panelClass: ['mat-bg-primary']
            });
          }
        );
      }
    });
  }

  /**
   * Delete reference
   * @param reference Reference to remove
   */
  public deleteReference(reference: ReferenceModel) {
    if (window.confirm('Confirmer la suppression de la référence "' + reference.code + '" ?')) {
      this.referencesService.deleteReference(reference).subscribe(
        () => {
          const appList = this.referencesList;
          const index = appList.findIndex(array => array.id === reference.id);
          appList.splice(index, 1);
          this.referencesMatTable = new MatTableDataSource<ReferenceModel>(appList);
          this.initMatTable();
          this.snackBar.open('Référence "' + reference.code + '" supprimé.', '', {
            duration: 3000,
            panelClass: ['mat-bg-primary']
          });
        }
      );
    }
  }

  openInvoiceInNewTab(invoiceId) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/invoices/${invoiceId}`])
    );
  
    window.open(url, '_blank');
  }
  
  openReferencesInNewTab(appellationId, productId) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/appellations/${appellationId}/products/${productId}/references`])
    );
  
    window.open(url, '_blank');
  }
}
