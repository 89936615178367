import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserModel, ResponseModalModel } from '../user.model';
import { EstablishmentModel } from '@app/pages/establishments/establishment.model';
import { Observable, map, of, tap } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EstablishmentService } from '@app/pages/establishments/establishment.service';
import { UserService } from '@app/_services';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  public refForm: FormGroup;
  public responseElt: ResponseModalModel = new ResponseModalModel();
  public establishments: EstablishmentModel[] = [];
  public loading: boolean = true;

  selectable = true;
  removable = true;
  establishmentCtrl = new FormControl();
  filteredEstablishments: Observable<EstablishmentModel[]>;
  allEstablishments: EstablishmentModel[] = [];

  public elementType: string;

  @ViewChild('establishmentInput') establishmentInput: ElementRef<HTMLInputElement>;

  constructor(
    private establishmentService: EstablishmentService,
    private usersService: UsersService,
    public dialogRef: MatDialogRef<UserDetailComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.responseElt.response = '';
    this.responseElt.element = new UserModel();

    this.filteredEstablishments = this.establishmentCtrl.valueChanges.pipe(
      map((search: string | null) => search ? this._filter(search) : this.allEstablishments.slice())
    );
  }

  ngOnInit() {
    this.refForm = new FormGroup({
      firstName: new FormControl(this.data.element.firstName, [Validators.required]),
      lastName: new FormControl(this.data.element.lastName, [Validators.required]),
      email: new FormControl(this.data.element.email, [Validators.required, Validators.email]),
      status: new FormControl(this.data.element.status, [])
    });
    if (this.data.context === 'update') {
      this.refForm.addControl(
        'id', new FormControl(this.data.element.id, [])
      );
    }

    this.establishmentService.getEstablishmentsList().subscribe(
      (elements: EstablishmentModel[]) => {
        this.allEstablishments = elements

        if (this.data.context === 'update') {

          for (const userEstablishment of this.data.element?.establishments) {
            for (const establishment of elements) {
              if (establishment['@id'] === userEstablishment.establishment) {
                this.establishments.push(establishment)
              }
            }
          }
        }
        this.loading = false
      }
    );
  }

  public hasError(controlName: string, errorName: string) {
    return this.refForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      const user: UserModel = this.refForm.value;

      this.responseElt.response = this.data.context;
      this.responseElt.element = user;

      this.dialogRef.close({
        user: this.responseElt,
        establishments: this.establishments
      });
    }
  }

  onEstablishmentSelectionChange(event: MatAutocompleteSelectedEvent): void {
    this.establishments.push(event.option.value);
    this.establishmentCtrl.setValue(null);
  }

  removeEstablishment(establishment: EstablishmentModel): void {
    const index = this.establishments.indexOf(establishment)

    if (index >= 0) {
      const user: UserModel = this.refForm.value;

      this.usersService.unlinkUserEstablishments(user, establishment['@id']).subscribe(() => {
        this.establishments.splice(index, 1);
      })
    }
  }

  private _filter(value: any): EstablishmentModel[] {
    if (!(typeof value === 'string' || value instanceof String)) {
      return this.allEstablishments
    }

    const filterValue = value.toLowerCase();

    return this.allEstablishments.filter(establishment => establishment.name.toLowerCase().includes(filterValue));
  }
}
