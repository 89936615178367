import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { UserModel } from '@app/_models';
import { MercurialeModel } from './mercuriale.model';
import { Utils } from '@app/_helpers';

@Injectable({
    providedIn: 'root'
})
export class MercurialesService {
    public currentUser: UserModel;

    constructor(private http: HttpClient, public utils: Utils) {
    }

    public updateMercuriale(mercurialeDatas: MercurialeModel): Observable<any> {
        return this.http.put<MercurialeModel>(`${environment.apiUrls[environment.name]}/update_mercuriale`, { ...mercurialeDatas });
    }

    public deleteMercuriale(mercurialeDatas: MercurialeModel): Observable<any> {
        const params = {'status': 4};
        return this.http.put<MercurialeModel>(`${environment.apiUrls[environment.name]}/mercuriales/${mercurialeDatas.id}`, params);
    }

    public getMercuriales(offset = 0, items_per_page = 50, filterValue = null): Observable<MercurialeModel[]> {
        offset++;
        let params = new HttpParams().set('order[id]', 'desc').set('page', offset.toString()).set('items_per_page', items_per_page.toString());
        params = params.append('status[]', '1').append('status[]', '2').append('status[]', '3');
        if (filterValue) {
            filterValue.forEach(element => {
                params = (element.value) ? params.append(element.type, element.value.toString()) : params;
            });
        }
        return this.http.get<MercurialeModel[]>(`${environment.apiUrls[environment.name]}/mercuriales`, { params: params });
    }

    public getMercuriale(mercurialeId): Observable<MercurialeModel> {
        let params = new HttpParams().set('groups[]', 'mercurialeDetail');
        params = params.append('status[]', '1').append('status[]', '2').append('status[]', '3');
        return this.http.get<MercurialeModel>(`${environment.apiUrls[environment.name]}/mercuriales/` + mercurialeId, { params: params });
    }
}
