import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  TemplateRef,
} from "@angular/core";
import { AppellationDetailComponent } from "../appellation-detail/appellation-detail.component";
import { AppellationModel, EstablishmentTypeModel } from "../appellation.model";
import { AppellationService } from "../appellation.service";
import { merge, Observable, fromEvent } from "rxjs";
import {
  startWith,
  switchMap,
  map,
  catchError,
  debounceTime,
  filter,
  distinctUntilChanged,
} from "rxjs/operators";
import { LoaderService } from "@app/_services/loader.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TextValidator } from "@app/_validators/text.validator";
import { SlugifyPipe } from "@app/_helpers/slugify.pipe";
import { VatcategoriesService } from "@app/pages/vatcategories/vatcategories.service";
import { VatcategoryModel } from "@app/pages/vatcategories/vatcategory.model";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-appellations-list",
  templateUrl: "./appellations-list.component.html",
  styleUrls: ["./appellations-list.component.scss"],
})
export class AppellationsListComponent implements OnInit {
  public displayedColumns: string[] = [
    "id",
    "name",
    "certified",
    "products",
    "vatCategory",
    "categories",
    "attributes",
    "actions",
  ];
  public elementsList = new MatTableDataSource<AppellationModel>();
  public elementsMatTable = new MatTableDataSource();
  public resultsLength;
  public pageEvent: PageEvent;
  public loading = true;
  public reload = new EventEmitter();
  public refForm: FormGroup;
  public dialogForm: MatDialogRef<any>;
  public appellationName: string;
  public establishmentTypes: EstablishmentTypeModel[];
  public vatCategories: VatcategoryModel[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("appellationSearchInput", { static: true })
  appellationSearchInput: ElementRef;
  @ViewChild("replacement", { static: true }) replacement: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private elementService: AppellationService,
    private loaderService: LoaderService,
    private appellationService: AppellationService,
    private vatCategoriesService: VatcategoriesService,
    public slugifyPipe: SlugifyPipe
  ) {}

  ngOnInit() {
    this.getAppellationsList();

    fromEvent(this.appellationSearchInput.nativeElement, "keyup")
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.paginator.firstPage();
        this.reload.emit();
      });
  }

  getAppellationsList() {
    merge(this.paginator.page, this.reload)
      .pipe(
        startWith(() => {}),
        switchMap(() => {
          this.loaderService.changeLoader(true);
          return this.elementService.getAppellations(
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.appellationSearchInput.nativeElement.value
          );
        }),
        map((data) => {
          this.loaderService.changeLoader(false);
          this.loading = false;
          this.resultsLength = data["hydra:totalItems"];

          return data;
        }),
        catchError(() => {
          this.loaderService.changeLoader(false);
          this.loading = false;
          return new Observable();
        })
      )
      .subscribe((data) => {
        this.loaderService.changeLoader(false);
        this.loading = false;
        this.elementsList.data = data["hydra:member"];
      });
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.filterPredicate = (
      data: AppellationModel,
      filter
    ) => {
      const dataStr = this.slugifyPipe.transform(
        data.name + (data.category ? data.category.fullCategoryName : ""),
        true
      );
      return dataStr.indexOf(filter) !== -1;
    };
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Switch context between creation/edition feature
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  modalElement(context: string, element: AppellationModel): void {
    this.appellationService
      .getEstablishmentTypes()
      .subscribe((establishmentTypes) => {
        this.vatCategoriesService
          .getVatcategories()
          .subscribe((vatCategories) => {
            this.establishmentTypes = establishmentTypes;
            this.vatCategories = vatCategories;
            this.saveElement(context, element);
          });
      });
  }

  /**
   * Display Modal for element's edition
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  saveElement(context: string, element: AppellationModel = null) {
    const updateElt = this.dialog.open(AppellationDetailComponent, {
      width: "600px",
      data: {
        element: element ? element : new AppellationModel(),
        context: context,
        establishmentTypes: this.establishmentTypes,
        vatCategories: this.vatCategories,
      },
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe((result) => {
      if (
        result &&
        (result.response === "update" || result.response === "create")
      ) {
        this.elementService.updateAppellation(result.element).then((res) => {
          let txt = "";
          if (context === "create") {
            txt = "créé";
            this.elementsList.data.unshift(res);
          } else {
            txt = "mis à jour";
            const index = this.elementsList.data.findIndex(
              (elt) => elt.id === result.element.id
            );
            this.elementsList.data[index] = res;
          }
          this.elementsList = new MatTableDataSource(this.elementsList.data);
          this.initMatTable();
          this.snackBar.open(result.element.name + " " + txt, "", {
            duration: 3000,
            panelClass: ["mat-bg-primary"],
          });
        });
      }
    });
  }

  public modalReplacement(appellationToRemove) {
    this.refForm = new FormGroup({
      id: new FormControl("", [
        Validators.required,
        TextValidator.noWhiteSpace,
      ]),
    });
    this.dialogForm = this.dialog.open(this.replacement);
    this.dialogForm.afterClosed().subscribe((appelationToReplace) => {
      if (appelationToReplace) {
        this.elementService
          .replaceAppellation(appellationToRemove, appelationToReplace)
          .subscribe(() => {
            const index = this.elementsList.data.findIndex(
              (elt) => elt.id === appellationToRemove
            );
            if (index >= 0) {
              this.elementsList.data.splice(index, 1);
              this.elementsList = new MatTableDataSource(
                this.elementsList.data
              );
              this.initMatTable();
            }
            this.snackBar.open("Element supprimé.", "", {
              duration: 3000,
              panelClass: ["mat-bg-primary"],
            });
          });
      }
    });
  }

  public checkAppellationId() {
    const appellationId = this.refForm.get("id").value;
    this.appellationService
      .getAppellation(appellationId)
      .then((res: AppellationModel) => {
        this.appellationName = res.name;
      });
  }
}
