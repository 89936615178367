import { Injectable } from "@angular/core";
import { InvoiceFileModel, INVOICES_STATE_VISIBLE } from "./invoice-file.model";
import { HttpClient, HttpParams } from "@angular/common/http";

import { UserModel } from "@app/_models";
import { firstValueFrom, map, Observable } from "rxjs";
import { environment } from "@environments/environment";

import { MatSnackBar } from "@angular/material/snack-bar";
import { Utils } from "@app/_helpers/utils.class";
import * as JSZip from "jszip";

import mime from "mime";

import FileSaver from "file-saver";
import { ParserModel } from "./select-parser/parser.model";

interface BaseInvoiceFileModel {
  identifierFile: string;
  originalName: string;
  id: number;
}

@Injectable({
  providedIn: "root",
})
export class InvoicesFilesService {
  public currentUser: UserModel;

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    public utils: Utils
  ) {}

  getParsers(): Observable<any[]> {
    return this.http.get<ParserModel[]>(`${environment.apiUrls[`v2-${environment.name}`]}/parsers`)
      .pipe(map((data) => data["hydra:member"]));
  }

  public getInvoices(
    offset = 0,
    items_per_page = 50,
    filterValue = null,
    state = INVOICES_STATE_VISIBLE,
    establishmentState = null
  ): Observable<InvoiceFileModel[]> {
    offset++;
    let params = new HttpParams()
      .set("order[createdAt]", "DESC")
      .set("order[identifierFile]", "asc")
      .set("page", offset.toString())
      .set("items_per_page", items_per_page.toString());
    if (state !== null) {
      state.forEach((element) => {
        params = params.append("state[]", element.toString());
      });
    }
    if (establishmentState !== null) {
      params = params.append(
        "establishment.state",
        establishmentState.toString()
      );
    }

    params = params
      .append("status[]", "1")
      .append("status[]", "2")
      .append("status[]", "3");
    if (filterValue) {
      filterValue.forEach((element) => {
        params = element.value
          ? params.append(element.type, element.value.toString())
          : params;
      });
    }
    return this.http.get<InvoiceFileModel[]>(
      `${environment.apiUrls[environment.name]}/establishment_files`,
      { params: params }
    );
  }

  public async findInvoiceByIdentifier(
    identifier: string
  ): Promise<InvoiceFileModel> {
    const response = await firstValueFrom(
      this.http.get<InvoiceFileModel[]>(
        `${environment.apiUrls[environment.name]}/establishment_files`,
        {
          params: {
            identifierFile: identifier,
          },
        }
      )
    );

    const invoices = response["hydra:member"];
    if (invoices.length === 0) {
      this.snackBar.open("Impossible d'ouvrir le fichier", "OK", {
        duration: 5000,
      });
    }

    return invoices[0];
  }

  /**
   * Open invoice in new tab
   * @param invoice invoice object to open
   */
  public openFile(file: BaseInvoiceFileModel): void {
    const id = file.id;
    this.http
      .get(
        `${environment.apiUrls[environment.name]}/establishment_files/${id}`,
        {
          params: {
            "groups[]": "aws_url",
          },
        }
      )
      .subscribe((fileApi) => {
        const url = fileApi["url"];

        fetch(url)
          .then((res) => (res.status === 200 ? true : false))
          .then((exists) => {
            if (!exists) {
              this.snackBar.open("Impossible d'ouvrir le fichier", "OK", {
                duration: 5000,
              });

              return;
            }

            const openWindow = window.open(file.originalName, "_blank");
            const htmlWindow =
              '<html><body style="margin:0;"><iframe style="border:0;width:100%;height:100%;" src="' +
              url +
              '"></iframe></body></html>';
            openWindow.document.title = file.originalName;
            openWindow.document.writeln(htmlWindow);
          });
      });
  }

  public analyzeInvoice(
    invoice: InvoiceFileModel
  ): Observable<InvoiceFileModel> {
    const payload = {
      profession: environment.name === "chr" ? "CHR" : "CPW",
      establishmentId: invoice.establishment.newId,
      fileIdentifier: invoice.identifierFile,
    };

    return this.http.post<InvoiceFileModel>(
      `${
        environment.apiUrls["v2-" + environment.name]
      }/invoice-analysis/analyze-establishment-file`,
      payload
    );
  }

  public flagAnalyzed(invoice: InvoiceFileModel): Observable<InvoiceFileModel> {
    const payload = {
      hasBeenAnalyzed: true,
    };
    return this.http.put<InvoiceFileModel>(
      `${environment.apiUrls[environment.name]}/establishment_files/${
        invoice.id
      }`,
      payload
    );
  }

  public deleteInvoice(
    invoice: InvoiceFileModel
  ): Observable<InvoiceFileModel> {
    return this.http.delete<InvoiceFileModel>(
      `${environment.apiUrls[environment.name]}/establishment_files/${
        invoice.id
      }`
    );
  }

  public batchDelete(
    invoices: InvoiceFileModel[]
  ): Observable<InvoiceFileModel> {
    const iris = invoices.map(
      (invoice) => `/establishment_files/${invoice.id}`
    );

    const payload = {
      iris: iris,
    };

    return this.http.post<InvoiceFileModel>(
      `${
        environment.apiUrls[environment.name]
      }/establishment_files/batch-delete`,
      payload
    );
  }

  public sendInvoicesToExternal(
    invoices: InvoiceFileModel[]
  ): Observable<InvoiceFileModel> {
    const files = invoices.map((invoice) => ({
      fileIdentifier: invoice.identifierFile,
      legacyEstablishmentId: invoice.establishment.id,
    }));

    const payload = {
      profession: environment.name === "chr" ? "CHR" : "CPW",
      files,
    };

    return this.http.post<InvoiceFileModel>(
      `${
        environment.apiUrls["v2-" + environment.name]
      }/invoice-analysis/external-analysis`,
      payload
    );
  }

  public sendInvoicesToParser(
    invoices: InvoiceFileModel[],
    parser: ParserModel
  ): Observable<InvoiceFileModel> {
    const files = invoices.map((invoice) => ({
      fileIdentifier: invoice.identifierFile,
      parserId: parser["@id"],
    }));

    const payload = {
      files: files,
    };

    return this.http.post<InvoiceFileModel>(
      `${
        environment.apiUrls["v2-" + environment.name]
      }/invoice-analysis/analyze`,
      payload
    );
  }

  public updateinvoice(invoice: InvoiceFileModel): Promise<any> {
    const params = {
      numberOfInvoices: invoice.numberOfInvoices,
      state: invoice.state,
      coopeoNote: invoice.coopeoNote,
      coopeoNoteType: invoice.coopeoNoteType,
      isExtActive: invoice.isExtActive,
      isCoopeoActive: invoice.isCoopeoActive,
      isOcrActive: invoice.isOcrActive,
      establishment:
        typeof invoice.establishment === "number"
          ? `/establishments/${invoice.establishment}`
          : invoice.establishment.id,
    };

    return this.http
      .put<InvoiceFileModel>(
        `${environment.apiUrls[environment.name]}/establishment_files/${
          invoice.id
        }`,
        params
      )
      .toPromise()
      .then((res) => {
        this.getInvoices();
        return res;
      });
  }

  /**
   * Download zip of invoice(s)
   * @param invoices invoices collection to download in zip file
   */
  public async downloadInvoices(files: BaseInvoiceFileModel[]): Promise<void> {
    const zip = new JSZip();
    const invoices = zip.folder("factures");
    let index = 0;

    for (const file of files) {
      index++;

      const fileApi = await firstValueFrom(
        this.http.get(
          `${environment.apiUrls[environment.name]}/establishment_files/${
            file.id
          }`,
          {
            params: {
              "groups[]": "aws_url",
            },
          }
        )
      );

      const url = fileApi["url"];

      try {
        const res = await fetch(url);

        if (res.status !== 200) {
          this.snackBar.open(
            `Fichier ${file.originalName} indisponible`,
            "OK",
            {
              duration: 5000,
            }
          );

          return;
        }

        const blob = await res.blob();

        const mimeType = blob.type;
        const ext =
          mime.getExtension(mimeType) ??
          this.utils.getExtension(file.originalName);

        invoices.file(file.identifierFile + "." + ext, blob);
      } catch (error) {
        this.snackBar.open(`Téléchargement impossible`, "OK", {
          duration: 5000,
        });
      }
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, "factures.zip");
    });
  }
}
