import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CategoryModel, ResponseModalModel } from '../category.model';
import { AttributesService } from '@app/pages/attributes/attributes.service';
import { AttributeModel } from '@app/pages/attributes/attribute.model';
import { CategoriesService } from '../categories.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TextValidator } from '@app/_validators/text.validator';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-category-detail',
  styleUrls: ['./categories-detail.component.scss'],
  templateUrl: './category-detail.component.html'
})
export class ProductsCategoryDetailComponent implements OnInit {
  public refForm: FormGroup;
  public responseElt: ResponseModalModel = new ResponseModalModel();
  public attributesList: AttributeModel[];
  public categoriesList: CategoryModel[];
  public filteredCategoriesList: Observable<CategoryModel[]>;
  public filteredAttributesList: Observable<AttributeModel[]>;
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('fruitInput', { read: false }) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { read: false }) matAutocomplete: MatAutocomplete;

  constructor(
    public dialogRef: MatDialogRef<CategoryModel>,
    private attributesService: AttributesService,
    private categoriesService: CategoriesService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.responseElt.response = '';
    this.responseElt.element = new CategoryModel();
  }

  ngOnInit() {
    const parentId = (this.data.element.parentId) ? this.data.element.parentId : this.data.parentId;
    this.refForm = new FormGroup({
      name: new FormControl(this.data.element.name, [Validators.required, TextValidator.noWhiteSpace]),
      parentId: new FormControl(this.data.categoriesList.find(elt => elt.id === parentId)),
      attributes: new FormControl((this.data.element.attributes) ? this.data.element.attributes.map(res => res.id) : '')
    });
    if (this.data.context === 'update') {
      this.refForm.addControl(
        'id', new FormControl(this.data.element.id)
      );
    }
    this.attributesService.getAttributes(true).subscribe(
      (elements: AttributeModel[]) => {
        this.attributesList = elements.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredAttributesList = this.refForm.get('attributes').valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterAttributes(name) : this.attributesList.slice())
          );
        // Remove parent's attributes from the list of choice (they are automaticly linked with the child)
        if (this.data.context === 'update') {
          const attributsParent = this.data.element.attributesList.filter(this.comparer(this.data.element.attributes));
          attributsParent.forEach(element => {
            const index = this.attributesList.findIndex(array => array.id === element.id);
            this.attributesList[index].duplicate = true;
          });
        }
      }
    );
    this.categoriesList = this.data.categoriesList;
    this.categoriesList.sort((a, b) => a.fullCategoryName.localeCompare(b.fullCategoryName));
    // Remove actual category from the list
    this.categoriesList.splice(this.categoriesList.findIndex(array => array.id === this.data.element.id), 1);
    this.filteredCategoriesList = this.refForm.get('parentId').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterCategories(name) : this.categoriesList.slice())
      );
  }

  private _filterCategories(value: any): CategoryModel[] {
    const filterValue = value.toLowerCase();
    return this.categoriesList.filter(cat => cat.name.toLowerCase().indexOf(filterValue) === 0 || cat.fullCategoryName.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterAttributes(value: any): AttributeModel[] {
    const filterValue = value.toLowerCase();
    return this.attributesList.filter(cat => cat.name.toLowerCase().indexOf(filterValue) === 0 || cat.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public displayFn(category?: CategoryModel): string | undefined {
    return category ? category.name : undefined;
  }

  public comparer(otherArray) {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.id === current.id;
      }).length === 0;
    };
  }

  public hasError(controlName: string, errorName: string) {
    return this.refForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.refForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }
}
