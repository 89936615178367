import { Injectable } from '@angular/core';
import { BrandModel } from './brand.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  constructor(private http: HttpClient) { }

  public getBrands(offset = 0, items_per_page = 50, filterValue = null): Observable<BrandModel[]> {
    offset++;
    let params = new HttpParams().set('order[id]', 'desc').set('page', offset.toString()).set('items_per_page', items_per_page.toString());
    if (filterValue) {
      params = params.set('name', filterValue);
    }
    const request = this.http.get<BrandModel[]>(`${environment.apiUrls[environment.name]}/brands`, { params: params });

    return request;
  }

  public getBrandsList(value: string = null, itemPerPage: number = null): Observable<BrandModel[]> {
    let params = new HttpParams().set('order[id]', 'desc').append('groups[]', 'list');
    if (value) {
      params = params.append('name', value);
    }
    if (itemPerPage !== null) {
      params = params.append('items_per_page', itemPerPage.toString());
    }
    return this.http.get<BrandModel[]>(`${environment.apiUrls[environment.name]}/brands`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public createBrand(brand: BrandModel): Observable<BrandModel> {
    return this.http.post<BrandModel>(`${environment.apiUrls[environment.name]}/brands`, brand);
  }

  public updateBrand(brand: BrandModel): Observable<BrandModel> {
    return this.http.put<BrandModel>(`${environment.apiUrls[environment.name]}/brands/${brand.id}`, brand);
  }

  public deleteBrand(brand: BrandModel): Observable<BrandModel> {
    return this.http.delete<BrandModel>(`${environment.apiUrls[environment.name]}/brands/${brand.id}`);
  }
}
