import { Component, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { ProductModel } from '../product.model';
import { ProductService } from '../product.service';
import { AppellationModel } from '../../appellations/appellation.model';
import { AppellationService } from '../../appellations/appellation.service';
import { SlugifyPipe } from '@app/_helpers/slugify.pipe';
import { merge, Observable, fromEvent } from 'rxjs';
import { startWith, switchMap, map, catchError, debounceTime, distinctUntilChanged, mergeAll } from 'rxjs/operators';
import { LoaderService } from '@app/_services/loader.service';
import { FormControl } from '@angular/forms';
import { VAT_VAL } from '../../products/product.model';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
})
export class ProductsListComponent implements OnInit {
  displayedColumns: string[] = ['id', 'references', 'vat', 'actions'];
  attributeValues: any[] = [];
  parentAppellationID: number;
  parentAppellation: AppellationModel;
  productsList: ProductModel[] = [];
  public resultsLength;
  public loading = true;
  public textSearchInput = [];
  public listSearchInput = [];
  public boolSearchInput = [];
  public emptySearchInput = [];
  public vatSearchInput = new FormControl();
  public reload = new EventEmitter();
  public vatValues: number[] = VAT_VAL;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('productSearchInput', { static: true }) productSearchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public snackBar: MatSnackBar,
    private appellationsService: AppellationService,
    private productsService: ProductService,
    private loaderService: LoaderService,
    private slugifyPipe: SlugifyPipe
  ) {
    this.parentAppellationID = +this.route.snapshot.paramMap.get(
      'appellation-id'
    );
  }

  ngOnInit() {
    this.getProductsList();

    this.appellationsService
      .getAppellation(this.parentAppellationID)
      .then((resAppellation) => {
        this.parentAppellation = resAppellation;
        this.vatSearchInput.valueChanges
            .subscribe(() => {
              this.paginator.firstPage();
              this.reload.emit();
            });
        for (const attr of resAppellation.attributesList) {
          this.attributeValues.push({
            id: attr.id,
            type: attr['type'],
            column: this.slugifyPipe.transform(attr.name),
            columnName: attr.name,
          });

          this.displayedColumns.splice(
            1,
            0,
            this.slugifyPipe.transform(attr.name)
          );
          if (attr['type'] === 0) {
            this.textSearchInput[attr.id] = new FormControl('');
            this.textSearchInput[attr.id].valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(() => {
              this.paginator.firstPage();
              this.reload.emit();
            });
          }
          if (attr['type'] === 1) {
            this.listSearchInput[attr.id] = new FormControl('');
            this.listSearchInput[attr.id].valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(() => {
              this.paginator.firstPage();
              this.reload.emit();
            });
          }
          if (attr['type'] === 2) {
            this.boolSearchInput[attr.id] = new FormControl('');
            this.boolSearchInput[attr.id].valueChanges
            .subscribe(() => {
              this.paginator.firstPage();
              this.reload.emit();
            });
          }
          this.emptySearchInput[attr.id] = new FormControl('');
          this.emptySearchInput[attr.id].valueChanges
            .subscribe(() => {
              this.paginator.firstPage();
              this.reload.emit();
            });
        }
        this.loading = false;
      });
  }

  getProductsList() {
    merge(this.paginator.page, this.reload)
      .pipe(
        startWith(() => {}),
        switchMap(() => {
          const filterValue: any = {
            textFilters: [],
            listFilters: [],
            boolFilters: [],
            vatFilters: ''
          };
          this.loaderService.changeLoader(true);

          for (const attributeId in this.textSearchInput) {
            if (this.emptySearchInput[attributeId].value) {
              filterValue.textFilters.push({ id: attributeId, value: null });
          } else if (this.textSearchInput[attributeId].value) {
              filterValue.textFilters.push({
                id: attributeId,
                value: this.textSearchInput[attributeId].value,
              });
            }
          }
          for (const attributeId in this.listSearchInput) {
            if (this.emptySearchInput[attributeId].value) {
              filterValue.listFilters.push({ id: attributeId, value: null });
          } else if (this.listSearchInput[attributeId].value) {
              filterValue.listFilters.push({
                id: attributeId,
                value: this.listSearchInput[attributeId].value,
              });
            }
          }
          for (const attributeId in this.boolSearchInput) {
            if (this.boolSearchInput[attributeId].value) {
              filterValue.boolFilters.push({ id: attributeId });
            } else {
              const indexToRemove = filterValue.boolFilters.findIndex(
                (att) => att.id === attributeId
              );
              if (indexToRemove >= 0) {
                filterValue.boolFilters.splice(indexToRemove, 1);
              }
            }
          }
          if (this.vatSearchInput.value) {
            filterValue.vatFilters = this.vatSearchInput.value;
          }
          return this.productsService.getProductsList(
            this.parentAppellationID,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            filterValue
          );
        }),
        map((data) => {
          this.loaderService.changeLoader(false);
          this.resultsLength = data['totalItems'];

          return data;
        }),
        catchError(() => {
          this.loaderService.changeLoader(false);
          return new Observable();
        })
      )
      .subscribe((data: ProductModel[]) => {
        this.loaderService.changeLoader(false);
        this.productsList = data['items'];
      });
  }

  findAttribute(attributeId, productValueAttributeValues) {
    return productValueAttributeValues.find(
      (element) => element.attribute['id'] === attributeId
    );
  }

  /**
   * Switch context between creation/edition feature
   * @param context 'create' or 'update'
   * @param product product obj to edit
   */
  modalProduct(context: string, product: ProductModel): void {
    if (context === 'create') {
      this.createProduct(context);
    } else if (context === 'update') {
      this.updateProduct(context, product);
    }
  }

  /**
   * Display Modal for product's creation
   * @param context 'create' or 'update'
   */
  createProduct(context: string) {
    const createApp = this.dialog.open(ProductDetailComponent, {
      width: '600px',
      data: {
        product: new ProductModel(this.parentAppellation),
        context: context,
      },
      disableClose: true
    });

    // Observable after modal closed
    createApp.afterClosed().subscribe((result) => {
      if (result.response === 'productCreated') {
        this.productsService.createProduct(result.product).then((res) => {
          this.router.navigate([
            '/appellations/' +
              res.appellation.id +
              '/products/' +
              res.id +
              '/references',
          ]);
        });
      }
    });
  }

  /**
   * Display Modal for product's edition
   * @param context 'create' or 'update'
   * @param product product obj to edit
   */
  updateProduct(context: string, product: ProductModel) {
    product.appellation = this.parentAppellation;
    const updateElt = this.dialog.open(ProductDetailComponent, {
      width: '600px',
      data: { product: product, context: context }
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe((result) => {
      if (result && result.response === 'productUpdated') {
        this.productsService.updateProduct(result.product).then((res) => {
          const index = this.productsList.findIndex(
            (array) => array.id === res.id
          );
          this.productsList[index] = res;
          this.snackBar.open(
            'Produit "' + result.product.appellation.name + '" modifié.',
            '',
            {
              duration: 3000,
              panelClass: ['mat-bg-primary'],
            }
          );
          this.reload.emit();
        });
      }
    });
  }

  /**
   * Delete product
   * @param product Product to remove
   */
  public deleteProduct(product: ProductModel) {
    if (
      window.confirm(
        'Confirmer la suppression du produit "' +
          product.appellation.name +
          '" ?'
      )
    ) {
      this.productsService.deleteProduct(product).then(() => {
        const appList = this.productsList;
        const index = appList.findIndex((array) => array.id === product.id);
        appList.splice(index, 1);
        this.snackBar.open(
          'Produit "' + product.appellation.name + '" supprimé.',
          '',
          {
            duration: 3000,
            panelClass: ['mat-bg-primary'],
          }
        );
        this.reload.emit();
      });
    }
  }
}
