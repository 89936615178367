import { EstablishmentModel } from "../establishments/establishment.model";

export class InvoiceFileModel {
  id: number;
  establishment: EstablishmentModel;
  originalName: string;
  identifierFile: string;
  numberOfInvoices: number;
  processedInvoices: number;
  coopeoNote: string;
  coopeoNoteType: string;
  state: number;
  isExtActive: boolean;
  isCoopeoActive: boolean;
  isOcrActive: boolean;
  hasBeenAnalyzed: boolean;
  providersName: Array<string>;
  isNative: boolean;

  /* establishmentId: number; */

  public constructor() {}
}

export class ResponseModalModel {
  response: string;
  element: InvoiceFileModel;

  constructor() {
    this.element = new InvoiceFileModel();
    this.response = "";
  }
}

/**
 * 0 => En attente de traitement
 * 1 => En cours de traitement
 * 2 => Demande traitée
 * 3 => Demande erreur fichier
 */
export const INVOICES_STATE_VISIBLE = [0, 1, 3];
