import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ReferenceInvoiceModel, ReferenceMercurialeModel } from './reference-invoice.model';

@Injectable({
  providedIn: 'root'
})
export class ImportCsvService {

  constructor(private http: HttpClient) { }

  public createImportinvoice(factures: ReferenceInvoiceModel[]): Observable<ReferenceInvoiceModel> {
    return this.http.post<ReferenceInvoiceModel>(`${environment.apiUrls[environment.name]}/import_invoice`, factures);
  }

  public createImportMercuriale(mercuriales: ReferenceMercurialeModel[]): Observable<ReferenceMercurialeModel> {
    return this.http.post<ReferenceMercurialeModel>(`${environment.apiUrls[environment.name]}/import_mercuriale`, mercuriales);
  }
}
