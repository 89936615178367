import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators  } from '@angular/forms';
import { UnitModel, ResponseModalModel } from '../unit.model';
import { UnitsService } from '../units.service';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TextValidator } from '@app/_validators/text.validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-unit-detail',
  templateUrl: './unit-detail.component.html'
})
export class UnitDetailComponent implements OnInit {
  public refForm: FormGroup;
  public responseElt: ResponseModalModel =  new ResponseModalModel();
  public unitsList: UnitModel[];
  public filteredUnitsList: Observable<UnitModel[]>;
  public typesList: any = [
    {value: 0, label: 'Conditionnement'},
    {value: 1, label: 'Équivalence'},
    {value: 2, label: 'Colisage'}
  ];

  constructor(
    public dialogRef: MatDialogRef<UnitDetailComponent>,
    private unitsService: UnitsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.responseElt.response = '';
      this.responseElt.element = new UnitModel();
    }

  ngOnInit() {
    this.refForm = new FormGroup({
      name: new FormControl(this.data.element.name, [Validators.required, Validators.maxLength(50), TextValidator.noWhiteSpace]),
      types: new FormControl(this.data.element.types, [Validators.required]),
      parent: new FormControl( (this.data.element.parent) ? this.data.element.parent : ''),
      shortName: new FormControl(this.data.element.shortName, [Validators.required, Validators.maxLength(50), TextValidator.noWhiteSpace])
    });
    if ( this.data.context === 'update' ) {
      this.refForm.addControl(
        'id', new FormControl(this.data.element.id, [])
      );
    }

    this.unitsService.getUnits().subscribe(
      (elements: UnitModel[]) => {
        this.unitsList = elements;
        this.unitsList.sort((a, b) => a.fullUnitName.localeCompare(b.fullUnitName));
        // Remove actual unit from the list
        this.unitsList.splice(this.unitsList.findIndex(array => array.id === this.data.element.id), 1);
        this.filteredUnitsList = this.refForm.get('parent').valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterUnits(name) : this.unitsList.slice())
          );
      }
    );
  }

  private _filterUnits(value: any): UnitModel[] {
    const filterValue = value.toLowerCase();
    return this.unitsList.filter(unit => unit.name.toLowerCase().indexOf(filterValue) === 0 || unit.fullUnitName.toLowerCase().indexOf(filterValue) === 0);
  }

  public displayFn(category?: UnitModel): string | undefined {
    return category ? category.name : undefined;
  }

  public hasError(controlName: string, errorName: string) {
    return this.refForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.refForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }

  public getSpace(element, space: number = 0) {
    if (element.parent) {
      space++;
      return this.getSpace(element.parent, space);
    }
    return space;
  }
}
