import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from '@app/_services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html'
})
export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService, private snackBar: MatSnackBar, private translate: TranslateService) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            if (message) {
                this.translate.get(message.text).subscribe((res: string) => {
                    const classes = (message.type === 'error') ? ['mat-bg-warn'] : ['mat-bg-accent'];
                    this.snackBar.open(res, '', {
                        duration: 3000,
                        panelClass: classes
                    });
                });
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
