import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators  } from '@angular/forms';
import { ResponseModalModel, ReferencesAlcoholModel } from '../references-alcohol.model';
import { ProvidersService } from '@app/pages/providers/providers.service';
import { TypesAlcoholsService } from '@app/pages/types-alcohols/types-alcohols.service';
import { ProviderModel } from '@app/pages/providers/provider.model';
import { TypeAlcoholModel } from '@app/pages/types-alcohols/type-alcohol.model';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { TextValidator } from '@app/_validators/text.validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-references-alcohol-detail',
  templateUrl: './references-alcohol-detail.component.html'
})
export class ReferencesAlcoholDetailComponent implements OnInit {
  public refForm: FormGroup;
  public responseElt: ResponseModalModel =  new ResponseModalModel();
  public providersList: ProviderModel[];
  public typesList: TypeAlcoholModel[];
  public filteredProvidersList: Observable<ProviderModel[]>;
  public filteredTypesList: Observable<TypeAlcoholModel[]>;

  constructor(
    public dialogRef: MatDialogRef<ReferencesAlcoholDetailComponent>,
    private providersService: ProvidersService,
    private TypeAlcoholService: TypesAlcoholsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.responseElt.response = '';
      this.responseElt.element = new ReferencesAlcoholModel();
    }

  ngOnInit() {
    this.refForm = new FormGroup({
      code: new FormControl(this.data.element.code ? this.data.element.code : '', [Validators.required, Validators.maxLength(50), TextValidator.noWhiteSpace]),
      degree: new FormControl(this.data.element.degree ? this.data.element.degree : 0, [Validators.required, Validators.maxLength(50)]),
      provider: new FormControl(this.data.element.provider ? this.data.element.provider : '', [Validators.required, Validators.maxLength(50)]),
      type: new FormControl(this.data.element.type ? this.data.element.type : '', [Validators.required, Validators.maxLength(50)])
    });
    if ( this.data.context === 'update' ) {
      this.refForm.addControl(
        'id', new FormControl(this.data.element.id, [])
      );
    }
    this.providersService.getProviders().subscribe(
      (elements: ProviderModel[]) => {
        this.filteredProvidersList = this.refForm.get('provider').valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(elements, name) : elements.slice())
        );
      }
    );
    this.TypeAlcoholService.getTypesAlcohols().subscribe(
      (elements: TypeAlcoholModel[]) => {
        this.filteredTypesList = this.refForm.get('type').valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(elements, name) : elements.slice())
        );
      }
    );
  }

  private _filter(list, value: any): any {
    const filterValue = value.toLowerCase();
    return list.filter(elm => elm.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public displayFn(element?: any): string | undefined {
    return element ? element.name : undefined;
  }

  public hasError(controlName: string, errorName: string) {
    return this.refForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.refForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }

}
