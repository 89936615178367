import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';

import { AlertService, AuthenticationService } from '@app/_services';
import { LoaderService } from '@app/_services/loader.service';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    public project: String = environment.name;
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private loaderService: LoaderService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.authenticationService.currentProjectName.subscribe(name => {
            if (name) {
                environment.name = name;
                this.project = name;
            } else {
                this.authenticationService.setCurrentProjectName('chr');
                this.project = 'chr';
            }
        });
        const email = environment.account.email;
        const password = environment.account.password;
        this.loginForm = this.formBuilder.group({
            project: [environment.name, [Validators.required]],
            username: [email, [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(coopeo|reda-cherifi)\.(fr|com)$')]],
            password: [password, [Validators.required, Validators.minLength(6)]]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    public applyProject(value) {
        this.authenticationService.setCurrentProjectName(value);
    }

    public hasError(controlName: string, errorName: string) {
        return this.loginForm.controls[controlName].hasError(errorName);
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                () => {
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                    this.loaderService.changeLoader(false);
                }
            );
    }
}
