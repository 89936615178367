import { Injectable } from '@angular/core';
import { AttributeValueModel } from './attribute-value.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AttributeValuesService {
  constructor(private http: HttpClient) { }

  public getAttributeValues(attribute): Observable<AttributeValueModel[]> {
    const params = new HttpParams().set('order[id]', 'desc').set('attribute', attribute);
    return this.http.get<AttributeValueModel[]>(`${environment.apiUrls[environment.name]}/attribute_values`, {
      params: params
    }).pipe(map(data => data['hydra:member']));
  }

  public createAttributeValue(attribute, attributeValue: AttributeValueModel): Observable<AttributeValueModel> {
    const params = {
      'value': attributeValue.value,
      'attribute': '/attributes/' + attribute.id
    };
    return this.http.post<AttributeValueModel>(`${environment.apiUrls[environment.name]}/attribute_values`, params);
  }

  public updateAttributeValue(attribute, attributeValue: AttributeValueModel): Observable<AttributeValueModel> {
    return this.http.put<AttributeValueModel>(`${environment.apiUrls[environment.name]}/attribute_values/${attributeValue.id}`, attributeValue);
  }

  public deleteAttributeValue(attribute, attributeValue: AttributeValueModel): Observable<AttributeValueModel> {
    return this.http.delete<AttributeValueModel>(`${environment.apiUrls[environment.name]}/attribute_values/${attributeValue.id}`);
  }
}
