import { Injectable } from '@angular/core';
import { ReferencesFeeModel } from './references-fee.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferencesFeesService {

  constructor(private http: HttpClient) { }

  public getReferencesfees(offset = 0, items_per_page = null, filterValue = null): Observable<ReferencesFeeModel[]> {
    offset++;
    let params = new HttpParams().set('order[id]', 'desc').set('page', offset.toString());
    if (items_per_page) {
      params = params.set('items_per_page', items_per_page.toString());
    }
    params = params.append('status[]', '1').append('status[]', '2').append('status[]', '3');
    if (filterValue) {
        filterValue.forEach(element => {
            params = (element.value) ? params.append(element.type, element.value.toString()) : params;
        });
    }
    return this.http.get<ReferencesFeeModel[]>(`${environment.apiUrls[environment.name]}/reference_fees`, {params: params});
  }

  public createReferencesfee(referencesfee: ReferencesFeeModel): Observable<ReferencesFeeModel> {
    const params = {
      'code': referencesfee.code,
      'provider': '/providers/' + referencesfee.provider.id,
      'fee': '/fees/' + referencesfee.fee.id
    };
    return this.http.post<ReferencesFeeModel>(`${environment.apiUrls[environment.name]}/reference_fees`, params);
  }

  public updateReferencesfee(referencesfee: ReferencesFeeModel): Observable<ReferencesFeeModel> {
    const params = {
      'code': referencesfee.code,
      'provider': '/providers/' + referencesfee.provider.id,
      'fee': '/fees/' + referencesfee.fee.id
    };
    return this.http.put<ReferencesFeeModel>(`${environment.apiUrls[environment.name]}/reference_fees/${referencesfee.id}`, params);
  }

  public deleteReferencesfee(referencesfee: ReferencesFeeModel): Observable<ReferencesFeeModel> {
    return this.http.delete<ReferencesFeeModel>(`${environment.apiUrls[environment.name]}/reference_fees/${referencesfee.id}`);
  }
}
