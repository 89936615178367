import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MercurialesService } from '../mercuriales.service';
import { MercurialeModel, MercurialeRowModel } from '../mercuriale.model';
import { ProvidersService } from '@app/pages/providers/providers.service';
import { EstablishmentService } from '@app/pages/establishments/establishment.service';
import { EstablishmentModel } from '@app/pages/establishments/establishment.model';
import { ProviderModel } from '@app/pages/providers/provider.model';
import { ReferencesService } from '@app/pages/references/references.service';
import { isEqual, parseISO } from 'date-fns';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EstablishmentGroupModel } from '@app/pages/establishment-group/establishment-group.model';
import { EstablishmentGroupService } from '@app/pages/establishment-group/establishment-group.service';
import { LoaderService } from '@app/_services/loader.service';
import * as moment from 'moment';

@Component({
  selector: 'app-mercuriale-detail',
  templateUrl: './mercuriale-detail.component.html',
  styleUrls: ['./mercuriale-detail.component.scss']
})
export class MercurialeDetailComponent implements OnInit {
  public establishments: EstablishmentModel[] = [];
  public establishmentsGroups: EstablishmentGroupModel[];
  public providers: ProviderModel[] = [];
  public mercurialeId: Number;
  public mercurialeDatas: MercurialeModel = null;

  constructor(
    private route: ActivatedRoute,
    private mercurialeService: MercurialesService,
    public snackBar: MatSnackBar,
    private router: Router,
    private establishmentService: EstablishmentService,
    private establishmentGroupService: EstablishmentGroupService,
    private providerService: ProvidersService,
    private loaderService: LoaderService,
    private referenceServices: ReferencesService
  ) { }

  ngOnInit() {
    this.establishmentService.getEstablishments().subscribe(establishments => {
      this.establishments = establishments;
    });
    this.establishmentGroupService.getEstablishmentGroup().subscribe(establishmentsGroups => {
      this.establishmentsGroups = establishmentsGroups['hydra:member'];
    });
    this.providerService.getProviders().subscribe(providers => {
      this.providers = providers;
    });
    this.mercurialeId = +this.route.snapshot.paramMap.get('mercuriale-id');

    this.loaderService.changeLoader(true);
    this.mercurialeService.getMercuriale(this.mercurialeId).subscribe(mercuriale => {
      this.loaderService.changeLoader(false);
      this.mercurialeDatas = mercuriale;
    });

  }

  public isEqual(dateA, dateB) { return isEqual(parseISO(dateA), parseISO(dateB)); }

  /**
   * Remove reference from mercuriale
   * @param refIndex Index of reference on the csvData
   */
  public removeLine(elementIndex) {
    this.mercurialeDatas.mercurialeRows.splice(elementIndex, 1);
  }

  public getReference(mercurialeRow: MercurialeRowModel) {
    this.referenceServices.getReferencesByCode((mercurialeRow.reference) ? mercurialeRow.reference.code : mercurialeRow.referenceCode, this.mercurialeDatas.providerId, true).subscribe(newReference => {
      if (newReference.length > 0 && ((mercurialeRow.reference && mercurialeRow.reference.code === newReference[0].code) || mercurialeRow.referenceCode === newReference[0].code)) {
        mercurialeRow.reference = newReference[0];
        mercurialeRow.status = (!mercurialeRow.preTaxPrice) ? false : true;
      } else {
        mercurialeRow.referenceCode = mercurialeRow.referenceCode;
        mercurialeRow.status = (!mercurialeRow.preTaxPrice) ? false : true;
      }
    });
  }

  public checkPrice(index) {
    const check = (isNaN(this.mercurialeDatas.mercurialeRows[index].preTaxPrice) || this.mercurialeDatas.mercurialeRows[index].preTaxPrice == null);
    this.mercurialeDatas.mercurialeRows[index].status = !check;
    return check;
  }

  public validForm() {
    let response = true;
    this.mercurialeDatas.mercurialeRows.forEach((mercurialeRow, index) => {
      if (this.checkPrice(index)) {
        response = false;
      }
    });
    return response;
  }

  public persist() {
    this.mercurialeDatas.mercurialeRows.forEach((mercurialeRow, index) => {
      this.mercurialeDatas.mercurialeRows[index].startDate = moment(Date.parse(mercurialeRow.startDate)).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      this.mercurialeDatas.mercurialeRows[index].endDate = moment(Date.parse(mercurialeRow.endDate)).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    });
    this.mercurialeService.updateMercuriale(this.mercurialeDatas).subscribe(res => {
      if (res) {
        this.router.navigate(['/mercuriales']);
        this.snackBar.open('Mercuriale ' + this.mercurialeDatas.identifier + ' mise à jour', '', {
          duration: 3000,
          panelClass: ['mat-bg-primary']
        });
      }
    });
  }

  /**
   * Call to the service and open file in new tab
   * @param file file consultation
   */
  public openFile(file) {
    // this.mercurialeService.openFile(file);
  }

  public resetEstablishment(type) {
    (type === 'group') ? this.mercurialeDatas.establishmentGroupId = null : this.mercurialeDatas.establishmentId = null;
  }

}
