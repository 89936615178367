import { Injectable } from '@angular/core';
import { AttributeModel } from './attribute.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AttributesService {

  constructor(private http: HttpClient) { }

  public getAttributes(list = false): Observable<AttributeModel[]> {
    let params = new HttpParams().set('order[id]', 'desc');
    if (list) {
      params = params.set('groups[]', 'list');
    }
    return this.http.get<AttributeModel[]>(`${environment.apiUrls[environment.name]}/attributes`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public getAttribute(id): Observable<AttributeModel> {
    return this.http.get<AttributeModel>(`${environment.apiUrls[environment.name]}/attributes/${id}`);
  }

  public createAttribute(attribute: AttributeModel): Observable<AttributeModel> {
    return this.http.post<AttributeModel>(`${environment.apiUrls[environment.name]}/attributes`, attribute);
  }

  public updateAttribute(attribute: AttributeModel): Observable<AttributeModel> {
    return this.http.put<AttributeModel>(`${environment.apiUrls[environment.name]}/attributes/${attribute.id}`, attribute);
  }

  public deleteAttribute(attribute: AttributeModel): Observable<AttributeModel> {
    return this.http.delete<AttributeModel>(`${environment.apiUrls[environment.name]}/attributes/${attribute.id}`);
  }
}
