import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { ChartOptions, ChartData } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { Utils } from '@app/_helpers';
import { AnalyticService } from '@app/pages/dashboard/dashboard.service';
import * as moment from 'moment';
import { AuthenticationService } from '@app/_services';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-invoices-review',
  templateUrl: './invoices-review.component.html',
  styleUrls: ['./invoices-review.component.scss']
})
export class InvoicesReviewComponent implements OnInit, OnDestroy {
  @ViewChild(BaseChartDirective, { static: true }) private _chart;

  @Input() events: Observable<any>;
  @Input() type: any;

  // Dates filter
  public dateStart = new FormControl({ value: moment().startOf('year'), disabled: true });
  public dateEnd = new FormControl({ value: moment().endOf('year'), disabled: true });

  // Syntesis bar chart per month
  public barChartOptions: ChartOptions = {
    responsive: true,
    layout: { padding: { top: 10, bottom: 10 } },
    maintainAspectRatio: false,
    animation: { duration: 2500 },
    scales: {
      x: { grid: { display: false } },
      y: {
        stacked: true,
        ticks: { maxTicksLimit: 10000 }
      }
    },
    plugins: {
      title: { display: true, font: { size: 20, weight: 'normal' }, color: 'white', text: 'Évolution du nombre de factures' },
      datalabels: { color: 'transparent', anchor: 'end', align: 'end' },
      legend: { position: 'top', onClick: null },
      tooltip: {
        mode: 'index',
        intersect: false,
        position: 'average',
        callbacks: {
          label: function (context) {
            return context.formattedValue + ' factures';
          }
        }
      },
    },
    elements: {
      point: { radius: 0 },
      line: { tension: 0.4 }
    },
  };
  public barChartPlugins = [pluginDataLabels];
  public barChartData: ChartData<'line'> = { labels: [], datasets: [] };
  public color = '#f9a825';

  private eventsSubscription: any;

  constructor(private utils: Utils,
    private analyticService: AnalyticService) { }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe(res => {
      this.getStats(res.filters);
      this.dateStart = res.filters.dateStart;
      this.dateEnd = res.filters.dateEnd;
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  /**
   * Get datas from API and formatte them in the chart
   */
  public getStats(filters: any) {
    this.barChartData = { labels: [], datasets: [] };
    // Reload chart if establishment updated
    const dateStart = moment(filters.dateStart.value.toDate()).format('YYYY-MM-DD');
    const dateEnd = moment(filters.dateEnd.value.toDate()).format('YYYY-MM-DD');
    /**
     * Annual Review
     */
    this.analyticService.getInvoicesReview(dateStart, dateEnd, this.type).subscribe(datas => {
      // Init labels list
      // this.getMaxValue(datas['invoices']);

      const datasPurchasesFormatted = this.getDatasFormatted(datas['invoices']);
      // Params for global purchases
      this.barChartData.datasets = [{
        data: datasPurchasesFormatted,
        label: 'Factures',
        backgroundColor: this.color + '2A',
        hoverBackgroundColor: this.color + '2A',
        borderColor: this.color,
        hoverBorderColor: this.color,
        pointBackgroundColor: this.color + '2A',
        pointBorderColor: this.color
      }];
    });
  }

  /**
   * Return datas source for annual review
   * @param indexStart Start iteration
   * @param indexLength Number iteration
   * @param yearCompare Current year
   * @param datasInvoices Datas invoices
   */
  public getDatasReviewByPeriod(indexStart, indexLength, yearCompare, datasInvoices, concatLabel = true) {
    const datasInv = [];
    let data;

    for (let y = indexStart; y <= indexLength; y++) {
      if (concatLabel) {
        this.barChartData.labels.push(this.utils.capitalize(moment().month(y).format('MMMM')) + ' ' + yearCompare);
      }
      if (data = datasInvoices.find(invoice => (invoice.year === yearCompare && invoice.month === ('0' + (y + 1)).slice(-2).toString()))) {
        datasInv.push(Math.round((data.total) * 100) / 100);
      } else {
        datasInv.push(0);
      }
    }
    if (!concatLabel) {
    }
    return { datasInv: datasInv };
  }

  /**
   * Return an object with data from API formatted with the number of label list (months)
   * @param datas Datas form API
   * @param concatLabel Boolean for providers datas
   * TODO: Refacto with provider weight
   */
  public getDatasFormatted(datas, concatLabel = true) {
    let datasInv = [];
    const dayStart = moment(this.dateStart.value).format('D');
    const dayEnd = moment(this.dateEnd.value).format('D');
    const monthStart = moment(this.dateStart.value).format('MM');
    const monthEnd = moment(this.dateEnd.value).format('MM');
    const yearStart = moment(this.dateStart.value).format('YYYY');
    const yearEnd = moment(this.dateEnd.value).format('YYYY');

    const daysDifference = Number.parseInt(dayEnd, 10) - Number.parseInt(dayStart, 10);
    const monthsDifference = Number.parseInt(monthEnd, 10) - Number.parseInt(monthStart, 10);
    const yearsDifference = Number.parseInt(yearEnd, 10) - Number.parseInt(yearStart, 10);
    let response;
    let indexStart, indexLength, yearCompare;
    // Define the numbers of month to show on the graph
    if (yearsDifference > 0) { // Period during differents years - Example period between 10/2018 to 12/2020
      for (let index = 0; index <= yearsDifference; index++) {
        if (index === 0) { // First year period - Example months between 10/2018 to 12/2018
          // ChartJs consider months list starting at the index 0 (for january)
          indexStart = Number.parseInt(monthStart, 10) - 1;
          indexLength = 11;
          yearCompare = yearStart;
          response = this.getDatasReviewByPeriod(indexStart, indexLength, yearCompare, datas, concatLabel);
        } else if (index === yearsDifference) { // Last year period - Example months between 01/2020 to 12/2020
          // ChartJs consider months list starting at the index 0 (for january)
          indexStart = 0;
          indexLength = Number.parseInt(monthEnd, 10) - 1;
          yearCompare = yearEnd;
          response = this.getDatasReviewByPeriod(indexStart, indexLength, yearCompare, datas, concatLabel);
        } else { // During the period of years - Example months between 01/2019 to 12/2019
          indexStart = 0;
          indexLength = 11;
          yearCompare = (Number.parseInt(yearStart, 10) + index).toString();
          response = this.getDatasReviewByPeriod(indexStart, indexLength, yearCompare, datas, concatLabel);
        }
        datasInv = [...datasInv, ...response.datasInv];
      }
    } else if (monthsDifference > 0 || daysDifference > 0) { // Period during the same year - Example period between 01/2020 to 12/2020
      // ChartJs consider months list starting at the index 0 (for january)
      indexStart = Number.parseInt(monthStart, 10) - 1;
      indexLength = Number.parseInt(monthEnd, 10) - 1;
      yearCompare = yearStart;
      response = this.getDatasReviewByPeriod(indexStart, indexLength, yearCompare, datas, concatLabel);
      datasInv = [...datasInv, ...response.datasInv];
    }
    return datasInv;
  }

  /**
   * TODO: Refacto this part:
   * https://github.com/valor-software/ng2-charts/commit/ef5d2f47f59a72f2d77a02c51c51c412a4cc9489
   */
  public forceChartRefresh() {
    setTimeout(() => { this._chart.refresh(); });
  }
}
