import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AnalyticService } from '@app/pages/dashboard/dashboard.service';
import { Utils } from '@app/_helpers';
import { LoaderService } from '@app/_services/loader.service';
import * as moment from 'moment';
import { catchError, map, merge, Observable, startWith, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-wrong-savings-list',
  templateUrl: './wrong-savings-list.component.html',
  styleUrls: ['./wrong-savings-list.component.scss']
})
export class WrongSavingsListComponent implements OnInit, OnDestroy {
  @Input() events: Observable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private eventsSubscription: Subscription;
  private wrongSavingsSubscription: Subscription;

  public displayedColumns: string[] = ['code', 'appellation', 'providerName', 'establishmentName', 'invoiceRowPrice', 'priceMercuriale', 'difference', 'percentage', 'actions'];
  public dataSource = new MatTableDataSource<any>();
  public wrongSavings: any[];
  public resultsLength;
  public loading = true;
  public filters;
  public reload = new EventEmitter()

  constructor(
    private analyticService: AnalyticService,
    public utils: Utils,
    private loaderService: LoaderService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>();
    this.initMatTable();

    this.eventsSubscription = this.events.subscribe(res => {
      this.filters = res.filters;

      if (this.wrongSavingsSubscription) {
        this.wrongSavingsSubscription.unsubscribe();
      }
      this.getStats();
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
    if (this.wrongSavingsSubscription) {
      this.wrongSavingsSubscription.unsubscribe();
    }
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Get datas from API and formatte them in the chart
   */
  public getStats() {
    this.wrongSavingsSubscription = merge(this.reload, this.paginator.page)
      .pipe(
        startWith(() => { }),
        switchMap(() => {
          this.loaderService.changeLoader(true);
          const dateStart = moment(this.filters.dateStart.value.toDate()).format('YYYY-MM-DD');
          const dateEnd = moment(this.filters.dateEnd.value.toDate()).format('YYYY-MM-DD');
          return this.analyticService.getWrongSavings(dateStart, dateEnd, this.paginator.pageIndex, this.paginator.pageSize)
        }),
        map(data => {
          this.loaderService.changeLoader(false);
          this.loading = false;
          this.resultsLength = data['totalItems'];

          return data;
        }),
        catchError(() => {
          this.loaderService.changeLoader(false);
          this.loading = false;
          return new Observable();
        })
      ).subscribe(data => {
        this.loaderService.changeLoader(false);
        this.loading = false;
        this.wrongSavings = data['productsListFormatted'];

        this.dataSource = new MatTableDataSource<any>(this.wrongSavings);
      }
      );
  }

  openFileInNewTab(id, context) {
    let url;
    if (context === 'invoice') {
      url = this.router.serializeUrl(
        this.router.createUrlTree([`/invoices/${id}`])
      );
    } else if (context === 'mercuriale') {
      url = this.router.serializeUrl(
        this.router.createUrlTree([`/mercuriales/${id}`])
      );
    }

    window.open(url, '_blank');
  }

}
