export class UnitModel {
    id: number;
    name: string;
    fullUnitName: string;
    types: number[];
    parent: UnitModel;
    shortName: string;
    parentUnitShortName: string;
    parentUnitRatio: number;

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: UnitModel;

    constructor() {
        this.element = new UnitModel();
        this.response = '';
    }
}
