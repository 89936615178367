import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextValidator } from '@app/_validators/text.validator';
import { EstablishmentModel, EstablishmentStates, ResponseModalModel } from '../establishment.model';

@Component({
  selector: 'app-establishment-detail',
  templateUrl: './establishment-detail.component.html',
  styleUrls: ['./establishment-detail.component.scss']
})
export class EstablishmentDetailComponent implements OnInit {
  public establishmentForm: FormGroup;
  public responseElt: ResponseModalModel = new ResponseModalModel();
  public establishmentsTypesList = this.data.establishmentsTypesList;
  public establishmentsStates = EstablishmentStates;

  constructor(
    public dialogRef: MatDialogRef<EstablishmentDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.responseElt.response = '';
    this.responseElt.element = new EstablishmentModel();
  }

  ngOnInit() {
    this.establishmentForm = new FormGroup({
      name: new FormControl(this.data.element.name, [Validators.required, TextValidator.noWhiteSpace]),
      address: new FormControl(this.data.element.address, [Validators.required, TextValidator.noWhiteSpace]),
      city: new FormControl(this.data.element.city, [Validators.required, TextValidator.noWhiteSpace]),
      type: new FormControl((this.data.element.type_id), [Validators.required]),
      isHighPriority: new FormControl((this.data.element.isHighPriority)),
      state: new FormControl((this.data.element.state), [Validators.required]),
      flatRate: new FormControl((this.data.element.flatRate)),
      handledFixesFees: new FormControl((this.data.element.handledFixesFees), [Validators.required]),
      comment: new FormControl(this.data.element.comment),
    });
    this.establishmentForm.get('flatRate').valueChanges.subscribe(newVal => {
      if (newVal.length > 0) return;
      return this.establishmentForm.get('flatRate').setValue(null, { onlySelf: true })
    })
    if (this.data.context === 'update') {
      this.establishmentForm.addControl(
        'id', new FormControl(this.data.element.id, [])
      );
    }
  }

  public hasError(controlName: string, errorName: string) {
    return this.establishmentForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.establishmentForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.establishmentForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }

}
