import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThemeService } from 'ng2-charts';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { AuthenticationService } from '@app/_services';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  // Dates filter
  public dateStart = new FormControl({ value: moment().startOf('year'), disabled: true });
  public dateEnd = new FormControl({ value: moment().endOf('year'), disabled: true });

  public eventsSubject: Subject<any> = new Subject<any>();

  constructor(private themeService: ThemeService) { }

  ngOnInit() { this.setDarkTheme(); }

  /**
   * Define the filters and redefine the chart
   * @param filters object of dateStart, dateEnd and list of establishments
   */
  public setFilters(filters) {
    // Send the filters to all components called in the dashboard
    this.eventsSubject.next({ filters: filters });
  }

  /**
   * Define the dark theme
   */
  public setDarkTheme() {
    const overrides: ChartConfiguration['options'] = {
      plugins: {
        datalabels: { display: false },
        title: { font: { family: 'Open Sans', style: 'normal' } },
        legend: { labels: { color: 'white', font: { family: 'Open Sans' } } },
      },
      scales: {
        x: { ticks: { color: 'white', font: { family: 'Open Sans' } }, grid: { color: 'rgba(255,255,255,0.1)' } },
        y: { ticks: { color: 'white', font: { family: 'Open Sans' } }, grid: { color: 'rgba(255,255,255,0.1)' } }
      }
    };
    this.themeService.setColorschemesOptions(overrides);
  }
}
