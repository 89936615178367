import { ReferenceModel } from '../references/reference.model';
import { ProviderModel } from '../providers/provider.model';
import { ReferencesTaxeModel } from '../references-taxes/references-taxe.model';
import { ReferencesFeeModel } from '../references-fees/references-fee.model';
import { FeeValueModel } from '../fees/fee.model';

/**
 * Facture
 */
export class ReferenceInvoiceModel {
    dossier: string;
    lien_fichier: string;
    fournisseur: ProviderModel;
    facture: string;
    date_facture: Date | string;
    references: ReferenceInvoiceDetailModel[];
    fees: FeeValueModel[];
    total_facture_ht: number;
    total_facture_ttc: number;
    total_coopeo_ht: number;
    total_coopeo_ttc: number;
    discount: number;
    isRightEstablishment: boolean;

    constructor() { this.references = []; }
}

export class ReferenceInvoiceDetailModel {
    reference: ReferencesTaxeModel | ReferencesFeeModel | ReferenceModel;
    date_livraison: Date | string;
    quantite: number;
    prix_unitaire_ht: any;
    tva: number;
    taxesHT: number;
    taxesTTC: number;
    status: boolean;
    discount: number;
    credit: number;

    constructor() {
        this.reference = new ReferenceModel();
        this.status = true;
        this.quantite = 1;
        this.prix_unitaire_ht = 0;
        this.taxesHT = 0;
        this.taxesTTC = 0;
        this.discount = 0;
        this.credit = 0;
    }
}

/**
 * Mercuriale
 */
export class ReferenceMercurialeModel {
    dossier: number;
    dossierGroup: number;
    lien_fichier: string;
    fournisseur: ProviderModel;
    mercuriale: string;
    references: ReferenceMercurialeDetailModel[];
    discount: number;

    constructor() { this.references = []; }
}

export class ReferenceMercurialeDetailModel {
    reference: ReferencesTaxeModel | ReferencesFeeModel | ReferenceModel;
    date_debut: Date;
    date_fin: Date;
    prix_unitaire_ht: any;
    status: boolean;
    mercurialeRatio: any;

    constructor() {
        this.reference = new ReferenceModel();
        this.prix_unitaire_ht = 0;
        this.status = true;
    }
}
