import { TypeAlcoholModel } from '../types-alcohols/type-alcohol.model';

export class StickerModel {
    id: number;
    type: TypeAlcoholModel;
    year: number;
    amount: number;

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: StickerModel;

    constructor() {
        this.element = new StickerModel();
        this.response = '';
    }
}
