import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sanitize-id' })
export class SanitizeIdPipe implements PipeTransform {
    public regex = new RegExp('.*\/(.*?)');

    transform(input: string): number {
        return Number(input.replace(this.regex, ''));
    }
}
