import { Component, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { BrandModel } from '../brand.model';
import { BrandsService } from '../brands.service';
import { BrandDetailComponent } from '../brand-detail/brand-detail.component';
import { SlugifyPipe } from '@app/_helpers/slugify.pipe';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, startWith, switchMap, catchError } from 'rxjs/operators';
import { LoaderService } from '@app/_services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-brands-list',
  templateUrl: './brands-list.component.html',
  styleUrls: ['./brands-list.component.scss']
})
export class BrandsListComponent implements OnInit {
  public displayedColumns: string[] = ['id', 'name', 'actions'];
  public elementsList: BrandModel[] = [];
  public elementType: string;
  public elementsMatTable;
  public resultsLength;
  public pageEvent: PageEvent;
  public loading = true;
  public reload = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('brandSearchInput', { static: true }) brandSearchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private elementService: BrandsService,
    private slugifyPipe: SlugifyPipe,
    private translate: TranslateService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.getBrandsList();

    fromEvent(this.brandSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , debounceTime(800)
      , distinctUntilChanged()
    ).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
  }

  getBrandsList() {
    merge(this.paginator.page, this.reload)
      .pipe(
        startWith(() => { }),
        switchMap(() => {
          this.loaderService.changeLoader(true);
          return this.elementService.getBrands(this.paginator.pageIndex, this.paginator.pageSize, this.brandSearchInput.nativeElement.value);
        }),
        map(data => {
          this.loaderService.changeLoader(false);
          this.loading = false;
          this.resultsLength = data['hydra:totalItems'];

          return data;
        }),
        catchError(() => {
          this.loaderService.changeLoader(false);
          this.loading = false;
          return new Observable();
        })
      ).subscribe(data => {
        this.loaderService.changeLoader(false);
        this.loading = false;
        this.elementsList = data['hydra:member'];
      }
      );
  }

  /**
   * Switch context between creation/edition feature
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  modalElement(context: string, element: BrandModel): void {
    if (context === 'create') {
      this.createElement(context);
    } else if (context === 'update') {
      this.updateElement(context, element);
    }
  }

  /**
   * Display Modal for element's creation
   * @param context 'create' or 'update'
   */
  createElement(context: string) {
    const createApp = this.dialog.open(BrandDetailComponent, {
      width: '600px',
      data: { element: new BrandModel(), context: context, elementType: this.elementType },
      disableClose: true
    });

    // Observable after modal closed
    createApp.afterClosed().subscribe(result => {
      if (result.response === 'create') {
        const match = this.elementsList.find(i => this.slugifyPipe.transform(i.name, true) === this.slugifyPipe.transform(result.element.name, true));
        if (match === undefined) {
          this.elementService.createBrand(result.element).subscribe(
            res => {
              this.snackBar.open('Element "' + result.element.name + '" créé.', '', {
                duration: 3000,
                panelClass: ['mat-bg-primary']
              });
              this.elementsList.unshift(res);
            }
          );
        } else {
          this.translate.get('global.actions.duplicateWith').subscribe((res: string) => {
            this.snackBar.open(res + ' "' + result.element.name + '".', '', {
              duration: 3000,
              panelClass: ['mat-bg-warn']
            });
          });
        }
      }
    });
  }

  /**
   * Display Modal for element's edition
   * @param context 'create' or 'update'
   * @param element element obj to edit
   */
  updateElement(context: string, element: BrandModel) {
    const updateElt = this.dialog.open(BrandDetailComponent, {
      width: '600px',
      data: { element: element, context: context },
      disableClose: true
    });

    // Observable after modal closed
    updateElt.afterClosed().subscribe(result => {
      if (result.response === 'update') {
        this.elementService.updateBrand(result.element).subscribe(
          res => {
            const refList = this.elementsList;
            const index = refList.findIndex(array => array.id === result.element.id);
            refList[index] = res;
            this.snackBar.open('Element "' + result.element.name + '" modifié.', '', {
              duration: 3000,
              panelClass: ['mat-bg-primary']
            });
          }
        );
      }
    });
  }

  /**
   * Delete element
   * @param element Element to remove
   */
  public deleteElement(element) {
    if (window.confirm('Confirmer la suppression de la reference "' + element.name + '" ?')) {
      this.elementService.deleteBrand(element).subscribe(
        res => {
          const refList = this.elementsList;
          const index = refList.findIndex(array => array.id === element.id);
          refList.splice(index, 1);
          this.snackBar.open('Element "' + element.name + '" supprimé.', '', {
            duration: 3000,
            panelClass: ['mat-bg-primary']
          });
        }
      );
    }
  }
}
