import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators  } from '@angular/forms';
import { ProductsFamilyModel, ResponseModalModel } from '../products-family.model';
import { TextValidator } from '@app/_validators/text.validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-product-family-detail',
  templateUrl: './product-family-detail.component.html'
})
export class ProductFamilyDetailComponent implements OnInit {
  public refForm: FormGroup;
  public responseElt: ResponseModalModel =  new ResponseModalModel();

  constructor(
    public dialogRef: MatDialogRef<ProductFamilyDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.responseElt.response = '';
      this.responseElt.element = new ProductsFamilyModel();
    }

  ngOnInit() {
    this.refForm = new FormGroup({
      name: new FormControl(this.data.element.name, [Validators.required, Validators.maxLength(50), TextValidator.noWhiteSpace]),
      color: new FormControl(this.data.element.color, [Validators.required, Validators.maxLength(50)]),
      shortName: new FormControl(this.data.element.shortName, [Validators.required, Validators.maxLength(50)])
    });
    if ( this.data.context === 'update' ) {
      this.refForm.addControl(
        'id', new FormControl(this.data.element.id, [])
      );
    }
  }

  public hasError(controlName: string, errorName: string) {
    return this.refForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.refForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }

}
