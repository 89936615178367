import { HttpParameterCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class Utils {
    public getExtension(fileName) {
        const re = /(?:\.([^.]+))?$/;
        return re.exec(fileName)[1];
    }
    public removeExtension(fileName) {
        if (fileName.lastIndexOf('.') > 0) {
            return fileName.substr(0, fileName.lastIndexOf('.'));
        } else {
            return fileName;
        }
    }
    public capitalize(str) {
        return str.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
    }
}

@Injectable()
export class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}
