import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators  } from '@angular/forms';
import { ProviderModel, ResponseModalModel } from '../provider.model';
import { TextValidator } from '@app/_validators/text.validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-provider-detail',
  templateUrl: './provider-detail.component.html'
})
export class ProviderDetailComponent implements OnInit {
  public refForm: FormGroup;
  public responseElt: ResponseModalModel =  new ResponseModalModel();

  constructor(
    public dialogRef: MatDialogRef<ProviderDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.responseElt.response = '';
      this.responseElt.element = new ProviderModel();
    }

  ngOnInit() {
    this.refForm = new FormGroup({
      name: new FormControl(this.data.element.name, [Validators.required, Validators.maxLength(50), TextValidator.noWhiteSpace]),
      referenceLength: new FormControl(this.data.element.referenceLength),
      color: new FormControl((this.data.element.color) ? this.data.element.color : '#000000', [Validators.required]),
      comment: new FormControl(this.data.element.comment),
    });
    if ( this.data.context === 'update' ) {
      this.refForm.addControl(
        'id', new FormControl(this.data.element.id, [])
      );
    }
  }

  public hasError(controlName: string, errorName: string) {
    return this.refForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.refForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }

}
