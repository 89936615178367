import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators  } from '@angular/forms';
import { ScopeModel, ResponseModalModel } from '../scope.model';
import { TextValidator } from '@app/_validators/text.validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-scope-detail',
  templateUrl: './scope-detail.component.html'
})
export class ScopeDetailComponent implements OnInit {
  public refForm: FormGroup;
  public responseElt: ResponseModalModel =  new ResponseModalModel();
  public scopesList: ScopeModel[];
  public filteredScopesList: Observable<ScopeModel[]>;

  constructor(
    public dialogRef: MatDialogRef<ScopeDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.responseElt.response = '';
      this.responseElt.element = new ScopeModel();
    }

  ngOnInit() {
    const parentId = (this.data.element.parentId) ? this.data.element.parentId : this.data.parentId;
    const dependingId = (this.data.element.dependingId) ? this.data.element.dependingId : null;
    this.refForm = new FormGroup({
      code: new FormControl(this.data.element.code, [Validators.required, Validators.maxLength(50), TextValidator.noWhiteSpace]),
      name: new FormControl(this.data.element.name, [Validators.required, Validators.maxLength(255), TextValidator.noWhiteSpace]),
      description: new FormControl(this.data.element.description, [Validators.maxLength(255)]),
      depending: new FormControl(this.data.scopesList.find(elt => elt.id === dependingId)),
      parent: new FormControl(this.data.scopesList.find(elt => elt.id === parentId))
    });
    if ( this.data.context === 'update' ) {
      this.refForm.addControl(
        'id', new FormControl(this.data.element.id, [])
      );
    }
    this.scopesList = this.data.scopesList;
    this.scopesList.sort((a, b) => a.fullScopeName.localeCompare(b.fullScopeName));
    // Remove actual category from the list
    this.scopesList.splice(this.scopesList.findIndex(array => array.id === this.data.element.id), 1);
    this.filteredScopesList = this.refForm.get('parent').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterScopes(name) : this.scopesList.slice())
      );
  }

  private _filterScopes(value: any): ScopeModel[] {
    const filterValue = value.toLowerCase();
    return this.scopesList.filter(cat => cat.name.toLowerCase().indexOf(filterValue) === 0 || cat.fullScopeName.toLowerCase().indexOf(filterValue) === 0);
  }

  public hasError(controlName: string, errorName: string) {
    return this.refForm.controls[controlName].hasError(errorName);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.refForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }

  public displayFn(scope?: ScopeModel): string | undefined {
    return scope ? scope.name : undefined;
  }
}
