export class FeeModel {
    id: number;
    name: string;
    vat: any;
    isNegotiable: boolean;

    constructor() {
        this.vat = 0;
        this.isNegotiable = true;
    }
}

export class FeeValueModel {
    id: number;
    fee: FeeModel;
    value: number;
    vat: number;

    constructor() {
        this.fee = new FeeModel;
        this.value = 0;
        this.vat = null;
    }
}

export class ResponseModalModel {
    response: string;
    element: FeeModel;

    constructor() {
        this.element = new FeeModel();
        this.response = '';
    }
}
