import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService, AlertService } from '@app/_services';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public req: any;

  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.currentUserValue;
    let errorMessage;
    // Active Loader
    return next.handle(request).pipe(
      catchError(httpError => {
        switch (httpError.status) {
          case 401: // Refresh Token
            if (httpError.error.message === 'Expired JWT Token') {
              return this.authenticationService.loginRT(currentUser.refresh_token).pipe(
                switchMap(res => {
                  const cloneRequest = request.clone({ setHeaders: { Authorization: `Bearer ${res.token}` } });
                  return next.handle(cloneRequest)
                    .pipe(catchError(refreshError => {
                      return throwError(refreshError);
                    }));
                })
              );
            } else if (httpError.error.message === 'Bad credentials.' || httpError.error.message === 'Invalid credentials.') {
              this.alertService.error('interceptor.' + httpError.error.message); // Show message to user
            } else {
              this.alertService.error('Veuillez vous reconnecter à l\'application'); // Show message to user
              this.authenticationService.logout();
              this.router.navigate(['/login']);
              return throwError(errorMessage);
            }
            break;
          case 400: // Bad Request
            if (httpError.error.violations && httpError.error.violations[0].message === 'This value is already used.') {
              this.alertService.error('Cet élément existe déjà.'); // Show message to user
            } else {
              this.alertService.error('Erreur lors de l\'opération'); // Show message to user
              return throwError(httpError);
            }
            break;
          default: // Error Server
            // Bad Request
            if (httpError.error.violations && httpError.error.violations[0].message === 'This value is already used.') {
              this.alertService.error('Cet élément existe déjà.'); // Show message to user
              errorMessage = httpError.error.violations[0].message;
            } else {
              errorMessage = httpError.error.message || 'interceptor.' + httpError.statusText;
              this.alertService.error(errorMessage); // Show message to user
            }

            console.log('ErrorInterceptor -> httpError', httpError);
            return throwError(httpError);
        }
      })
    );
  }
}
