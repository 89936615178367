import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReferencesTaxeModel, ResponseModalModel } from '../references-taxe.model';
import { ProvidersService } from '@app/pages/providers/providers.service';
import { TaxesService } from '@app/pages/taxes/taxes.service';
import { ProviderModel } from '@app/pages/providers/provider.model';
import { TaxeModel } from '@app/pages/taxes/taxe.model';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { TextValidator } from '@app/_validators/text.validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-references-taxe-detail',
  templateUrl: './references-taxe-detail.component.html'
})
export class ReferencesTaxeDetailComponent implements OnInit {
  public refForm: FormGroup;
  public responseElt: ResponseModalModel = new ResponseModalModel();
  public filteredProvidersList: Observable<ProviderModel[]>;
  public filteredTaxesList: Observable<TaxeModel[]>;

  constructor(
    public dialogRef: MatDialogRef<ReferencesTaxeDetailComponent>,
    private providersService: ProvidersService,
    private taxesService: TaxesService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.responseElt.response = '';
    this.responseElt.element = new ReferencesTaxeModel();
  }

  ngOnInit() {
    this.refForm = new FormGroup({
      code: new FormControl(this.data.element.code ? this.data.element.code : '', [Validators.required, Validators.maxLength(50), TextValidator.noWhiteSpace]),
      provider: new FormControl(
        this.data.element.provider ? this.data.element.provider : '',
        (this.data.element.provider) ? [Validators.required, Validators.maxLength(50)] : [Validators.required, Validators.maxLength(50), TextValidator.noWhiteSpace]
      ),
      taxe: new FormControl(
        this.data.element.taxe ? this.data.element.taxe : '',
        (this.data.element.taxe) ? [Validators.required, Validators.maxLength(50)] : [Validators.required, Validators.maxLength(50), TextValidator.noWhiteSpace]
      )
    });
    if (this.data.context === 'update') {
      this.refForm.addControl(
        'id', new FormControl(this.data.element.id, [])
      );
    }
    this.providersService.getProviders().subscribe(
      (elements: ProviderModel[]) => {
        this.filteredProvidersList = this.refForm.get('provider').valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(elements, name) : elements.slice())
          );
      }
    );
    this.taxesService.getTaxes().subscribe(
      (elements: TaxeModel[]) => {
        this.filteredTaxesList = this.refForm.get('taxe').valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(elements, name) : elements.slice())
          );
      }
    );
  }

  private _filter(list, value: any): any {
    const filterValue = value.toLowerCase();
    return list.filter(elm => elm.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public displayFn(element?: any): string | undefined {
    return element ? element.name : undefined;
  }

  public hasError(controlreference: string, errorreference: string) {
    return this.refForm.controls[controlreference].hasError(errorreference);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.refForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }

}
