import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators  } from '@angular/forms';
import { StickerModel, ResponseModalModel } from '../sticker.model';
import { TypesAlcoholsService } from '@app/pages/types-alcohols/types-alcohols.service';
import { TypeAlcoholModel } from '@app/pages/types-alcohols/type-alcohol.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sticker-detail',
  templateUrl: './sticker-detail.component.html'
})
export class StickerDetailComponent implements OnInit {
  public refForm: FormGroup;
  public responseElt: ResponseModalModel =  new ResponseModalModel();
  public typesList: TypeAlcoholModel[];

  constructor(
    public dialogRef: MatDialogRef<StickerDetailComponent>,
    private typesService: TypesAlcoholsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.responseElt.response = '';
      this.responseElt.element = new StickerModel();
    }

  ngOnInit() {
    this.refForm = new FormGroup({
      type: new FormControl(this.data.element.type ? this.data.element.type.id : '', [Validators.required, Validators.maxLength(50)]),
      year: new FormControl(this.data.element.year ? this.data.element.year : '', [Validators.required, Validators.maxLength(50)]),
      amount: new FormControl(this.data.element.amount ? this.data.element.amount : '', [Validators.required, Validators.maxLength(50)])
    });
    if ( this.data.context === 'update' ) {
      this.refForm.addControl(
        'id', new FormControl(this.data.element.id, [])
      );
    }
    this.typesService.getTypesAlcohols().subscribe(
      (elements: TypeAlcoholModel[]) => {
        this.typesList = elements;
      }
    );
  }

  public hasError(controlreference: string, errorreference: string) {
    return this.refForm.controls[controlreference].hasError(errorreference);
  }

  public cancelModal() {
    this.dialogRef.close({ response: 'elementCanceled' });
  }

  public saveForm() {
    if (this.refForm.valid) {
      this.responseElt.response = this.data.context;
      this.responseElt.element = this.refForm.value;
      this.dialogRef.close(this.responseElt);
    }
  }

}
