import { AuthenticationService } from '@app/_services';
import { UserModel } from '@app/_models';
import { Injectable } from '@angular/core';

@Injectable()
export class CheckScopeComponent {
    public currentUser: UserModel;

    constructor(private authenticationService: AuthenticationService) {
        this.authenticationService.currentUser.subscribe( state => {
            this.currentUser = state;
        });
    }

    public hasRole(role: string): boolean {
        /* if ( this.currentUser && (this.currentUser.isOwner || this.currentUser.scopes.find(element => element.scope.code === role)) ) {
            return true;
        } else {
            return false;
        } */
        return false;
    }
}
