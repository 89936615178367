import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsListComponent } from './pages/products/products-list/products-list.component';
import { AppellationsListComponent } from './pages/appellations/appellations-list/appellations-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ReferencesListComponent } from './pages/references/references-list/references-list.component';
import { ProvidersListComponent } from './pages/providers/providers-list/providers-list.component';
import { ScopesListComponent } from './pages/scopes/scopes-list/scopes-list.component';
import { BrandsListComponent } from './pages/brands/brands-list/brands-list.component';
import { ProductsFamiliesListComponent } from './pages/products-families/products-families-list/products-families-list.component';
import { TaxesListComponent } from './pages/taxes/taxes-list/taxes-list.component';
import { UnitsListComponent } from './pages/units/units-list/units-list.component';
import { EstablishmentsListComponent } from './pages/establishments/establishments-list/establishments-list.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { ProductsCategoriesListComponent } from './pages/categories/categories-list/categories-list.component';
import { AttributesListComponent } from './pages/attributes/attributes-list/attributes-list.component';
import { AuthGuard } from './_guards';
import { LoginComponent } from './pages/login';
import { RegisterComponent } from './pages/register';
import { AttributeValuesListComponent } from './pages/attribute-values/attribute-values-list/attribute-values-list.component';
import { VatcategoriesListComponent } from './pages/vatcategories/vatcategories-list/vatcategories-list.component';
import { ReferencesTaxesListComponent } from './pages/references-taxes/references-taxes-list/references-taxes-list.component';
import { InvoicesFilesListComponent } from './pages/invoices-files/invoices-files-list/invoices-files-list.component';
import { ImportinvoiceComponent } from './pages/import-csv/import-invoice.component';
import { ImportScanComponent } from './pages/import-scan/import-scan.component';
import { FeesListComponent } from './pages/fees/fees-list/fees-list.component';
import { ReferencesFeesListComponent } from './pages/references-fees/references-fees-list/references-fees-list.component';
import { TypesAlcoholsListComponent } from './pages/types-alcohols/types-alcohols-list/types-alcohols-list.component';
import { StickersListComponent } from './pages/stickers/stickers-list/stickers-list.component';
import { ReferencesAlcoholsListComponent } from './pages/references-alcohols/references-alcohols-list/references-alcohols-list.component';
import { ImportMercurialeComponent } from './pages/import-csv/import-mercuriale.component';
import { AllReferencesComponent } from './pages/all-references/all-references.component';
import { InvoicesListComponent } from './pages/invoices/invoices-list/invoices-list.component';
import { InvoiceDetailComponent } from './pages/invoices/invoice-detail/invoice-detail.component';
import { MercurialesListComponent } from './pages/mercuriales/mercuriales-list/mercuriales-list.component';
import { MercurialeDetailComponent } from './pages/mercuriales/mercuriale-detail/mercuriale-detail.component';
import { EstablishmentGroupListComponent } from './pages/establishment-group/establishment-group-list/establishment-group-list.component';
import { EstablishmentGroupDetailComponent } from './pages/establishment-group/establishment-group-detail/establishment-group-detail.component';
import { EstablishmentTypeListComponent } from './pages/establishment-type/establishment-type-list/establishment-type-list.component';
import { AreaListComponent } from './pages/area/area-list/area-list.component';
import { WrongSavingsComponent } from './pages/wrong-savings/wrong-savings.component';
import { IndustrialsListComponent } from './pages/industrials/industrials-list/industrials-list.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },

  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'categories', component: ProductsCategoriesListComponent, canActivate: [AuthGuard] },
  { path: 'attributes', component: AttributesListComponent, canActivate: [AuthGuard] },
  {
    path: 'attributes/:id', canActivate: [AuthGuard], children: [
      { path: 'attribute-values', component: AttributeValuesListComponent },
    ]
  },
  { path: 'invoices-files', component: InvoicesFilesListComponent, canActivate: [AuthGuard] },
  { path: 'invoices', component: InvoicesListComponent, canActivate: [AuthGuard] },
  { path: 'invoices/:invoice-id', component: InvoiceDetailComponent, canActivate: [AuthGuard] },
  { path: 'mercuriales', component: MercurialesListComponent, canActivate: [AuthGuard] },
  { path: 'mercuriales/:mercuriale-id', component: MercurialeDetailComponent, canActivate: [AuthGuard] },
  { path: 'import-invoice', component: ImportinvoiceComponent, canActivate: [AuthGuard] },
  { path: 'import-invoice-scan', component: ImportScanComponent, canActivate: [AuthGuard] },
  { path: 'import-mercuriale', component: ImportMercurialeComponent, canActivate: [AuthGuard] },
  { path: 'wrong-savings', component: WrongSavingsComponent, canActivate: [AuthGuard] },
  { path: 'references', component: AllReferencesComponent, canActivate: [AuthGuard] },
  { path: 'appellations', component: AppellationsListComponent, canActivate: [AuthGuard] },
  {
    path: 'appellations/:appellation-id', canActivate: [AuthGuard], children: [
      { path: 'products', component: ProductsListComponent },
      {
        path: 'products/:product-id', children: [
          { path: 'references', component: ReferencesListComponent }
        ]
      }
    ]
  },
  { path: 'providers', component: ProvidersListComponent, canActivate: [AuthGuard] },
  { path: 'scopes', component: ScopesListComponent, canActivate: [AuthGuard] },
  { path: 'brands', component: BrandsListComponent, canActivate: [AuthGuard] },
  { path: 'industrials', component: IndustrialsListComponent, canActivate: [AuthGuard] },
  { path: 'taxes', component: TaxesListComponent, canActivate: [AuthGuard] },
  { path: 'fees', component: FeesListComponent, canActivate: [AuthGuard] },
  { path: 'references-taxes', component: ReferencesTaxesListComponent, canActivate: [AuthGuard] },
  { path: 'references-fees', component: ReferencesFeesListComponent, canActivate: [AuthGuard] },
  { path: 'products-families', component: ProductsFamiliesListComponent, canActivate: [AuthGuard] },
  { path: 'units', component: UnitsListComponent, canActivate: [AuthGuard] },
  { path: 'vat-categories', component: VatcategoriesListComponent, canActivate: [AuthGuard] },
  { path: 'establishments', component: EstablishmentsListComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersListComponent, canActivate: [AuthGuard] },
  { path: 'types-alcohols', component: TypesAlcoholsListComponent, canActivate: [AuthGuard] },
  { path: 'stickers', component: StickersListComponent, canActivate: [AuthGuard] },
  { path: 'references-alcohols', component: ReferencesAlcoholsListComponent, canActivate: [AuthGuard] },
  { path: 'establishment-type-list', component: EstablishmentTypeListComponent, canActivate: [AuthGuard] },
  { path: 'areas', component: AreaListComponent, canActivate: [AuthGuard] },
  { path: 'establishment-group-list', component: EstablishmentGroupListComponent, canActivate: [AuthGuard] },
  { path: 'establishment-group/:establishment-group-id', component: EstablishmentGroupDetailComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', component: DashboardComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
