import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { UserModel } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<UserModel[]>(`${environment.apiUrls[environment.name]}/users`);
    }

    getById(id: number) {
        return this.http.get(`${environment.apiUrls[environment.name]}/users/${id}`);
    }

    register(user: UserModel) {
        return this.http.post(`${environment.apiUrls[environment.name]}/users/register`, user);
    }

    update(user: UserModel) {
        return this.http.put(`${environment.apiUrls[environment.name]}/users/${user.user.id}`, user);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrls[environment.name]}/users/${id}`);
    }
}
