export class TaxeModel {
    id: number;
    name: string;
    amount: number;
    withVat: boolean;

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: TaxeModel;

    constructor() {
        this.element = new TaxeModel();
        this.response = '';
    }
}
