export class BrandModel {
    id: number;
    name: string;

    constructor() {
        this.name = '';
    }
}

export class ResponseModalModel {
    response: string;
    element: BrandModel;

    constructor() {
        this.element = new BrandModel();
        this.response = '';
    }
}
