export class AttributeValueModel {
    id: number;
    value: string;

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: AttributeValueModel;

    constructor() {
        this.element = new AttributeValueModel();
        this.response = '';
    }
}
