import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AnalyticService } from '@app/pages/dashboard/dashboard.service';
import { Utils } from '@app/_helpers';
import { ChartOptions, ChartData } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-invoices-treatment',
  templateUrl: './invoices-treatment.component.html',
  styleUrls: ['./invoices-treatment.component.scss']
})
export class InvoicesTreatmentComponent implements OnInit, OnDestroy {
  @ViewChild(BaseChartDirective, { static: true }) private _chart;

  @Input() events: Observable<any>;

  // Dates filters
  public dateStart = new FormControl({ value: moment().startOf('year'), disabled: true });
  public dateEnd = new FormControl({ value: moment().endOf('year'), disabled: true });

  // Synthesis bar chart per month
  public barChartOptions: ChartOptions = {
    responsive: true,
    layout: { padding: { top: 10, bottom: 10 } },
    maintainAspectRatio: false,
    animation: { duration: 2500 },
    plugins: {
      datalabels: { color: 'transparent', anchor: 'end', align: 'end' },
      title: { display: true, font: { size: 20, weight: 'normal' }, color: 'white', text: 'Évolution du délai moyen de traitement des factures' },
      legend: { position: 'top', onClick: null },
      tooltip: {
        mode: 'index',
        intersect: false,
        position: 'average',
        callbacks: {
          label: function (context) {
            return context.formattedValue + ' jours';
          }
        }
      },
    },
    scales: {
      x: { grid: { display: false } },
      y: {
        stacked: true,
        ticks: { maxTicksLimit: 10000 }
      }
    },
    elements: { point: { radius: 0 } },
  };
  public barChartPlugins = [pluginDataLabels];
  public barChartData: ChartData<'line'> = { labels: [], datasets: [] };
  public color = '#f9a825';

  private eventsSubscription: any;

  constructor(private utils: Utils, private analyticsService: AnalyticService) { }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe(res => {
      this.getStats(res.filters);
      this.dateStart = res.filters.dateStart;
      this.dateEnd = res.filters.dateEnd;
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  /**
   * Get datas from API and formatte them in the chart
   */
  public getStats(filters: any) {
    const dateStart = moment(filters.dateStart.value.toDate()).format('YYYY-MM-DD');
    const dateEnd = moment(filters.dateEnd.value.toDate()).format('YYYY-MM-DD');
    /**
     * Annual Review
     */
    this.analyticsService.getInvoicesTreatment(dateStart, dateEnd).subscribe(data => {
      // this.getMaxValue(data['invoices']);

      const dataInvoicesTreatment = this.getDataFormatted(data['invoices']);
      this.barChartData.datasets = [{
        data: dataInvoicesTreatment,
        label: 'Délai (en jours)',
        backgroundColor: this.color,
        borderColor: this.color,
        pointBackgroundColor: this.color,
        pointBorderColor: this.color
      }];
    });
  }

  /**
   * Return datas source for annual review
   * @param indexStart Start iteration
   * @param indexLength Number iteration
   * @param yearCompare Current year
   * @param dataTreatments Datas invoices
   */
  public getDataTreatmentByPeriod(indexStart, indexLength, yearCompare, dataTreatments, concatLabel = true) {
    const dataTreatment = [];
    let data;

    for (let y = indexStart; y <= indexLength; y++) {
      if (concatLabel) {
        this.barChartData.labels.push(this.utils.capitalize(moment().month(y).format('MMMM')) + ' ' + yearCompare);
      }
      if (data = dataTreatments.find(establishment_file => (establishment_file.year === yearCompare && establishment_file.month === ('0' + (y + 1)).slice(-2).toString()))) {
        dataTreatment.push(Math.round((data.total) * 100) / 100);
      } else {
        dataTreatment.push(0);
      }
    }
    if (!concatLabel) {
    }
    return { dataTreatment: dataTreatment };
  }

  /**
   * Return an object with data from API formatted with the number of label list (months)
   * @param datas Datas form API
   * @param concatLabel Boolean for providers datas
   * TODO: Refacto with provider weight
   */
  public getDataFormatted(datas, concatLabel = true) {
    let datasInv = [];
    const dayStart = moment(this.dateStart.value).format('D');
    const dayEnd = moment(this.dateEnd.value).format('D');
    const monthStart = moment(this.dateStart.value).format('MM');
    const monthEnd = moment(this.dateEnd.value).format('MM');
    const yearStart = moment(this.dateStart.value).format('YYYY');
    const yearEnd = moment(this.dateEnd.value).format('YYYY');

    const daysDifference = Number.parseInt(dayEnd, 10) - Number.parseInt(dayStart, 10);
    const monthsDifference = Number.parseInt(monthEnd, 10) - Number.parseInt(monthStart, 10);
    const yearsDifference = Number.parseInt(yearEnd, 10) - Number.parseInt(yearStart, 10);
    let response;
    let indexStart, indexLength, yearCompare;
    // Define the numbers of month to show on the graph
    if (yearsDifference > 0) { // Period during differents years - Example period between 10/2018 to 12/2020
      for (let index = 0; index <= yearsDifference; index++) {
        if (index === 0) { // First year period - Example months between 10/2018 to 12/2018
          // ChartJs consider months list starting at the index 0 (for january)
          indexStart = Number.parseInt(monthStart, 10) - 1;
          indexLength = 11;
          yearCompare = yearStart;
          response = this.getDataTreatmentByPeriod(indexStart, indexLength, yearCompare, datas, concatLabel);
        } else if (index === yearsDifference) { // Last year period - Example months between 01/2020 to 12/2020
          // ChartJs consider months list starting at the index 0 (for january)
          indexStart = 0;
          indexLength = Number.parseInt(monthEnd, 10) - 1;
          yearCompare = yearEnd;
          response = this.getDataTreatmentByPeriod(indexStart, indexLength, yearCompare, datas, concatLabel);
        } else { // During the period of years - Example months between 01/2019 to 12/2019
          indexStart = 0;
          indexLength = 11;
          yearCompare = (Number.parseInt(yearStart, 10) + index).toString();
          response = this.getDataTreatmentByPeriod(indexStart, indexLength, yearCompare, datas, concatLabel);
        }
        datasInv = [...datasInv, ...response.dataTreatment];
      }
    } else if (monthsDifference > 0 || daysDifference > 0) { // Period during the same year - Example period between 01/2020 to 12/2020
      // ChartJs consider months list starting at the index 0 (for january)
      indexStart = Number.parseInt(monthStart, 10) - 1;
      indexLength = Number.parseInt(monthEnd, 10) - 1;
      yearCompare = yearStart;
      response = this.getDataTreatmentByPeriod(indexStart, indexLength, yearCompare, datas, concatLabel);
      datasInv = [...datasInv, ...response.dataTreatment];
    }
    return datasInv;
  }

  /**
   * Define a max value for the chart
   * @param datas object of data from API
   */
  public getMaxValue(datas: any) {
    let maxValue = 0;
    datas.forEach(element => {
      maxValue = (Number(element.total) > maxValue) ? Number(element.total) : maxValue;
    });
    this.barChartOptions.scales.yAxes[0].ticks.max = Math.round((maxValue + 10) / 10) * 10;
    this.forceChartRefresh();
  }

  /**
   * TODO: Refacto this part:
   * https://github.com/valor-software/ng2-charts/commit/ef5d2f47f59a72f2d77a02c51c51c412a4cc9489
   */
  public forceChartRefresh() {
    setTimeout(() => { this._chart.refresh(); });
  }
}
