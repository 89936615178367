import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

import { AuthenticationService } from '@app/_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService
        ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has('X-Skip-Interceptor')) {
            const headers = request.headers.delete('X-Skip-Interceptor');
            return next.handle(request.clone({ headers }));
        }
        // Check if current token is still active
        const currentUser = this.authenticationService.currentUserValue;

        // Add authorization header with jwt token if available
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`,
                    'Admin-version': environment.version
                }
            });
        }
        return next.handle(request);
    }
}
