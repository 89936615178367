import { ProviderModel } from '../providers/provider.model';
import { TypeAlcoholModel } from '../types-alcohols/type-alcohol.model';

export class ReferencesAlcoholModel {
    id: number;
    code: string;
    provider: ProviderModel;
    degree: number;
    type: TypeAlcoholModel;

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: ReferencesAlcoholModel;

    constructor() {
        this.element = new ReferencesAlcoholModel();
        this.response = '';
    }
}
