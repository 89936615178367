import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { Utils } from '@app/_helpers';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDatepicker } from '@angular/material/datepicker';

export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'D MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }
  ]
})
export class FiltersComponent implements OnInit {
  @ViewChild('auto', { read: false }) matAutocomplete: MatAutocomplete;

  @ViewChild(MatDatepicker) pickerStart;
  @ViewChild(MatDatepicker) pickerEnd;

  // Dates filter
  public dateStart = new FormControl({ value: moment().subtract(12, 'months').startOf('month'), disabled: true });
  public dateEnd = new FormControl({ value: moment().endOf('month'), disabled: true });

  public responseElt: any;

  constructor(
    public utils: Utils,
    public dialogRef: MatDialogRef<FiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    // Set the filters
    this.dateStart = (this.data.filters.dateStart) ? this.data.filters.dateStart : this.dateStart;
    this.dateEnd = (this.data.filters.dateEnd) ? this.data.filters.dateEnd : this.dateEnd;
  }

  private removeEstablishment(establishment: any): void {
    const index = this.data.filters.establishments.indexOf(establishment);

    if (index >= 0) {
      this.data.filters.establishments.splice(index, 1);
    }
  }

  private removeProvider(provider: any): void {
    const index = this.data.filters.providers.indexOf(provider);

    if (index >= 0) {
      this.data.filters.providers.splice(index, 1);
    }

  }

  public selectedEstablishment(event: MatAutocompleteSelectedEvent): void {
    if (this.data.filters.establishments.find(element => element.name === event.option.value.name)) {
      this.removeEstablishment(event.option.value);
      return;
    } else {
      this.data.filters.establishments.push(event.option.value);
    }
  }

  public selectedProvider(event: MatAutocompleteSelectedEvent): void {
    if (this.data.filters.providers.find(element => element.name === event.option.value.name)) {
      this.removeProvider(event.option.value);
      return;
    } else {
      this.data.filters.providers.push(event.option.value);
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public applyFilters() {
    this.dialogRef.close({
      filters: {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        establishments: this.data.filters.establishments,
        providers: this.data.filters.providers,
      }
    });
  }

  public inArray(element: any, type: String = ''): any {
    if (type === 'provider') {
      return this.data.filters.providers.filter(provider => provider.name.toLowerCase().includes(element.name.toLowerCase()));
    } else {
      return this.data.filters.establishments.filter(establishment => establishment.name.toLowerCase().includes(element.name.toLowerCase()));
    }
  }
}
