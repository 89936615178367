import { Injectable } from '@angular/core';
import { VatcategoryModel } from './vatcategory.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VatcategoriesService {

  constructor(private http: HttpClient) { }

  public getVatcategories(): Observable<VatcategoryModel[]> {
    const params = new HttpParams().set('order[id]', 'desc').append('exists[establishment]', 'false').append('exists[establishmentGroup]', 'false');
    return this.http.get<VatcategoryModel[]>(`${environment.apiUrls[environment.name]}/vat_categories`, {
      params: params
    }).pipe(map(data => data['hydra:member']));
  }

  public createVatcategory(vatcategory: VatcategoryModel): Observable<VatcategoryModel> {
    return this.http.post<VatcategoryModel>(`${environment.apiUrls[environment.name]}/vat_categories`, vatcategory);
  }

  public updateVatcategory(vatcategory: VatcategoryModel): Observable<VatcategoryModel> {
    return this.http.put<VatcategoryModel>(`${environment.apiUrls[environment.name]}/vat_categories/${vatcategory.id}`, vatcategory);
  }

  public deleteVatcategory(vatcategory: VatcategoryModel): Observable<VatcategoryModel> {
    return this.http.delete<VatcategoryModel>(`${environment.apiUrls[environment.name]}/vat_categories/${vatcategory.id}`);
  }
}
