import { ProviderModel } from '../providers/provider.model';
import { FeeModel } from '../fees/fee.model';

export class ReferencesFeeModel {
    id: number;
    code: string;
    provider: ProviderModel;
    fee: FeeModel;

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: ReferencesFeeModel;

    constructor() {
        this.element = new ReferencesFeeModel();
        this.response = '';
    }
}
