import { Injectable } from '@angular/core';
import { CategoryModel } from './category.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private http: HttpClient) { }

  public getCategories(list = false): Observable<CategoryModel[]> {
    let params = new HttpParams().set('order[id]', 'desc');
    if (list) {
      params = params.set('groups[]', 'list');
    }
    return this.http.get<CategoryModel[]>(`${environment.apiUrls[environment.name]}/categories`, {
      params: params
    }).pipe(map(data => data['hydra:member']));
  }

  public getCategory(id): Observable<CategoryModel> {
    return this.http.get<CategoryModel>(`${environment.apiUrls[environment.name]}/categories/${id}`);
  }

  public createCategory(categorie: CategoryModel): Observable<CategoryModel> {
    const params = {
      'name': categorie.name,
      ...(categorie.parentId && {'parent': categorie.parentId}),
      ...(categorie.attributes && {'attributes': categorie.attributes.map(x => '/attributes/' + x)})
    };
    return this.http.post<CategoryModel>(`${environment.apiUrls[environment.name]}/categories`, params);
  }

  public updateCategory(categorie: CategoryModel): Observable<CategoryModel> {
    const params = {
      'name': categorie.name,
      ...(categorie.parentId && {'parent': categorie.parentId}),
      ...(categorie.attributes && {'attributes': categorie.attributes.map(x => '/attributes/' + x)})
    };
    return this.http.put<CategoryModel>(`${environment.apiUrls[environment.name]}/categories/${categorie.id}`, params);
  }

  public deleteCategory(categorie: CategoryModel): Observable<CategoryModel> {
    return this.http.delete<CategoryModel>(`${environment.apiUrls[environment.name]}/categories/${categorie.id}`);
  }
}
