export class VatcategoryModel {
    id: number;
    name: string;

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: VatcategoryModel;

    constructor() {
        this.element = new VatcategoryModel();
        this.response = '';
    }
}
