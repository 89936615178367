import { UserModel } from "@app/_models";
import { EstablishmentTypeModel } from "../establishment-type/establishment-type.model";

export const EstablishmentStates = [
  "customer",
  "test",
  "lost",
  "pause",
] as const;
export type EstablishmentState = (typeof EstablishmentStates)[number];

export class EstablishmentModel {
  id: number;
  newId: string;
  shortId: string;
  address: string;
  city: string;
  email: string;
  name: string;
  phone: string;
  pictureID: string;
  pictureUrl: null;
  siret: string;
  userEstablishments: [{ user: string; isOwner: boolean }];
  zipCode: string;
  type: EstablishmentTypeModel;
  type_id: Number;
  type_name: string;
  type_web_icon: string;
  isHighPriority: boolean;
  state: EstablishmentState;
  flatRate: string | null;
  handledFixesFees: boolean;
  public constructor() {}
}

export class UserEstablishmentModel {
  id: number;
  isOwner: boolean;
  establishment: EstablishmentModel | string;
  scope: string[];
  user: UserModel[] | string; // @todo:

  public constructor() {}
}

export class ResponseModalModel {
  response: string;
  element: EstablishmentModel;

  constructor() {
    this.element = new EstablishmentModel();
    this.response = "";
  }
}

export class UserEstablishmentResponseModalModel {
  response: {
    action: string;
    establishment: EstablishmentModel;
  };
  element: UserEstablishmentModel;

  constructor() {
    this.element = new UserEstablishmentModel();
    this.response = {
      action: "",
      establishment: new EstablishmentModel(),
    };
  }
}
