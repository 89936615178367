import { Component, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import { UserEstablishmentModel } from '@app/pages/establishments/establishment.model';
import { LoaderService } from '@app/_services/loader.service';
import { InvoiceModel } from '../invoice.model';
import { InvoicesService } from '../invoices.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, startWith, switchMap, map, catchError } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '@app/_components/confirmation-dialog';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { AnalyticService } from '@app/pages/dashboard/dashboard.service';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss']
})
export class InvoicesListComponent implements OnInit {
  @Input() events: Observable<any>;
  public reload = new EventEmitter();

  public invoices: any;
  public currentUserEstablishment: UserEstablishmentModel;
  public dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  public displayedColumns: string[] = ['id', 'identifierFile', 'identifier', 'establishment', 'provider', 'preTaxCsv', 'vatIncludedCsv', 'HTDifference', 'date', 'actions'];
  public elementsList: InvoiceModel[];
  public elementType: string;
  public elementsMatTable = new MatTableDataSource<InvoiceModel>();
  public loading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  providerSearchInput = new FormControl('');
  establishmentSearchInput = new FormControl('');
  identifierSearchInput = new FormControl('');
  identifierFileSearchInput = new FormControl('');
  resultsLength = Number;

  public firstDate = moment('2016-12-31').format();
  public dateNow = moment().format();

  public eventsSubject: Subject<any> = new Subject<any>();

  state$: Observable<object>;
  public contextInvoices: string = null;
  public invoicesWrongDateIds: any = [];
  public invoicesHTDifferenceIds: any = [];

  constructor(
    private elementService: InvoicesService,
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private analyticService: AnalyticService
  ) { }

  ngOnInit() {
    this.state$ = this.route.paramMap.pipe(map(() => window.history.state));

    this.state$.subscribe((res: any) => {
      this.loaderService.changeLoader(true);
      if (res.data) {
        this.contextInvoices = res.context;
        this.getStats(res.data)
      } else {
        this.getStats();
      }
    });

    this.identifierSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.identifierFileSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.providerSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
    this.establishmentSearchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => { this.paginator.firstPage(); this.reload.emit(); });
  }

  /**
   * Get datas from API and formatte them in the chart
   */
  public getStats(ids = null) {
    merge(this.paginator.page, this.sort.sortChange, this.reload)
      .pipe(
        startWith(() => { }),
        switchMap(() => {
          const filterValue = [
            { 'type': 'establishmentProvider.provider.name', 'value': this.providerSearchInput.value },
            { 'type': 'establishmentProvider.establishment.name', 'value': this.establishmentSearchInput.value },
            { 'type': 'identifier', 'value': this.identifierSearchInput.value },
            { 'type': 'establishmentFile.identifierFile', 'value': this.identifierFileSearchInput.value }
          ];
          filterValue.push({ 'type': (this.sort.direction) ? 'order[date]' : 'order[createdAt]', 'value': (this.sort.direction) ? this.sort.direction : 'desc' });

          if (this.sort.active === 'HTDifference') {
            filterValue.push({ 'type': (this.sort.direction) ? 'order[hTDifference]' : 'order[createdAt]', 'value': (this.sort.direction) ? this.sort.direction : 'desc' });
          }
          let filteredIds = null;
          if (this.contextInvoices === 'dates') {
            filteredIds = this.invoicesWrongDateIds;
          } else if (this.contextInvoices === 'difference') {
            filteredIds = this.invoicesHTDifferenceIds;
          }
          this.loaderService.changeLoader(true);

          return this.elementService.getInvoices(this.paginator.pageIndex, this.paginator.pageSize, filterValue, (ids) ? ids : (filteredIds) ? filteredIds : null);
        }),
        map(data => {
          this.loaderService.changeLoader(false);
          this.resultsLength = data['hydra:totalItems'];

          return data;
        }),
        catchError(() => {
          this.loaderService.changeLoader(false);
          return new Observable();
        })
      ).subscribe(data => {
        this.loaderService.changeLoader(false);
        this.elementsMatTable.data = data['hydra:member'];
      }
      );
  }

  /**
   * Define the filters and redefine the chart
   * @param filters object of dateStart, dateEnd and list of establishments
   */
  public setFilters(filters) {
    // Send the filters to all components called in the dashboard
    this.eventsSubject.next({ filters: filters });
  }

  /**
   * Init paginator & sort
   */
  initMatTable() {
    this.elementsMatTable.paginator = this.paginator;
    this.elementsMatTable.sort = this.sort;
  }

  /**
   * Search in table
   * @param filterValue string to research in array list
   */
  applyFilter(filterValue: string) {
    this.elementsMatTable.filter = filterValue.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Call to the service and open file in new tab
   * @param file file consultation
   */
  public openFile(file) {
    this.elementService.openFile(file);
  }

  public delete(invoice: InvoiceModel) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, { width: '600px', disableClose: false });
    this.dialogRef.componentInstance.confirmMessage = 'Supprimer la facture ?';
    this.dialogRef.componentInstance.confirmMessageDetail = 'Confirmer la suppression de la facture.';
    this.dialogRef.componentInstance.styleConfirm = 'delete';

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.elementService.deleteInvoice(invoice).subscribe(
          () => {
            // Remove from MatTable
            const index = this.elementsMatTable.data.findIndex(array => array.id === invoice.id);
            this.elementsMatTable.data.splice(index, 1);
            this.elementsMatTable = new MatTableDataSource<InvoiceModel>(this.elementsMatTable.data);
            // Snackbar for user
            this.snackBar.open('Facture supprimé', '', {
              duration: 3000,
              panelClass: ['mat-bg-warn']
            });
          }
        );
      }
      this.dialogRef = null;
    });
  }

  public resetFilter() {
    this.contextInvoices = null;
    this.invoicesWrongDateIds = null;
    this.invoicesHTDifferenceIds = null;
    this.paginator.firstPage();
    this.reload.emit();
  }

  public getInvoicesByContext(context) {
    this.analyticService.getInvoicesImported().subscribe((invoices: any) => {

      if (context === 'dates') {
        this.contextInvoices = context;
        this.invoicesWrongDateIds = invoices.totalInvoicesWrongDate.ids;
      } else if (context === 'difference') {
        this.contextInvoices = context;
        this.invoicesHTDifferenceIds = invoices.totalInvoicesHTDifference.ids;
      }
      this.paginator.firstPage();
      this.reload.emit();
    });
  }
}
