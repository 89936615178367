import { AbstractControl, ValidationErrors } from '@angular/forms';

export class TextValidator {
    static noWhiteSpace(control: AbstractControl): ValidationErrors | null {
        if (control.value && control.value && typeof(control.value) === 'string' && (control.value as string).trim().length !== (control.value as string).length) {
            return { cannotContainSpace: true };
        }
        return null;
    }
}
