import { AttributeModel } from '../attributes/attribute.model';
import { CategoryModel } from '../categories/category.model';
import { VatcategoryModel } from '../vatcategories/vatcategory.model';

export class EstablishmentTypeModel {
    id: number;
    area: AreaModel[];

    constructor() {}
}

export class AreaModel {
    id: number;
    name: any;

    constructor() {}
}

export class EstablishmentTypeAreaModel {
    id: number;
    establishmentType: EstablishmentTypeModel;
    area: AreaModel;

    constructor() {}
}

export class EstablishmentTypeVatCategoryModel {
    id: number;
    establishmentType: EstablishmentTypeModel;
    vatCategory: VatcategoryModel;

    constructor() {}
}

export class AppellationEstablishmentTypeAreaModel {
    id: number;
    appellation: string;
    establishmentTypeArea: any;

    constructor() {}
}

export class AppellationModel {
    id: number;
    name: string;
    category: CategoryModel;
    fullCategoryName: string;
    attributesList: AttributeModel[];
    attributes: AttributeModel[];
    vatCategory: VatcategoryModel;
    establishmentTypeAreas: EstablishmentTypeAreaModel[];
    certified: boolean;

    constructor() {
        this.name = '';
        this.certified = false;
    }
}

export class ResponseModalModel {
    response: string;
    element: AppellationModel;

    constructor() {
        this.element = new AppellationModel();
        this.response = '';
    }
}
