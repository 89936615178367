import { Component, OnInit } from '@angular/core';
import { UserEstablishmentModel } from '@app/pages/establishments/establishment.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '@app/_services';
import { UserModel } from '@app/_models';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { CheckScopeComponent } from '@app/_guards';
import { environment } from '@environments/environment';
import { SseService } from '@app/_services/sse.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  public project: String = environment.name;
  public version: String = 'v ' + environment.version;
  public currentUser: UserModel;
  public globaLoader: boolean;
  public establishmentsList: UserEstablishmentModel[];
  public currentUserEstablishment: UserEstablishmentModel;
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private checkScope: CheckScopeComponent,
    private breakpointObserver: BreakpointObserver,
    private sseService: SseService
    ) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      this.globaLoader = false;
    }

  ngOnInit() {
    /* this.sseService.getServerSentEvent('admin_version').subscribe((publication: any) => {
      const publicationData = JSON.parse(publication.data);
      if (publicationData.admin_version > environment.version) {
        const versionSB = this.snackBar.open('Une nouvelle version de Coopeo est disponible. Merci de rafraîchir votre navigateur.', 'RELANCER', {
          duration: 0,
          panelClass: ['mat-bg-accent', 'w-25', 'versionSB'],
          horizontalPosition: 'start'
        });
        versionSB.onAction().subscribe(() => { location.reload(); });
      }
    }); */
    this.authenticationService.currentUser.subscribe( state => {
      this.currentUser = state;
    } );
  }

  public hasRight(right: string) {
    return this.checkScope.hasRole(right);
  }
}
