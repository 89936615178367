import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ImportScanService {
  constructor(private http: HttpClient) {}

  public analyze(file: Blob): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("profession", "CHR");
    return this.http.post<any>(
      `${
        environment.apiUrls["v2-" + environment.name]
      }/invoice-integration/analyze-file`,
      formData
    );
  }
}
