import { Injectable } from '@angular/core';
import { StickerModel } from './sticker.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StickersService {

  constructor(private http: HttpClient) { }

  public getStickers(): Observable<StickerModel[]> {
    const params = new HttpParams().set('order[id]', 'desc');
    return this.http.get<StickerModel[]>(`${environment.apiUrls[environment.name]}/social_security_stickers`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public getSticker(year, type): Observable<StickerModel> {
    const params = new HttpParams().set('order[id]', 'desc').set('year', year).set('type', type);
    return this.http.get<StickerModel>(`${environment.apiUrls[environment.name]}/social_security_stickers`, {params: params}).pipe(map(data => data['hydra:member'][0]));
  }

  public createSticker(sticker: StickerModel): Observable<StickerModel> {
    const params = {
      'year': sticker.year,
      'amount': sticker.amount,
      'type': '/type_alcohols/' + sticker.type,
    };
    return this.http.post<StickerModel>(`${environment.apiUrls[environment.name]}/social_security_stickers`, params);
  }

  public updateSticker(sticker: StickerModel): Observable<StickerModel> {
    const params = {
      'year': sticker.year,
      'amount': sticker.amount,
      'type': '/type_alcohols/' + sticker.type,
    };
    return this.http.put<StickerModel>(`${environment.apiUrls[environment.name]}/social_security_stickers/${sticker.id}`, params);
  }

  public deleteSticker(sticker: StickerModel): Observable<StickerModel> {
    return this.http.delete<StickerModel>(`${environment.apiUrls[environment.name]}/social_security_stickers/${sticker.id}`);
  }
}
