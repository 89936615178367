import { Injectable } from '@angular/core';
import { TaxeModel } from './taxe.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaxesService {

  constructor(private http: HttpClient) { }

  public getTaxes(): Observable<TaxeModel[]> {
    const params = new HttpParams().set('order[id]', 'desc');
    return this.http.get<TaxeModel[]>(`${environment.apiUrls[environment.name]}/taxes`, {params: params}).pipe(map(data => data['hydra:member']));
  }

  public createTaxe(taxe: TaxeModel): Observable<TaxeModel> {
    return this.http.post<TaxeModel>(`${environment.apiUrls[environment.name]}/taxes`, taxe);
  }

  public updateTaxe(taxe: TaxeModel): Observable<TaxeModel> {
    return this.http.put<TaxeModel>(`${environment.apiUrls[environment.name]}/taxes/${taxe.id}`, taxe);
  }

  public deleteTaxe(taxe: TaxeModel): Observable<TaxeModel> {
    return this.http.delete<TaxeModel>(`${environment.apiUrls[environment.name]}/taxes/${taxe.id}`);
  }
}
