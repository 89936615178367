export class ProviderModel {
    id: number;
    name: string;
    referenceLength: number;
    color: string;

    constructor() {
        this.referenceLength = 0;
        this.color = '#000000';
    }
}

export class ResponseModalModel {
    response: string;
    element: ProviderModel;

    constructor() {
        this.element = new ProviderModel();
        this.response = '';
    }
}
