import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { UserModel } from '@app/_models';
import { InvoiceModel } from './invoice.model';
import { Utils } from '@app/_helpers';
import { InvoicesFilesService } from '../invoices-files/invoices-files.service';

@Injectable({
    providedIn: 'root'
})
export class InvoicesService {
    public currentUser: UserModel;

    constructor(private http: HttpClient, public utils: Utils, private invoiceFilesService: InvoicesFilesService) {
    }

    public updateInvoice(invoiceDatas: InvoiceModel): Observable<any> {
        return this.http.put<InvoiceModel>(`${environment.apiUrls[environment.name]}/update_invoice`, { ...invoiceDatas });
    }

    public deleteInvoice(invoiceDatas: InvoiceModel): Observable<any> {
        return this.http.delete<InvoiceModel>(`${environment.apiUrls[environment.name]}/invoices/${invoiceDatas.id}`);
    }

    public getInvoices(offset = 0, items_per_page = 50, filterValue = null, ids = null): Observable<InvoiceModel[]> {
        offset++;
        let params = new HttpParams().set('page', offset.toString()).set('items_per_page', items_per_page.toString());
        params = params.append('status[]', '1').append('status[]', '2').append('status[]', '3');
        if (filterValue) {
            filterValue.forEach(element => {
                params = (element.value) ? params.append(element.type, element.value.toString()) : params;
            });
        }
        if (ids) {
            ids.forEach(id => {
                params = params.append('id[]', id)
            });
        }
        return this.http.get<InvoiceModel[]>(`${environment.apiUrls[environment.name]}/invoices`, { params: params });
    }

    public getInvoice(invoiceId): Observable<InvoiceModel> {
        let params = new HttpParams().set('groups[]', 'invoiceDetail');
        params = params.append('status[]', '1').append('status[]', '2').append('status[]', '3');
        return this.http.get<InvoiceModel>(`${environment.apiUrls[environment.name]}/invoices/` + invoiceId, { params: params });
    }

    /**
     * Open file in new tab
     * @param file file object to open
     */
    public openFile(file: InvoiceModel): void {
      this.invoiceFilesService.openFile(file.establishmentFile);
    }

}
