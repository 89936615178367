import { AttributeModel } from '../attributes/attribute.model';

export class CategoryModel {
    id: number;
    name: string;
    fullCategoryName: string;
    attributesList: AttributeModel[];
    parent: CategoryModel;
    parentId: number;
    attributes: AttributeModel[];

    constructor() {}
}

export class ResponseModalModel {
    response: string;
    element: CategoryModel;

    constructor() {
        this.element = new CategoryModel();
        this.response = '';
    }
}
